import React, { useState, useEffect } from 'react';
import './styles.scss';
import { toast } from 'react-toastify';
import { Container, Row, Col, Spinner, Form, Button } from 'react-bootstrap';
import {
  passwordResetTokenValidation,
  passwordUpdate,
} from '../../services/mutations';

import LogoIcon from '../../images/logo/logo.png';

const ChangePassword: React.FC = () => {
  const token = window.location.pathname.substring(1).split('/')[1];

  const [loadingToken, handleLoadingToken] = useState(true);
  const [errorLoadingToken, handleErrorLoadingToken] = useState(false);
  const [loadingSubmit, handleLoadingSubmit] = useState(false);
  const [successChangePassword, handleChangeSuccess] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');

  useEffect(() => {
    handleLoadingToken(true);
    passwordResetTokenValidation(token)
      .then(response => {
        if (response.data.data.passwordResetTokenValidation) {
          handleLoadingToken(false);
        } else {
          handleLoadingToken(false);
          handleErrorLoadingToken(true);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }, [token]);

  const resetPassword = (event: React.FormEvent) => {
    event.preventDefault();
    if (password !== '' && passwordConfirm !== '') {
      if (password !== passwordConfirm) {
        toast.error('As senhas não coincidem!!');
      } else {
        handleLoadingSubmit(true);
        passwordUpdate(token, password, passwordConfirm)
          .then(response => {
            if (response.data.data) {
              toast.success(response.data.data.passwordUpdate);
              handleChangeSuccess(true);
            }
            if (response.data.errors[0]) {
              toast.error(response.data.errors[0].message);
            }
            handleLoadingSubmit(false);
          })
          .catch(error => {
            toast.error(error);
            handleLoadingSubmit(false);
          });
      }
    } else {
      toast.error('Os campos não podem ser vazios!!');
    }
  };

  return (
    <Container fluid className="container-change-password">
      <Row className="justify-content">
        {!loadingToken ? (
          <Col xl={5} className="justify-content">
            <>
              {!errorLoadingToken ? (
                <>
                  {!successChangePassword ? (
                    <>
                      <h5>
                        Cadastre sua nova senha de acesso à plataforma <br />{' '}
                        Máquinas para Construção.
                      </h5>
                      <Form
                        className="form-new-password"
                        onSubmit={(event: React.FormEvent) =>
                          resetPassword(event)
                        }
                      >
                        <Form.Group>
                          <Form.Label>Nova senha</Form.Label>
                          <Form.Control
                            type="password"
                            value={password}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>,
                            ) => setPassword(event.target.value)}
                          />
                        </Form.Group>
                        <Form.Group>
                          <Form.Label>Confirmar nova senha</Form.Label>
                          <Form.Control
                            type="password"
                            value={passwordConfirm}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>,
                            ) => setPasswordConfirm(event.target.value)}
                          />
                        </Form.Group>
                        <Button type="submit">
                          {!loadingSubmit ? (
                            'Solicitar alteração de senha'
                          ) : (
                            <Spinner
                              as="span"
                              animation="border"
                              role="status"
                              aria-hidden="true"
                            />
                          )}
                        </Button>
                      </Form>
                    </>
                  ) : (
                    <>
                      <img src={LogoIcon} alt="logo" />
                      <h5>
                        Sua senha foi cadastrada com sucesso! Clique no botão
                        abaixo e digite para acessar o perfil da sua conta.
                      </h5>
                      <Button
                        type="submit"
                        onClick={() => {
                          window.location.href = '/login';
                        }}
                      >
                        Acessar conta
                      </Button>
                    </>
                  )}
                </>
              ) : (
                <>
                  <h4>Opss!</h4>
                  <h5>
                    <br /> Verificamos que este link está expirado, deseja
                    tentar recuperar sua senha novamente?
                  </h5>
                  <Button
                    type="submit"
                    onClick={() => {
                      window.location.pathname = '/recuperar-senha';
                    }}
                  >
                    Tentar novamente
                  </Button>
                </>
              )}
            </>
          </Col>
        ) : (
          <Spinner
            as="span"
            animation="border"
            role="status"
            aria-hidden="true"
          />
        )}
      </Row>
    </Container>
  );
};

export default ChangePassword;
