import React from 'react';

import Header from '../../components/Header';

import MachineDetail from '../../components/MachineDetail';

const MachinesDetail: React.FC = () => (
  <>
    <Header />
    <section className="fade-in">
      <MachineDetail />
    </section>
  </>
);

export default MachinesDetail;
