import React from 'react';
import Header from '../../components/Header';
import ChangePassword from '../../components/ChangePassword';
import Footer from '../../components/Footer';

const RecoverPassword: React.FC = () => (
  <>
    <Header />
    <section className="fade-in">
      <ChangePassword />
    </section>
    <Footer />
  </>
);

export default RecoverPassword;
