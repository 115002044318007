import axios from 'axios';

const headers = {
  'Content-type': 'application/json',
};

export const getCategories = async () => {
  const data = {
    query: `
    query CategoriesList{
      categories(){
        id
        name
        background
      }
    }
          `,
  };
  const categoriesList = await axios({
    url: process.env.REACT_APP_API_URL,
    method: 'POST',
    headers,
    data,
  });
  return categoriesList;
};

export const getMachines = async () => {
  const data = {
    query: `
    query MachinesList{
      machines(){
        id
        rentalCompanyId
        modelId
        model
        manufacturerId
        manufacturer
        operationalCapacityReferential
        operationalCapacityQuantitative
        operationalCapacityUnit
        equipamentMachineId
        equipamentMachine
        categoryId
        category
        operation
        fabricationYear
        minimumLeaseTerm
        onSale
        onRent
        onService
        deliveryRadius{
          id
          state
          deliveryInCapital
          deliveryInInland
        },
        createdAt
      }
    }
          `,
  };
  const machinesList = await axios({
    url: process.env.REACT_APP_API_URL,
    method: 'POST',
    headers,
    data,
  });
  return machinesList;
};

export const getMachinesTypes = async () => {
  const data = {
    query: `
    query MachinesTypes{
      equipamentMachines(){
        id
        name
        categoryId
        createdAt
      }
    }
          `,
  };
  const machinesTypes = await axios({
    url: process.env.REACT_APP_API_URL,
    method: 'POST',
    headers,
    data,
  });
  return machinesTypes;
};
// TODO: a revisar
export const getOnlyMachineTypes = async () => {
  const data = {
    query: `
    query MachinesTypes{
      machineTypes(){
        id,
        name,
      }
    }
          `,
  };
  const onlyMachineTypes = await axios({
    url: process.env.REACT_APP_API_URL,
    method: 'POST',
    headers,
    data,
  });
  return onlyMachineTypes;
};
// TODO: a revisar
export const getOnlyMachineCategories = async () => {
  const data = {
    query: `
    query MachinesCategories{
    categories(){
        id,
        name,
      }
    }
          `,
  };
  const onlyMachineCategories = await axios({
    url: process.env.REACT_APP_API_URL,
    method: 'POST',
    headers,
    data,
  });
  return onlyMachineCategories;
};

export const getPartnerCompanies = async () => {
  const data = {
    query: `
    query PartnerCompaniesList{
      partnerCompanies(){
        id,
        corporateName,
        description,
        image
      }
    }
          `,
  };
  const PartnerCompaniesList = await axios({
    url: process.env.REACT_APP_API_URL,
    method: 'POST',
    headers,
    data,
  });
  return PartnerCompaniesList;
};

export const getConstructionCompany = async (token: string) => {
  const data = {
    query: `
    query ConstructionCompany{
      constructionCompany(){
        constructionCompany {
          id
          email
          companyName
          cnpjOrCpf
          phone
          whatsApp
          ownerName
          ownerEmail
          ownerPhone
          ownerWhatsApp
          quotations{
            id
            constructionCompanyId
            companyName
            cnpjOrCpf
            phone
            whatsApp
            ownerName
            ownerEmail
            ownerPhone
            ownerWhatsApp
            machines{
              model
              rentalCompanyId
              rentalCompanyName
              fabricationYear
              categoryName
              categoryId
              EquipamentMachineName
              EquipamentMachineId
              leaseTerm
              quantity
              operation
              sale
              rent
              service
            }
            pdfs{
              id
              rentalCompanyId
              rentalCompanyName
              pdfLink
              status
              createdAt
            }
            constructionState
            constructionPlace
            createdAt
          }
        }
      }
    }
          `,
  };
  const ConstructionCompany = await axios({
    url: process.env.REACT_APP_API_URL,
    method: 'POST',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
    data,
  });
  return ConstructionCompany;
};

export const getConstructionCompanyQuotations = async (token: string) => {
  const data = {
    query: `
    query ConstructionCompanyQuotations{
      constructionCompanyQuotations(){
        id
        constructionCompanyId
        companyName
        cnpjOrCpf
        image
        address
        phone
        whatsApp
        ownerName
        ownerEmail
        ownerPhone
        ownerWhatsApp
        pdfs{
          id
          rentalCompanyId
          rentalCompanyName
          pdfLink
          status
          createdAt
        }
        machines{
          model
          rentalCompanyId
          rentalCompanyName
          fabricationYear
          categoryName
          categoryId
          EquipamentMachineName
          EquipamentMachineId
          leaseTerm
          quantity
          operation
          sale
          rent
          service
        }
        constructionState
        constructionPlace
        createdAt
      }
    }
          `,
  };
  const ConstructionCompanyQuotations = await axios({
    url: process.env.REACT_APP_API_URL,
    method: 'POST',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
    data,
  });
  return ConstructionCompanyQuotations;
};
export const getRentalCompany = async (token: string) => {
  const data = {
    query: `
    query RentalCompanyCompany{
      rentalCompany(){
        rentalCompany {
          id
          machines{
            id
            rentalCompanyId
            modelId
            model
            manufacturerId
            manufacturer
            operationalCapacityReferential
            operationalCapacityQuantitative
            operationalCapacityUnit
            equipamentMachineId
            equipamentMachine
            categoryId
            category
            operation
            fabricationYear
            minimumLeaseTerm
            onRent
            onSale
            onService
            deliveryRadius{
              id
              state
              deliveryInCapital
              deliveryInInland
            }
            createdAt
          }
          deliveryRadius{
            id
            state
            deliveryInCapital
            deliveryInInland
          }
          acceptanceTermsOfUse
          corporateName
          cnpjOrCpf
          address
          phone
          whatsApp
          email
          planId
          status
          ownerName
          ownerEmail
          ownerPhone
          ownerWhatsApp
          createdAt
          lastLogin
        }
      }
    }
          `,
  };
  const RentalCompany = await axios({
    url: process.env.REACT_APP_API_URL,
    method: 'POST',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
    data,
  });
  return RentalCompany;
};

export const getRentalCompanyQuotations = async (token: string) => {
  const data = {
    query: `
    query RentalCompanyQuotations{
      rentalCompanyQuotations(){
          id
          contactAuthorizationEmail
          contactAuthorizationPhone
          contactAuthorizationWhatsApp
          constructionCompanyId
          companyName
          cnpjOrCpf
          phone
          whatsApp
          ownerName
          ownerEmail
          ownerPhone
          ownerWhatsApp
          pdfs{
            id
            rentalCompanyId
            rentalCompanyName
            pdfLink
            status
            createdAt
          }
          machines{
            model
            rentalCompanyId
            rentalCompanyName
            fabricationYear
            categoryName
            categoryId
            EquipamentMachineName
            EquipamentMachineId
            leaseTerm
            quantity
            operation
            rent
            sale
            service
          }
          constructionState
          constructionPlace
          createdAt
      }
    }
          `,
  };
  const RentalCompanyQuotations = await axios({
    url: process.env.REACT_APP_API_URL,
    method: 'POST',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
    data,
  });
  return RentalCompanyQuotations;
};

export const getManufacturers = async () => {
  const data = {
    query: `
      query Manufacturers{
        manufacturers(){
          id
          name
        }
      }
    `,
  };
  const Manufacturers = await axios({
    url: process.env.REACT_APP_API_URL,
    method: 'POST',
    headers,
    data,
  });
  return Manufacturers;
};

export const getMachineModels = async () => {
  const data = {
    query: `
      query MachineModels{
        machineModels(){
          id
          model
          categoryId
          categoryName
          equipamentMachineId
          equipamentMachineName
          manufacturerId
          manufacturer
          operationalCapacityReferential
          operationalCapacityQuantitative
          operationalCapacityUnit
        }
      }
    `,
  };
  const MachineModels = await axios({
    url: process.env.REACT_APP_API_URL,
    method: 'POST',
    headers,
    data,
  });
  return MachineModels;
};

export const getPlans = async () => {
  const data = {
    query: `
      query Plans{
        plans(){
          id
          name
          value
          validity
          description
        }
      }
    `,
  };
  const Plans = await axios({
    url: process.env.REACT_APP_API_URL,
    method: 'POST',
    headers,
    data,
  });
  return Plans;
};

export const getMachineModelsByEquipamentMachineAndManufacturer = async (
  equipamentMachineId: string,
  manufacturerId: string,
) => {
  const data = {
    query: `
    query MachineModelsByEquipamentMachineAndManufacturer{
      machineModelsByEquipamentMachineAndManufacturer(
        equipamentMachineId: "${equipamentMachineId}"
        manufacturerId: "${manufacturerId}"
      ){
        id
        model
        categoryId
        categoryName
        equipamentMachineId
        equipamentMachineName
        manufacturerId
        manufacturer
        operationalCapacityReferential
        operationalCapacityQuantitative
        operationalCapacityUnit
      }
    }
          `,
  };
  const MachineModelsByEquipamentMachineAndManufacturer = await axios({
    url: process.env.REACT_APP_API_URL,
    method: 'POST',
    headers,
    data,
  });
  return MachineModelsByEquipamentMachineAndManufacturer;
};
