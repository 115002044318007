import React from 'react';
import { Card } from 'react-bootstrap';

import './styles.scss';
import styled from 'styled-components';

import { MachineTypes } from '../../services/types';

const MachineTypeComponent = styled(Card)`
  background: #5157bb;
  border: 1px solid #e8e8e8;
`;

const MachineType: React.FC<MachineTypes> = (props: MachineTypes) => {
  const { name } = props;

  return (
    <MachineTypeComponent className="machine-type-component justify-content text-center fade-in">
      <Card.Title>{name.toLowerCase()}</Card.Title>
    </MachineTypeComponent>
  );
};

export default MachineType;
