/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import {
  Col,
  Row,
  Card,
  Collapse,
  Spinner,
  FormControl,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Machine from '../Machine';
import {
  getMachines,
  getOnlyMachineTypes,
  getOnlyMachineCategories,
} from '../../services/queries';
import {
  Machine as MachineItem,
  Category,
  DeliveryState,
} from '../../services/types';

import './styles.scss';
import {
  groupMinimumLeaseTerm,
  groupState,
  groupMachineTypes,
  filterItems,
  orderByName,
  orderByModel,
} from '../../services/functions';
import FontAwesome from '../FontAwesome';
import Footer from '../Footer';
import { removeSpecialChars } from '../../services/mask';

const MachinesSearchPanel: React.FC = () => {
  const URI = window.location.pathname.substring(1).split('/');

  const [openMachineTypes, handleOpenMachineTypes] = useState(false);
  const [openMinimumLeaseTerm, handleOpenMinimumLeaseTerm] = useState(false);
  const [openOperation, handleOpenOperation] = useState(false);
  const [openLocation, handleOpenLocation] = useState(false);

  const [filter, handleFilter] = useState<string>('');
  const [machineTypes, handleMachineTypes] = useState<string>('');
  const [minimumLeaseTerm, handleMinimumLeaseTerm] = useState<number>(0);
  const [operation, handleOperation] = useState<number>(0);
  const [state, handleState] = useState<string>('');

  const [machines, handleMachines] = useState<MachineItem[]>([]);
  const [machineTypesItems, handleMachineTypesItems] = useState<
    { id: string; name: string }[]
  >([]);
  const [machineTypesNamesItems, handleMachineTypesNamesItems] = useState<
    string[]
  >([]);
  const [
    machinesCategoriesNamesItems,
    handleMachinesCategoriesNamesItems,
  ] = useState<Category[]>([]);
  const [filteredItems, handleFilteredItems] = useState<MachineItem[]>([]);
  const [minimumLeaseTermItems, handleMinimumLeaseTermItems] = useState<
    number[]
  >([]);
  const [stateItems, handleStateItems] = useState<string[]>([]);

  const getAllMachines = async () => {
    await getOnlyMachineCategories()
      .then(async resp => {
        handleMachinesCategoriesNamesItems(
          orderByName(resp.data.data.categories),
        );
      })
      .catch(async error => {
        // eslint-disable-next-line no-alert
        alert(error);
        return 0;
      });
    await getMachines()
      .then(async response => {
        const temp: MachineItem[] = orderByModel(response.data.data.machines);
        handleMinimumLeaseTermItems(
          groupMinimumLeaseTerm(temp).sort((a: number, b: number) => a - b),
        );
        handleStateItems(groupState(temp));
        handleMachines(temp);
        handleFilter(removeSpecialChars(URI[URI.length - 1]));
        handleFilteredItems(
          filterItems(removeSpecialChars(URI[URI.length - 1]), temp),
        );
        await getOnlyMachineTypes().then(async result => {
          const tempNames: string[] = [];
          const tempCases = groupMachineTypes(temp).sort(
            (a: number, b: number) => a - b,
          );
          const tempResult: { id: string; name: string }[] =
            result.data.data.machineTypes;

          tempCases.map((index: number) => {
            tempResult.filter((item: { id: string; name: string }) => {
              if (Number(item.id) === index) {
                tempNames.push(item.name);
              }
              return true;
            });
            return true;
          });
          handleMachineTypesItems(tempResult);
          handleMachineTypesNamesItems(tempNames);
        });
      })
      .catch(async error => {
        // eslint-disable-next-line no-alert
        alert(error);
        return 0;
      });
  };

  const clearFilters = () => {
    handleFilter('');
    handleMachineTypes('');
    handleMinimumLeaseTerm(0);
    handleOperation(0);
    handleState('');
    handleFilteredItems(machines);
  };

  useEffect(() => {
    getAllMachines();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {machineTypesItems.length > 0 ? (
        <>
          <Row>
            <Col className="machines-panel" md={3}>
              <Card>
                <Card.Body>
                  <h4>Filtrar por:</h4>
                  <FormControl
                    type="text"
                    className="ml-sm-2 mr-sm-2"
                    placeholder="Digite aqui o modelo..."
                    value={filter}
                    onChange={event => {
                      if (event.target.value === '') {
                        clearFilters();
                      } else {
                        handleFilter(event.target.value);
                        handleFilteredItems(
                          filterItems(event.target.value, filteredItems),
                        );
                      }
                    }}
                  />
                  <div className="filter-section">
                    <Card.Title
                      onClick={() => {
                        handleOpenMachineTypes(!openMachineTypes);
                        handleOpenMinimumLeaseTerm(false);
                        handleOpenLocation(false);
                        handleOpenOperation(false);
                        clearFilters();
                      }}
                      aria-controls="example-collapse-text-a"
                      aria-expanded={openMachineTypes}
                    >
                      Tipo de máquina{' '}
                      <div className="float-right">
                        <FontAwesome name="angle-down" type="fas" />
                      </div>
                    </Card.Title>
                    <Collapse in={openMachineTypes}>
                      <div id="example-collapse-text-a">
                        <Row>
                          {machineTypesNamesItems.map(
                            (typeName: string, index: number) => (
                              <Card.Text key={typeName}>
                                {typeName}
                                <input
                                  type="radio"
                                  id="radioItem"
                                  name="radioItem"
                                  value={machineTypes}
                                  checked={machineTypes === typeName}
                                  onChange={() => {
                                    handleMachineTypes(typeName);
                                    handleFilteredItems(
                                      machines.filter(
                                        (machine: MachineItem) =>
                                          machine.machineTypeId ===
                                          machineTypesItems[index].id,
                                      ),
                                    );
                                  }}
                                />
                              </Card.Text>
                            ),
                          )}
                        </Row>
                      </div>
                    </Collapse>
                  </div>
                  <div className="filter-section">
                    <Card.Title
                      onClick={() => {
                        handleOpenMinimumLeaseTerm(!openMinimumLeaseTerm);
                        handleOpenLocation(false);
                        handleOpenOperation(false);
                        handleOpenMachineTypes(false);
                        clearFilters();
                      }}
                      aria-controls="example-collapse-text-b"
                      aria-expanded={openMinimumLeaseTerm}
                    >
                      Prazo mínimo de locação{' '}
                      <div className="float-right">
                        <FontAwesome name="angle-down" type="fas" />
                      </div>
                    </Card.Title>
                    <Collapse in={openMinimumLeaseTerm}>
                      <div id="example-collapse-text-b">
                        <Row>
                          {minimumLeaseTermItems.map((minLeaseTerm: number) => (
                            <Card.Text key={minLeaseTerm}>
                              {minLeaseTerm > 1
                                ? `
                                ${minLeaseTerm} meses
                                `
                                : `${minLeaseTerm} mês`}
                              <input
                                type="radio"
                                id="radioItem"
                                name="radioItem"
                                value={minimumLeaseTerm}
                                checked={minimumLeaseTerm === minLeaseTerm}
                                onChange={() => {
                                  handleMinimumLeaseTerm(minLeaseTerm);
                                  handleFilteredItems(
                                    machines.filter(
                                      (machine: MachineItem) =>
                                        machine.minimumLeaseTerm ===
                                        minLeaseTerm,
                                    ),
                                  );
                                }}
                              />
                            </Card.Text>
                          ))}
                        </Row>
                      </div>
                    </Collapse>
                  </div>
                  <div className="filter-section">
                    <Card.Title
                      onClick={() => {
                        handleOpenOperation(!openOperation);
                        handleOpenMinimumLeaseTerm(false);
                        handleOpenLocation(false);
                        handleOpenMachineTypes(false);
                        clearFilters();
                      }}
                      aria-controls="example-collapse-text-c"
                      aria-expanded={openOperation}
                    >
                      Operação{' '}
                      <div className="float-right">
                        <FontAwesome name="angle-down" type="fas" />
                      </div>
                    </Card.Title>
                    <Collapse in={openOperation}>
                      <div id="example-collapse-text-c">
                        <Row>
                          <Card.Text>
                            Com operador
                            <input
                              type="radio"
                              id="radioItem"
                              name="radioItem"
                              value={operation}
                              checked={operation === 1}
                              onChange={() => {
                                handleOperation(1);
                                handleFilteredItems(
                                  machines.filter(
                                    (machine: MachineItem) =>
                                      machine.operation === true,
                                  ),
                                );
                              }}
                            />
                          </Card.Text>
                          <Card.Text>
                            Sem operador
                            <input
                              type="radio"
                              id="radioItem"
                              name="radioItem"
                              value={operation}
                              checked={operation === 2}
                              onChange={() => {
                                handleOperation(2);
                                handleFilteredItems(
                                  machines.filter(
                                    (machine: MachineItem) =>
                                      machine.operation === false,
                                  ),
                                );
                              }}
                            />
                          </Card.Text>
                        </Row>
                      </div>
                    </Collapse>
                  </div>
                  <div className="filter-section">
                    <Card.Title
                      onClick={() => {
                        handleOpenLocation(!openLocation);
                        handleOpenMinimumLeaseTerm(false);
                        handleOpenLocation(false);
                        handleOpenMachineTypes(false);
                        clearFilters();
                      }}
                      aria-controls="example-collapse-text-d"
                      aria-expanded={openLocation}
                    >
                      Localidade{' '}
                      <div className="float-right">
                        <FontAwesome name="angle-down" type="fas" />
                      </div>
                    </Card.Title>
                    <Collapse in={openLocation}>
                      <div id="example-collapse-text-d">
                        <Row>
                          {stateItems.map((stateItem: string) => (
                            <Card.Text key={stateItem}>
                              {stateItem}
                              <input
                                type="radio"
                                id="radioItem"
                                name="radioItem"
                                value={state}
                                checked={state === stateItem}
                                onChange={() => {
                                  handleState(stateItem);
                                  handleFilteredItems(
                                    machines.filter(
                                      (machine: MachineItem) =>
                                        machine.deliveryRadius.filter(
                                          (deliveryState: DeliveryState) =>
                                            deliveryState.state === stateItem,
                                        )[0],
                                    ),
                                  );
                                }}
                              />
                            </Card.Text>
                          ))}
                        </Row>
                      </div>
                    </Collapse>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col className="mr-3 machines-items">
              <Row>
                {filteredItems.map((machine: MachineItem) => (
                  <Col key={machine.id} xs={6} sm={6} md={4} lg={3}>
                    <Link
                      to={`/categorias/${removeSpecialChars(
                        machinesCategoriesNamesItems.filter(
                          (category: Category) =>
                            category.id === machine.categoryId,
                        )[0].name,
                      )}/${removeSpecialChars(
                        machineTypesItems.filter(
                          (machineType: { id: string; name: string }) =>
                            machineType.id === machine.machineTypeId,
                        )[0].name,
                      )}/${removeSpecialChars(machine.model)}`}
                    >
                      <Machine {...machine} />
                    </Link>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
          <Footer />
        </>
      ) : (
        <Spinner
          className="spinner-loading"
          as="span"
          animation="border"
          role="status"
          aria-hidden="true"
        />
      )}
    </>
  );
};

export default MachinesSearchPanel;
