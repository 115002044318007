import React from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { DeliveryState } from '../../services/types';
import FontAwesome from '../FontAwesome';
import {
  toggleModalP,
  toggleModalConfirm,
} from '../../store/ducks/modals/actions';
import ConfirmationModal from '../ConfirmationModal';

export interface Props {
  posts: DeliveryState[];
}

const DeliveryStates = (props: Props) => {
  const dispatch = useDispatch();

  const { posts } = props;

  return (
    <>
      <tbody>
        {posts.map((deliveryState: DeliveryState) => (
          <tr className="fade-in" key={deliveryState.id}>
            <td>{deliveryState.id}</td>
            <td>{deliveryState.state}</td>
            <td>{deliveryState.deliveryInCapital ? 'Sim' : 'Não'}</td>
            <td>{deliveryState.deliveryInInland ? 'Sim' : 'Não'}</td>
            <td>
              <Button
                className="view"
                title="Editar localidade"
                onClick={() => dispatch(toggleModalP({ ...deliveryState }))}
              >
                <FontAwesome type="fas" name="pen" />
              </Button>
              <Button
                className="remove"
                title="Excluir localidade"
                onClick={() =>
                  dispatch(toggleModalConfirm({ ...deliveryState }))
                }
              >
                <FontAwesome type="fas" name="trash" />
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
      <ConfirmationModal confirmationType="remove-place" />
    </>
  );
};

export default DeliveryStates;
