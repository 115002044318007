/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import './styles.scss';
import { Row, Col, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getPlans, getRentalCompany } from '../../../services/queries';
import {
  PlansType,
  RentalCompanyInput,
  PaymentInput,
} from '../../../services/types';
import {
  createRentalCompany,
  setPayment,
  auth,
} from '../../../services/mutations';
// import PayPal from '../../PayPal';

interface Props {
  goBack(): any;
  paymentSuccess: any;
}

const Phase03: React.FC<Props> = (props: Props) => {
  const { goBack, paymentSuccess } = props;
  const history = useHistory();
  const storageForm: RentalCompanyInput = JSON.parse(
    sessionStorage.getItem('@MPC/form') as any,
  );
  const [isCreateAccount, handleIsCreateAccount] = useState(false);
  const [planSelected, handlePlanSelected] = useState<PlansType>({
    description: '',
    id: '',
    name: '',
    validity: 0,
    value: '',
  });
  const [tokenRental, setTokenRental] = useState('');

  const [rentalCompany] = useState<RentalCompanyInput>({
    acceptanceTermsOfUse: storageForm.acceptanceTermsOfUse,
    email: storageForm.email,
    password: storageForm.password,
    corporateName: storageForm.corporateName,
    cnpjOrCpf: storageForm.cnpjOrCpf,
    address: storageForm.address,
    phone: storageForm.phone,
    whatsApp: storageForm.whatsApp ? storageForm.whatsApp : '',
    planId: storageForm.planId,
    ownerName: storageForm.ownerName,
    ownerEmail: storageForm.ownerEmail,
    ownerPhone: storageForm.ownerPhone,
    ownerWhatsApp: storageForm.ownerWhatsApp,
  });
  // const [rentalId, handleRentalId] = useState('');

  useEffect(() => {
    if (storageForm.planId) {
      getPlans().then(async response => {
        handlePlanSelected(
          response.data.data.plans.filter(
            (plan: PlansType) => plan.id === storageForm.planId,
          )[0],
        );
      });
    }
  }, []);

  const createAccount = () => {
    createRentalCompany(rentalCompany)
      .then(response => {
        if (response.data.data.createRentalCompany) {
          toast.success('Conta criada com sucesso!');
          handleIsCreateAccount(true);
          auth(rentalCompany.email, rentalCompany.password).then(res => {
            if (res.data.data.login) {
              const { token } = res.data.data.login;
              setTokenRental(token);

              getRentalCompany(token).then(async result => {
                if (result.data.data.rentalCompany.rentalCompany) {
                  // const rental = result.data.data.rentalCompany.rentalCompany;
                  // rentalCompany.id = rental.id;
                  // handleRentalId(rental.id);
                } else {
                  toast.error(
                    'Ocorreu um erro, ao pegar seus dados para o pagamento. Por favor, realize o pagamento em seu perfil',
                  );
                }
              });
            } else {
              toast.error('Ocorreu um erro');
            }
          });
        } else {
          toast.error(
            'Erro ao criar conta, revise os dados e tente novamente!',
          );
        }
      })
      .catch(error => {
        console.log('error', error);
      });
  };

  // const paymentDone = (order: any) => {
  //   const payerId = order.payer.payer_id;
  //   const paymentId = order.purchase_units[0].payments.captures[0].id;
  //   const paymentEmail = order.payer.email_address;
  //   const paymentAmount = order.purchase_units[0].amount.value;
  //   const token = tokenRental;
  //   const { planId } = rentalCompany;

  //   const payment: PaymentInput = {
  //     payerId,
  //     paymentId,
  //     paymentEmail,
  //     paymentAmount,
  //     planId,
  //     token,
  //   };

  //   setPayment(payment)
  //     .then(response => {
  //       if (response.data.data.setPayment) {
  //         paymentSuccess();
  //       }
  //     })
  //     .catch(error => {
  //       console.log(error);
  //     });
  // };

  return (
    <>
      <Row className="row-description phase03">
        <Col>
          <h5>
            Confira os dados que você preencheu, caso contenha algo errado basta
            clicar no botão <b>Voltar etapa</b> no final da página. Lembre-se de
            realizar o pagamento do seu plano e em seguida finalizar o seu
            cadastro.
          </h5>
        </Col>
      </Row>
      <Row className="row-count-phases">
        <p>Etapa 03 de 03</p>
      </Row>
      <Row className="row-form-phase03">
        <Row style={{ width: '100%' }}>
          <Col>
            <h4>Nome / Razão Social</h4>
            <p> {storageForm.corporateName} </p>
            <h4>CPF / CNPJ</h4>
            <p> {storageForm.cnpjOrCpf} </p>
            <h4>Endereço</h4>
            <p> {storageForm.address} </p>
          </Col>
          <Col>
            <h4>E-mail</h4>
            <p> {storageForm.email} </p>
            <h4>Telefone</h4>
            <p> {storageForm.phone} </p>
            <h4>WhatsApp</h4>
            <p> {storageForm.whatsApp} </p>
          </Col>
          <Col>
            <h4>Nome do responsável</h4>
            <p> {storageForm.ownerName} </p>
            <h4>Telefone do responsável</h4>
            <p> {storageForm.ownerPhone} </p>
            <h4>E-mail</h4>
            <p> {storageForm.ownerEmail} </p>
          </Col>
        </Row>
        <hr style={{ height: 1, width: '100%' }} />
        <Row style={{ width: '100%' }}>
          <Col xl="5">
            <h4>Plano selecionado</h4>
            <p> {planSelected?.name} </p>
            <h4>Valor</h4>
            <p>
              {' '}
              {Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              }).format(Number(planSelected?.value))}
            </p>
          </Col>
          <Col xl="5">
            <h4>Descrição</h4>
            <p
              dangerouslySetInnerHTML={{
                __html: String(planSelected?.description),
              }}
            />
            <h4>Vigência</h4>
            <p> {planSelected?.validity} meses</p>
          </Col>
        </Row>
      </Row>
      <Row className="row-buttons">
        {!isCreateAccount ? (
          <>
            <Button className="btn-clear" onClick={goBack}>
              Voltar etapa
            </Button>
            <Button type="submit" onClick={createAccount}>
              Realizar Cadastro
            </Button>
          </>
        ) : (
          <Col
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: 15,
            }}
          >
            <h5>
              Realize o pagamento quando quiser receber demandas em
              <a href="#" onClick={() => history.push('/login')}>
                {' '}
                seu perfil
              </a>
            </h5>
            {/* <PayPal product={planSelected} response={paymentDone} /> */}
            <Col className="bank_depot">
              Caso prefira pagamento via Depósito Bancário, guarde o comprovante
              e envie para contato@maquinasparaconstrucao.com:
              <p>
                BANCO INTER
                <br />
                BANCO: 077
                <br />
                AGENCIA: 001
                <br />
                CONTA: 74314866
                <br />
                CNPJ: 38.132.662/0001-98
              </p>
            </Col>
          </Col>
        )}
      </Row>
    </>
  );
};

export default Phase03;
