/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Spinner } from 'react-bootstrap';

import Partner from '../Partner';
import { getPartnerCompanies } from '../../services/queries';
import { PartnerCompany } from '../../services/types';

import './styles.scss';
import Footer from '../Footer';

const PartnersList: React.FC = () => {
  const [partnersList, handlePartnersList] = useState([]);
  const getAllPartnersCompanies = () => {
    getPartnerCompanies()
      .then(async response =>
        handlePartnersList(response.data.data.partnerCompanies),
      )
      .catch(async error => {
        alert(error);
        return 0;
      });
  };

  useEffect(() => {
    getAllPartnersCompanies();
  }, []);

  return (
    <>
      {partnersList.length > 0 ? (
        <>
          <Container className="partners" fluid>
            <Col className="justify-content">
              <h2>Parceiros</h2>
            </Col>
            <Col>
              <p>
                Os locadores colocarão os seus produtos a disposição de um
                mercado formado entre outros, por construtoras, concessionárias,
                industrias, pessoas física e este mercado terá a disposição uma
                ferramenta para informar as suas reais necessidades, o que
                facilitará a comunicação entre as partes usuárias.
              </p>
            </Col>
            <Row>
              {partnersList.map((partner: PartnerCompany) => (
                <Col key={partner.id} xs={12} sm={12} md={6} lg={4}>
                  <Partner
                    {...{
                      id: partner.id,
                      corporateName: partner.corporateName,
                      image: partner.image,
                      description: partner.description,
                    }}
                  />
                </Col>
              ))}
            </Row>
          </Container>
          <Footer />
        </>
      ) : (
        <div className="justify-content text-center">
          <Spinner
            className="spinner-loading"
            as="span"
            animation="border"
            role="status"
            aria-hidden="true"
          />
        </div>
      )}
    </>
  );
};

export default PartnersList;
