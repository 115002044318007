import React from 'react';
import './styles.scss';

import { Container, Row, Col, Button } from 'react-bootstrap';

import { useHistory } from 'react-router-dom';

import ArchitectIcon from '../../images/architect.png';
import RenovationIcon from '../../images/renovation.png';
import TeamIcon from '../../images/team.png';

const ApresentationMPC: React.FC = () => {
  const history = useHistory();

  return (
    <Container fluid className="container-apresentation">
      <Row className="description-platform">
        <Col xl={5}>
          <p>
            Máquinas para Construção é a plataforma eficiente para unir quem
            precisa de máquinas a quem tem as melhores máquinas disponíveis no
            mercado.
          </p>
        </Col>
      </Row>
      <Row className="container-row-apresentation">
        <Col xl={4} className="container-col">
          <img src={ArchitectIcon} alt="architect" />
          <Button type="submit" onClick={() => history.push('login')}>
            Preciso de máquinas
          </Button>
        </Col>
        <Col xl={4} className="container-col">
          <img src={TeamIcon} alt="team" />
          <p>
            Máquinas para Construção é o elo entre locação, contratação de
            serviços e vendas de máquinas.
          </p>
        </Col>
        <Col xl={4} className="container-col">
          <img src={RenovationIcon} alt="renovation" />
          <Button
            type="submit"
            onClick={() => history.push('sejaumlocadormpc')}
          >
            Tenho máquinas
          </Button>
        </Col>
      </Row>
      <Row className="description-platform">
        <Col xl={6}>
          <p>
            Aqui você encontra um dos maiores bancos de dados de máquinas /
            equipamentos / ferramentas existentes para o mercado de Construção
            Civil, Manutenção e Prestadores de serviços.
            <br />
            São informações sobre mais de 10.000 tipos de máquinas, veículos,
            aeronaves, embarcações, etc.
            <br />
            Máquinas Para Construção é o seu canal para pesquisa.
            <br />
            Se você possui máquina disponível, a gente disponibiliza em nosso
            banco de dados e te coloca em contato com quem está precisando
            locar, contratar e comprar.
            <br />
            Faça de Máquinas para Construção seu mais poderoso agente comercial
            ou um importante aliado do seu setor de compras.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default ApresentationMPC;
