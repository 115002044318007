import React from 'react';

import ContactForm from '../../components/ContactForm';
import PagePath from '../../components/PagePath';
import Header from '../../components/Header';

const Contact: React.FC = () => (
  <>
    <Header />
    <PagePath />
    <ContactForm />
  </>
);

export default Contact;
