/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import './styles.scss';

import { Col, Card, Button, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import SelectSearch from 'react-select-search';
import { ApplicationState } from '../../store';
import { checkAdvancedSearch } from '../../services/validation';
import { MachineQuotationInput } from '../../services/types';
import { Item } from '../../store/ducks/cart/types';
import { clearCart } from '../../store/ducks/cart/actions';
import { createQuotation } from '../../services/mutations';

const CartCredential: React.FC = () => {
  const constructionCompanyState = useSelector(
    (state: ApplicationState) => state.constructionCompany,
  );

  const [constructionState, handleConstructionState] = useState<string>('');
  const [constructionPlace, handleConstructionPlace] = useState<string>(
    'CAPITAL',
  );
  const [contactAuthorizationPhone, handleContactAuthorizationPhone] = useState<
    boolean
  >(false);
  const [contactAuthorizationEmail, handleContactAuthorizationEmail] = useState<
    boolean
  >(false);
  const [
    contactAuthorizationWhatsApp,
    handleContactAuthorizationWhastApp,
  ] = useState<boolean>(false);
  const [noCompletedRegister, handleNoCompletedRegister] = useState<boolean>(
    false,
  );

  const cart = useSelector((state: ApplicationState) => state.cart);

  const { constructionCompany, user } = constructionCompanyState;

  const dispatch = useDispatch();

  const sendQuotation = (event: React.FormEvent) => {
    event.preventDefault();
    if (checkAdvancedSearch('state', constructionState)) {
      const machinesQuotation: MachineQuotationInput[] = [];
      cart.items.map((machine: Item | any) =>
        machine.id !== ''
          ? machinesQuotation.push({
              modelId: machine.modelId,
              fabricationYear: Number(machine.fabricationYear),
              leaseTerm: machine.leaseTerm,
              quantity: machine.quantity,
              operation: machine.operation,
              sale: machine.onSale,
              rent: machine.onRent,
              service: machine.onService,
            })
          : machinesQuotation.push({
              modelId: machine.modelId,
              fabricationYear: Number(machine.fabricationYear),
              leaseTerm: machine.leaseTerm,
              quantity: machine.quantity,
              operation: machine.operation,
              sale: machine.onSale,
              rent: machine.onRent,
              service: machine.onService,
            }),
      );

      createQuotation(
        {
          constructionState,
          constructionPlace,
          machines: machinesQuotation,
          contactAuthorizationEmail,
          contactAuthorizationPhone,
          contactAuthorizationWhatsApp,
        },
        user.token,
      ).then(async response => {
        if (
          checkAdvancedSearch('response', response.data.data.createQuotation)
        ) {
          dispatch(clearCart());
        }
      });
    }
  };

  const selectAuthorization = () => {
    const { ownerPhone, ownerWhatsApp, ownerEmail } = constructionCompany;

    if (!ownerEmail && !ownerPhone && !ownerWhatsApp) {
      handleNoCompletedRegister(true);
    }
  };

  return (
    <Col className="cart-credential">
      {user.token === '' ? (
        <>
          <h4>
            Efetue o <Link to="/login"> login ou crie sua conta </Link>
            para solicitar um orçamento. É grátis!
          </h4>
        </>
      ) : (
        <>
          <h2>Dados</h2>
          <Card>
            <Card.Body>
              <Form onSubmit={(event: React.FormEvent) => sendQuotation(event)}>
                <Form.Group>
                  <Form.Label>Empresa solicitante:</Form.Label>{' '}
                  {constructionCompany.companyName}
                </Form.Group>
                <Form.Group>
                  <Form.Label>E-mail:</Form.Label> {constructionCompany.email}
                </Form.Group>
                <Form.Group>
                  <Form.Label>Estado</Form.Label>
                  <SelectSearch
                    options={[
                      { name: 'Acre', value: 'AC' },
                      { name: 'Alagoas', value: 'AL' },
                      { name: 'Amapá', value: 'AP' },
                      { name: 'Amazonas', value: 'AM' },
                      { name: 'Bahia', value: 'BA' },
                      { name: 'Ceará', value: 'CE' },
                      { name: 'Distrito Federal', value: 'DF' },
                      { name: 'Espírito Santo', value: 'ES' },
                      { name: 'Goiás', value: 'GO' },
                      { name: 'Maranhão', value: 'MA' },
                      { name: 'Mato Grosso', value: 'MT' },
                      { name: 'Mato Grosso do Sul', value: 'MS' },
                      { name: 'Minas Gerais', value: 'MG' },
                      { name: 'Pará', value: 'PA' },
                      { name: 'Paraíba', value: 'PB' },
                      { name: 'Paraná', value: 'PR' },
                      { name: 'Pernambuco', value: 'PE' },
                      { name: 'Piauí', value: 'PI' },
                      { name: 'Rio de Janeiro', value: 'RJ' },
                      { name: 'Rio Grande do Norte', value: 'RN' },
                      { name: 'Rio Grande do Sul', value: 'RS' },
                      { name: 'Rondônia', value: 'RO' },
                      { name: 'Roraima', value: 'RR' },
                      { name: 'Santa Catarina', value: 'SC' },
                      { name: 'São Paulo', value: 'SP' },
                      { name: 'Sergipe', value: 'SE' },
                      { name: 'Tocantins', value: 'TO' },
                    ]}
                    value={constructionState}
                    placeholder="Selecione um Estado..."
                    search
                    onChange={event => {
                      handleConstructionState(String(event));
                    }}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Localidade</Form.Label>
                  <Form.Group>
                    <input
                      type="radio"
                      id="radioItem"
                      name="radioItem"
                      value={constructionPlace}
                      checked={constructionPlace === 'CAPITAL'}
                      onChange={() => handleConstructionPlace('CAPITAL')}
                    />
                    <label htmlFor="radioItem">
                      Capital e região metropolitana
                    </label>
                    <input
                      type="radio"
                      id="radioItem"
                      name="radioItem"
                      value={constructionPlace}
                      checked={constructionPlace === 'INLAND'}
                      onChange={() => handleConstructionPlace('INLAND')}
                    />
                    <label htmlFor="radioItem">Interior</label>
                  </Form.Group>
                </Form.Group>
                <Form.Group>
                  <Form.Label> Autorizar contato </Form.Label>
                  <Form.Group>
                    <input
                      type="checkbox"
                      id="checkboxItemPhone"
                      name="checkboxItemPhone"
                      checked={contactAuthorizationPhone === true}
                      onChange={() => {
                        handleContactAuthorizationPhone(
                          !contactAuthorizationPhone,
                        );
                        selectAuthorization();
                      }}
                    />
                    <label htmlFor="checkboxItemPhone" className="mr-3 ml-1">
                      Telefone
                    </label>
                    <input
                      type="checkbox"
                      id="checkboxItemWpp"
                      name="checkboxItemWpp"
                      checked={contactAuthorizationWhatsApp}
                      onChange={() => {
                        handleContactAuthorizationWhastApp(
                          !contactAuthorizationWhatsApp,
                        );
                        selectAuthorization();
                      }}
                    />
                    <label htmlFor="checkboxItemWpp" className="mr-3 ml-1">
                      WhatsApp
                    </label>
                    <input
                      type="checkbox"
                      id="checkboxItemEmail"
                      name="checkboxItemEmail"
                      checked={contactAuthorizationEmail}
                      onChange={() => {
                        handleContactAuthorizationEmail(
                          !contactAuthorizationEmail,
                        );
                        selectAuthorization();
                      }}
                    />
                    <label htmlFor="checkboxItemEmail" className="mr-3 ml-1">
                      Email
                    </label>
                  </Form.Group>
                </Form.Group>
                {!noCompletedRegister ? (
                  <Button type="submit">Enviar solicitação</Button>
                ) : (
                  <h5>
                    Para solicitar orçamento é necessário ter os campos de dados
                    do responsável preenchidos,
                    <Link to="/perfil/configs"> clique aqui</Link> para concluir
                    o cadastro.
                  </h5>
                )}
              </Form>
            </Card.Body>
          </Card>
        </>
      )}
    </Col>
  );
};

export default CartCredential;
