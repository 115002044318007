import {
  Machine,
  DeliveryState,
  Quotation,
} from '../../../services/types';
/**
 * Action types
 * @LOAD_RENTAL_COMPANY load rentalCompany data to store
 * @UPDATE_RENTAL_COMPANY handle rentalCompany infos
 * @REMOVE_RENTAL_COMPANY remove rentalCompany infos
 */
export enum RentalCompanyTypes {
  LOAD_RENTAL_COMPANY = '@rentalCompany/LOAD_RENTAL_COMPANY',
  UPDATE_RENTAL_COMPANY = '@rentalCompany/HANDLE_RENTAL_COMPANY',
  REMOVE_RENTAL_COMPANY = '@rentalCompany/REMOVE_RENTAL_COMPANY',
}

/**
 * Data types
 * @token : id of merchant
 * @role : url of merchant
 */

export interface User {
  token: string;
  role: string;
}

export interface RentalCompany {
  id: string;
  machines: Machine[];
  deliveryRadius: DeliveryState[];
  corporateName: string;
  description: string;
  cnpjOrCpf: string;
  address: string;
  phone: string;
  whatsApp: string;
  email: string;
  planId: string;
  image: string;
  status: boolean;
  state: string;
  city: string;
  ownerName: string;
  ownerEmail: string;
  ownerPhone: string;
  ownerWhatsApp: string;
  createdAt: string;
  lastLogin: string;
}

/**
 * State type
 * @data : the rentalCompany
 */
export interface RentalCompanyState {
  user: User;
  rentalCompany: RentalCompany;
  rentalCompanyQuotations: Quotation[];
}
