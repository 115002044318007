/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Col,
  Row,
  Card,
  Spinner,
  Form,
  Button,
  Collapse,
} from 'react-bootstrap';
import { getMachines } from '../../services/queries';
import { Machine, MachineTypes, DeliveryState } from '../../services/types';
import { insertMachine } from '../../store/ducks/cart/actions';
import { removeSpecialChars } from '../../services/mask';

import './styles.scss';
import { PagePathManual } from '../PagePath';
import Footer from '../Footer';
import {
  getMachineCategoryById,
  getMachineTypeByName,
  orderByModel,
} from '../../services/functions';
import FontAwesome from '../FontAwesome';
import CartTable from '../CartTable';

const MachineDetail: React.FC = () => {
  const URI = window.location.pathname.substring(1).split('/');

  const dispatch = useDispatch();

  const [machine, handleMachine] = useState<Machine | any>({
    id: '',
    image: '',
    rentalCompanyId: '',
    categoryId: '',
    machineTypeId: '',
    model: '',
    operation: false,
    fabricationYear: 0,
    minimumLeaseTerm: 0,
    description: '',
    comments: '',
    city: '',
    state: '',
    deliveryRadius: [],
    onSale: false,
    onRent: false,
    onService: false,
    createdAt: '',
  });

  const [category, handleCategory] = useState<{
    id: string;
    name: string;
    background: string;
  }>({
    id: '',
    name: '',
    background: '',
  });

  const [machineType, handleMachineType] = useState<MachineTypes>({
    id: '',
    name: '',
    categoryId: '',
    machines: [],
    createdAt: '',
  });

  const [quantity, handleQuantity] = useState<number>(1);
  const [operation, handleOperation] = useState<number>(0);
  const [sale, handleSale] = useState<number>(0);
  const [rent, handleRent] = useState<number>(0);
  const [service, handleService] = useState<number>(0);
  const [time, handleTime] = useState<number>(0);

  const [openConfirm, handleOpenConfirm] = useState<boolean>(false);

  const getAllMachines = async () => {
    await getMachineTypeByName(URI[2])
      .then(async machineTypeItem => {
        handleMachineType(machineTypeItem);
        await getMachineCategoryById(machineTypeItem.categoryId)
          .then(resp => {
            handleCategory(resp);
          })
          .catch(async error => {
            // eslint-disable-next-line no-alert
            alert(error);
            return 0;
          });
        await getMachines()
          .then(async response => {
            const temp: Machine[] = orderByModel(
              response.data.data.machines,
            ).filter(
              (machineItem: Machine | any) =>
                machineItem.equipamentMachineId === machineTypeItem.id &&
                machineItem.categoryId === machineTypeItem.categoryId &&
                removeSpecialChars(machineItem.modelId) === URI[3],
            );
            handleMachine(temp[0]);
            handleTime(temp[0].minimumLeaseTerm);
          })
          .catch(async error => {
            // eslint-disable-next-line no-alert
            alert(error);
            return 0;
          });
      })
      .catch(async error => {
        // eslint-disable-next-line no-alert
        alert(error);
        return 0;
      });
  };

  const addToCart = (event: React.FormEvent) => {
    event.preventDefault();
    const data = {
      id: machine.id,
      image: category.background,
      model: machine.model,
      modelId: machine.modelId,
      categoryName: category.name,
      manufacturer: machine.manufacturer,
      categoryId: category.id,
      machineTypeName: machineType.name,
      EquipamentMachineId: machineType.id,
      operation: operation === 1,
      fabricationYear: machine.fabricationYear,
      leaseTerm: time,
      onRent: rent === 1,
      onSale: sale === 1,
      onService: service === 1,
      quantity,
    };
    console.log('data', data);

    dispatch(insertMachine(data));
  };

  useEffect(() => {
    getAllMachines();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {machine.id !== '' ? (
        <>
          <PagePathManual
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...{
              link: machine.model.toLowerCase(),
              subLink: category.name,
              subSubLink: machineType.name,
            }}
          />
          <Col className="machine-detail">
            <Row>
              <Col xs={12} md={12} sm={12} lg={4}>
                <Row>
                  <Col>
                    <b>Categoria</b>
                    <p> {category.name.toUpperCase()} </p>
                    <b>Equipamento/Máquina</b>
                    <p> {machine.equipamentMachine} </p>
                    <Row className="row-model-fabrication">
                      <Col>
                        <b>Modelo</b>
                        <p> {machine.model} </p>
                      </Col>
                      {/* <Col>
                        <b>Ano de fabricação</b>
                        <p> {machine.fabricationYear} </p>
                      </Col> */}
                    </Row>
                    <b>Fabricante</b>
                    <p> {machine.manufacturer} </p>
                  </Col>
                </Row>
                <h4 className="title-capability">Capacidade Operacional</h4>
                <Card className="capability-operational">
                  <Row>
                    <Col>
                      <b>Referencial</b>
                      <p> {machine.operationalCapacityReferential} </p>
                    </Col>
                    <Col>
                      <b>Quantitativo</b>
                      <p> {machine.operationalCapacityQuantitative} </p>
                    </Col>
                    <Col>
                      <b>Unidade</b>
                      <p> {machine.operationalCapacityUnit} </p>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col xs={12} md={12} sm={12} lg={3}>
                <b>Prazo mínimo de locação</b>
                <p>
                  {machine.minimumLeaseTerm > 1
                    ? `${machine.minimumLeaseTerm} meses`
                    : `${machine.minimumLeaseTerm} mês`}
                </p>
                <b>Locação?</b>
                <p> {machine.onRent ? 'Sim' : 'Não'} </p>
                <b>Prestação de serviço?</b>
                <p> {machine.onService ? 'Sim' : 'Não'} </p>
                <b>À venda?</b>
                <p> {machine.onSale ? 'Sim' : 'Não'} </p>
                <b>Possui operador?</b>
                <p> {machine.operation ? 'Sim' : 'Não'} </p>
                <b>Área de cobertura</b>
                <p>
                  {machine.deliveryRadius.map(
                    (deliveryState: DeliveryState, index: number) => (
                      <li key={`${deliveryState.state + index}`}>
                        {deliveryState.state}:{' '}
                        {deliveryState.deliveryInCapital &&
                        !deliveryState.deliveryInInland
                          ? 'Capital e região metropolitana'
                          : ''}
                        {deliveryState.deliveryInInland &&
                        deliveryState.deliveryInCapital
                          ? 'Capital, região metropolitana e interior'
                          : ''}
                        {deliveryState.deliveryInInland &&
                        !deliveryState.deliveryInCapital
                          ? 'Apenas interior'
                          : ''}{' '}
                      </li>
                    ),
                  )}
                </p>
              </Col>
              <Col xs={12} md={12} sm={12} lg={5}>
                <Card>
                  <Card.Body className="text-center">
                    <Card.Title className="dashed">
                      <h4>Opções</h4>
                    </Card.Title>
                    <Form
                      onSubmit={(event: React.FormEvent) => addToCart(event)}
                    >
                      <Card.Subtitle>Prazo em meses para locação</Card.Subtitle>
                      <Row>
                        <Col xs={3}>
                          <Button
                            className="btn-qty"
                            onClick={() => {
                              if (time > machine.minimumLeaseTerm) {
                                handleTime(time - 1);
                              }
                            }}
                          >
                            <FontAwesome type="fas" name="minus" />
                          </Button>
                        </Col>
                        <Col xs={6}>
                          <input
                            className="input-qty"
                            type="number"
                            value={time}
                            disabled
                          />
                        </Col>
                        <Col xs={3}>
                          <Button
                            className="btn-qty"
                            onClick={() => {
                              if (time < 99) handleTime(time + 1);
                            }}
                          >
                            <FontAwesome type="fas" name="plus" />
                          </Button>
                        </Col>
                      </Row>
                      <Card.Subtitle>Quantidade</Card.Subtitle>
                      <Row>
                        <Col xs={3}>
                          <Button
                            className="btn-qty"
                            onClick={() => {
                              if (quantity > 1) handleQuantity(quantity - 1);
                            }}
                          >
                            <FontAwesome type="fas" name="minus" />
                          </Button>
                        </Col>
                        <Col xs={6}>
                          <input
                            className="input-qty"
                            type="number"
                            value={quantity}
                            disabled
                          />
                        </Col>
                        <Col>
                          <Button
                            className="btn-qty"
                            onClick={() => {
                              if (quantity < 99) handleQuantity(quantity + 1);
                            }}
                          >
                            <FontAwesome type="fas" name="plus" />
                          </Button>
                        </Col>
                      </Row>
                      {machine.operation && (
                        <Col>
                          <Form.Label>Com operador?</Form.Label>
                          <Row className="operator justify-content">
                            <input
                              type="radio"
                              id="radioItem1"
                              name="radioItem1"
                              value={operation}
                              checked={operation === 1}
                              onChange={() => handleOperation(1)}
                            />
                            <label className="mt-2" htmlFor="radioItem1">
                              Sim
                            </label>
                            <input
                              type="radio"
                              id="radioItem2"
                              name="radioItem2"
                              value={operation}
                              checked={operation === 0}
                              onChange={() => handleOperation(0)}
                            />
                            <label className="mt-2" htmlFor="radioItem2">
                              Não
                            </label>
                          </Row>
                        </Col>
                      )}
                      {machine.onRent && (
                        <Col>
                          <Form.Label>Alugar?</Form.Label>
                          <Row className="operator justify-content">
                            <input
                              type="radio"
                              id="radioItem9"
                              name="radioItem9"
                              value={rent}
                              checked={rent === 1}
                              onChange={() => handleRent(1)}
                            />
                            <label className="mt-2" htmlFor="radioItem9">
                              Sim
                            </label>
                            <input
                              type="radio"
                              id="radioItem99"
                              name="radioItem99"
                              value={rent}
                              checked={rent === 0}
                              onChange={() => handleRent(0)}
                            />
                            <label className="mt-2" htmlFor="radioItem99">
                              Não
                            </label>
                          </Row>
                        </Col>
                      )}
                      {machine.onSale && (
                        <Col>
                          <Form.Label>Comprar?</Form.Label>
                          <Row className="operator justify-content">
                            <input
                              type="radio"
                              id="radioItem3"
                              name="radioItem3"
                              value={sale}
                              checked={sale === 1}
                              onChange={() => handleSale(1)}
                            />
                            <label className="mt-2" htmlFor="radioItem3">
                              Sim
                            </label>
                            <input
                              type="radio"
                              id="radioItem4"
                              name="radioItem4"
                              value={sale}
                              checked={sale === 0}
                              onChange={() => handleSale(0)}
                            />
                            <label className="mt-2" htmlFor="radioItem4">
                              Não
                            </label>
                          </Row>
                        </Col>
                      )}
                      {machine.onService && (
                        <Col>
                          <Form.Label>Prestação de serviço?</Form.Label>
                          <Row className="operator justify-content">
                            <input
                              type="radio"
                              id="radioItem5"
                              name="radioItem5"
                              value={service}
                              checked={service === 1}
                              onChange={() => handleService(1)}
                            />
                            <label className="mt-2" htmlFor="radioItem5">
                              Sim
                            </label>
                            <input
                              type="radio"
                              id="radioItem6"
                              name="radioItem6"
                              value={service}
                              checked={service === 0}
                              onChange={() => handleService(0)}
                            />
                            <label className="mt-2" htmlFor="radioItem6">
                              Não
                            </label>
                          </Row>
                        </Col>
                      )}
                      <Button
                        className="mt-4"
                        type="submit"
                        onClick={() => handleOpenConfirm(!openConfirm)}
                        aria-controls="example-collapse-text-a"
                        aria-expanded={openConfirm}
                      >
                        Adicionar ao carrinho
                      </Button>
                      <Collapse in={openConfirm}>
                        <div id="example-collapse-text-a">
                          Máquina adicionada ao carrinho! Confira seu Carrinho
                          abaixo.
                        </div>
                      </Collapse>
                    </Form>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <CartTable />
          </Col>
          <Footer />
        </>
      ) : (
        <Spinner
          className="spinner-loading"
          as="span"
          animation="border"
          role="status"
          aria-hidden="true"
        />
      )}
    </>
  );
};

export default MachineDetail;
