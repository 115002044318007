import React from 'react';
import Header from '../../components/Header';
import MachinesTypesPanel from '../../components/MachinesTypesPanel';

// import { Container } from './styles';

const MachinesTypes: React.FC = () => (
  <>
    <Header />
    <section className="fade-in">
      <MachinesTypesPanel />
    </section>
  </>
);

export default MachinesTypes;
