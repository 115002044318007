import SimpleCrypto from 'simple-crypto-js';

const simpleCripto = new SimpleCrypto(process.env.REACT_APP_CRIPTO_KEY || '');

const constructionCompanyAuth = {
  encryptedToken:
    localStorage.getItem(
      process.env.REACT_APP_LOCAL_STORAGE_CONSTRUCTION_COMPANY_AUTH || '',
    ) || '',
  token: '',
};

const rentalCompanyAuth = {
  encryptedToken:
    localStorage.getItem(
      process.env.REACT_APP_LOCAL_STORAGE_RENTAL_COMPANY_AUTH || '',
    ) || '',
  token: '',
};

export const isAuthenticated = () => {
  if (constructionCompanyAuth.encryptedToken !== '') {
    constructionCompanyAuth.token = String(
      simpleCripto.decrypt(JSON.parse(constructionCompanyAuth.encryptedToken)),
    );
    if (constructionCompanyAuth.token !== '') {
      return true;
    }
    return false;
  }
  return false;
};

export const rentalCompanyAuthenticated = () => {
  if (rentalCompanyAuth.encryptedToken !== '') {
    rentalCompanyAuth.token = String(
      simpleCripto.decrypt(JSON.parse(rentalCompanyAuth.encryptedToken)),
    );
    if (rentalCompanyAuth.token !== '') {
      return true;
    }
    return false;
  }
  return false;
};

export const getToken = () => {
  if (constructionCompanyAuth.encryptedToken !== '') {
    constructionCompanyAuth.token = String(
      simpleCripto.decrypt(JSON.parse(constructionCompanyAuth.encryptedToken)),
    );
    // console.log('constructionCompanyAuth.token', constructionCompanyAuth.token);
    if (constructionCompanyAuth.token !== '') {
      return constructionCompanyAuth.token;
    }
    return '';
  }
  return '';
};

export const getRentalCompanyToken = () => {
  if (rentalCompanyAuth.encryptedToken !== '') {
    rentalCompanyAuth.token = String(
      simpleCripto.decrypt(JSON.parse(rentalCompanyAuth.encryptedToken)),
    );
    // console.log('rentalCompanyAuth.token', rentalCompanyAuth.token);
    if (rentalCompanyAuth.token !== '') {
      return rentalCompanyAuth.token;
    }
    return '';
  }
  return '';
};
