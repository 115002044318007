/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import './styles.scss';
import { Container } from 'react-bootstrap';
import { PlansType, RentalCompanyInput } from '../../services/types';
import Plans from './Plans';
import Phase01 from './Phase01';
import Phase02 from './Phase02';
import Phase03 from './Phase03';
import SuccessPage from './SuccessPage';

interface FormPhase01 {
  planId: string;
  cnpjOrCpf: string;
  corporateName: string;
  address: string;
  phone: string;
  whatsApp?: string;
}

interface FormPhase02 {
  ownerName: string;
  ownerEmail: string;
  ownerPhone: string;
  ownerWhatsApp: string;
  email: string;
  password: string;
  acceptanceTermsOfUse: boolean;
}

const CreateAccountRental: React.FC = () => {
  const [actualPage, handleActualPage] = useState('plans');
  const [formRentalCompany, handleFormRentalCompany] = useState<
    RentalCompanyInput
  >({
    acceptanceTermsOfUse: false,
    email: '',
    password: '',
    corporateName: '',
    cnpjOrCpf: '',
    address: '',
    phone: '',
    whatsApp: '',
    planId: '',
    ownerName: '',
    ownerEmail: '',
    ownerPhone: '',
    ownerWhatsApp: '',
  });

  sessionStorage.setItem('@MPC/form', JSON.stringify(formRentalCompany));

  return (
    <Container fluid className="contianer-create-rental">
      {actualPage === 'plans' && (
        <Plans
          selectedPlan={(plan: PlansType) => {
            handleFormRentalCompany({ ...formRentalCompany, planId: plan.id });
            handleActualPage('phase01');
          }}
        />
      )}
      {actualPage === 'phase01' && (
        <Phase01
          idPlan={formRentalCompany.planId}
          selectFormPhase01={(formPhase01: FormPhase01) => {
            handleFormRentalCompany({
              ...formRentalCompany,
              planId: formPhase01.planId,
              cnpjOrCpf: formPhase01.cnpjOrCpf,
              corporateName: formPhase01.corporateName,
              address: formPhase01.address,
              phone: formPhase01.phone,
              whatsApp: formPhase01.whatsApp,
            });
            handleActualPage('phase02');
          }}
        />
      )}
      {actualPage === 'phase02' && (
        <Phase02
          goBack={() => handleActualPage('phase01')}
          selectFormPhase02={(formPhase02: FormPhase02) => {
            handleFormRentalCompany({
              ...formRentalCompany,
              ownerName: formPhase02.ownerName,
              ownerEmail: formPhase02.ownerEmail,
              ownerPhone: formPhase02.ownerPhone,
              ownerWhatsApp: formPhase02.ownerWhatsApp,
              email: formPhase02.email,
              password: formPhase02.password,
              acceptanceTermsOfUse: formPhase02.acceptanceTermsOfUse,
            });
            handleActualPage('phase03');
          }}
        />
      )}
      {actualPage === 'phase03' && (
        <Phase03
          paymentSuccess={() => handleActualPage('success')}
          goBack={() => handleActualPage('phase02')}
        />
      )}
      {actualPage === 'success' && <SuccessPage />}
    </Container>
  );
};

export default CreateAccountRental;
