/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import { Col, Row, Form } from 'react-bootstrap';

import './styles.scss';
import { Link } from 'react-router-dom';
import SelectSearch from 'react-select-search';
import FontAwesome from '../FontAwesome';
import { refactorCategoryName, removeSpecialChars } from '../../services/mask';
import { getCategories, getMachinesTypes } from '../../services/queries';
import { Category, Options, MachineTypes } from '../../services/types';
import { orderByName } from '../../services/functions';

const CategoryBanner: React.FC = () => {
  const [categoryId, handleCategoryId] = useState('');
  const [categoryName, handleCategoryName] = useState('');
  const [machineType, handleMachineType] = useState('');
  const [categoriesList, handleCategoriesList] = useState([]);
  const [machinesTypes, handleMachinesTypes] = useState([]);

  const getAllCategories = () => {
    getCategories()
      .then(async response =>
        handleCategoriesList(orderByName(response.data.data.categories)),
      )
      .catch(async error => {
        // eslint-disable-next-line no-alert
        alert(error);
        return 0;
      });
  };

  const getAllMachinesTypes = () => {
    getMachinesTypes()
      .then(async response =>
        handleMachinesTypes(orderByName(response.data.data.equipamentMachines)),
      )
      .catch(async error => {
        // eslint-disable-next-line no-alert
        alert(error);
        return 0;
      });
  };

  const getCategoriesOptions = () => {
    const categoriesOptions: Options[] = [];
    categoriesList.map((categoryItem: Category) =>
      categoriesOptions.push({
        name: refactorCategoryName(categoryItem.name),
        value: categoryItem.id,
      }),
    );
    return categoriesOptions;
  };

  const selectCategory = (IdCategory: string) => {
    handleCategoryId(IdCategory);
    categoriesList.map((categoryItem: Category) => {
      if (categoryItem.id === IdCategory) {
        handleCategoryName(removeSpecialChars(categoryItem.name));
      }
    });
  };

  const selectEquipamentMachines = (IdEquipamentMachines: string) => {
    machinesTypes
      .filter(
        (machineTypeFilter: MachineTypes) =>
          machineTypeFilter.categoryId === categoryId,
      )
      .map((machineTypeFilterItem: MachineTypes) => {
        if (machineTypeFilterItem.id === IdEquipamentMachines) {
          handleMachineType(removeSpecialChars(machineTypeFilterItem.name));
        }
      });
  };

  const getMachinesTypesOptions = () => {
    const machinesOptions: Options[] = [];
    try {
      machinesTypes
        .filter(
          (machineTypeFilter: MachineTypes) =>
            machineTypeFilter.categoryId === categoryId,
        )
        .map((machineTypeFilterItem: MachineTypes) =>
          machinesOptions.push({
            name: machineTypeFilterItem.name,
            value: machineTypeFilterItem.id,
          }),
        );
      return machinesOptions;
    } catch (error) {
      return [];
    }
  };

  useEffect(() => {
    getAllCategories();
  }, []);

  return (
    <section>
      <Col xs={12} className="category-banner">
        <Row className="banner-row">
          <h1>Busque aqui a máquina que você precisa</h1>
          <Form
            inline
            onSubmit={(event: React.FormEvent) => event.preventDefault()}
          >
            <SelectSearch
              placeholder="Selecione a categoria desejada"
              className="select-search category"
              search
              options={getCategoriesOptions()}
              onChange={event => {
                selectCategory(String(event));
                getAllMachinesTypes();
                handleMachineType('');
              }}
            />
            <SelectSearch
              placeholder="Selecione equipamento/máquina"
              className="select-search equipament"
              options={getMachinesTypesOptions()}
              disabled={machinesTypes.length === 0}
              onChange={event => {
                selectEquipamentMachines(String(event));
              }}
            />
            <Link
              className="btn-search"
              to={`/categorias/${categoryName}/${machineType}`}
              style={
                machinesTypes.length === 0 || machineType === ''
                  ? { pointerEvents: 'none' }
                  : {}
              }
            >
              <FontAwesome name="search" type="fas" />
            </Link>
          </Form>

          <p>
            Já possui a lista completa?
            <Link to="/orcamento"> Clique aqui </Link>e solicite o seu orçamento
          </p>
        </Row>
        <small>
          <a href="https://br.freepik.com/fotos/fundo">
            Fundo foto criado por onlyyouqj - br.freepik.com
          </a>
        </small>
      </Col>
    </section>
  );
};

export default CategoryBanner;
