/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';

import './styles.scss';

import { Machine as MachineItem } from '../../services/types';
// import Imagem from '../../images/banner.jpg';

export interface Props {
  imagePath: string;
}

interface ImageProps {
  source: string;
}

const Machine: React.FC<MachineItem> = (props: MachineItem | any) => {
  const {
    model,
    equipamentMachine,
    manufacturer,
    operationalCapacityReferential,
    operationalCapacityQuantitative,
    operationalCapacityUnit,
  } = props;

  return (
    <Card className="machine fade-in">
      <Row>
        {/* <Col xl={5} style={{ padding: 0 }}>
          <CategoryImage source={image.replace(/'/g, '')} />
        </Col> */}
        <Col>
          <Card.Text>Equip/Máquina</Card.Text>
          <p style={{ minHeight: 60, maxHeight: 60 }}>{equipamentMachine}</p>
          <Card.Text>Modelo</Card.Text>
          <p style={{ minHeight: 40, maxHeight: 40 }}>{model}</p>
          <Card.Text>Fabricante</Card.Text>
          <p style={{ minHeight: 40, maxHeight: 40 }}>{manufacturer}</p>
        </Col>
      </Row>
      <p>Capacidade Operacional</p>
      <Card.Footer>
        <Row>
          <Col>
            <Card.Text>Referencial</Card.Text>
            <p>{operationalCapacityReferential}</p>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <Card.Text>Quantitativo</Card.Text>
            <p> {operationalCapacityQuantitative} </p>
          </Col>
          <Col xs={6}>
            <Card.Text>Unidade</Card.Text>
            <p> {operationalCapacityUnit} </p>
          </Col>
        </Row>
      </Card.Footer>
    </Card>
  );
};

export default Machine;
