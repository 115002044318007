import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Header from '../../components/Header';
import PagePath from '../../components/PagePath';
import Login, { RentalCompanyLogin } from '../../components/Login';
import CreateAccount from '../../components/CreateAccount';
import Footer from '../../components/Footer';

import Banner from '../../images/category-banner.jpg';

const Access: React.FC = () => (
  <>
    <Header />
    <PagePath />
    <section className="fade-in">
      <Row>
        <Col xs={12} sm={12} md={6} lg={6}>
          <Login />
        </Col>
        <Col xs={12} sm={12} md={6} lg={6}>
          <CreateAccount />
        </Col>
      </Row>
    </section>
    <Footer />
  </>
);

export const RentalCompanyAccess: React.FC = () => (
  <div style={{ backgroundImage: `url(${Banner})` }}>
    <section
      className="fade-in center"
      style={{ color: 'orange', height: '71vh' }}
    >
      <RentalCompanyLogin />
    </section>
    <Footer />
  </div>
);

export default Access;
