/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import './styles.scss';
import { Container } from 'react-bootstrap';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import { getCategories } from '../../services/queries';
import { Category } from '../../services/types';
import { refactorCategoryName, removeSpecialChars } from '../../services/mask';
import { settings } from './settingsSlick';
import { orderByName } from '../../services/functions';

const CategorySlick: React.FC = () => {
  const [categoriesList, handleCategoriesList] = useState<Category[]>([]);
  const [categorySelect, handleCategoriesSelect] = useState<Category>();
  const URI = window.location.pathname.substring(1).split('/')[1];

  const getAllCategories = () => {
    getCategories()
      .then(async response => {
        const categories: Category[] = await response.data.data.categories;

        // const formattedCategories = categories.sort(
        //   (a, b) => Number(a.id) - Number(b.id),
        // );

        handleCategoriesList(orderByName(categories));

        categories.forEach((category: Category) => {
          if (URI === removeSpecialChars(category.name)) {
            handleCategoriesSelect(category);
          }
        });
      })
      .catch(error => console.log('error', error));
  };

  useEffect(() => {
    getAllCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [URI]);

  return (
    <Container fluid className="container-category-slick">
      {categorySelect && (
        <Slider
          initialSlide={Number(categoriesList.indexOf(categorySelect)) - 1}
          {...settings}
        >
          {categoriesList.map((item: Category) => (
            <Link
              key={item.id}
              className={
                categorySelect.name === item.name
                  ? 'category-item select'
                  : 'category-item'
              }
              to={`/categorias/${removeSpecialChars(item.name)}`}
            >
              <img
                src={item.background.replace(/'/g, '')}
                alt="category"
                className="container-image"
              />
              <p style={categorySelect === item ? { fontWeight: 'bold' } : {}}>
                {refactorCategoryName(item.name)}
              </p>
            </Link>
          ))}
        </Slider>
      )}
    </Container>
  );
};

export default CategorySlick;
