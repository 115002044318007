import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Card, Button, Spinner } from 'react-bootstrap';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { ApplicationState } from '../../store';
import UserConfigs from '../../components/UserConfigs';
import UserQuotations from '../../components/UserQuotations';
import {
  getRentalCompany,
  getRentalCompanyQuotations,
} from '../../services/queries';
import { getRentalCompanyToken } from '../../services/auth';
import RentalCompanyConfigs from '../../components/RentalCompanyConfigs';
import RentalCompanyMachines from '../../components/RentalCompanyMachines';
import RentalCompanyQuotations from '../../components/RentalCompanyQuotations';
import RentalCompanyPlaces from '../../components/RentalCompanyPlaces';
import { removeConstructionCompany } from '../../store/ducks/constructionCompany/actions';
import {
  updateRentalCompany,
  removeRentalCompany,
} from '../../store/ducks/rentalCompany/actions';

import Logo from '../../images/logo/logo.png';

const LeftMenu = styled(Card)`
  padding-top: 5vh;
  margin-top: 5vh;
  margin-bottom: 3.5vh;
  margin-left: 1vh;
`;

const LogoImage = styled.img.attrs({ src: Logo })`
  width: 20%;
  object-fit: cover;
  margin-bottom: 15px;
`;

const switchRender = () => {
  const URI = window.location.pathname.substring(1).split('/');
  if (URI[0] === 'perfil') {
    switch (URI[1]) {
      case 'configs':
        return <UserConfigs />;
      case 'pedidos':
        return <UserQuotations />;
      default:
        console.log('URI[1]', URI[1]);
        return false;
    }
  }
  if (URI[0] === 'locadora') {
    switch (URI[1]) {
      case 'configs':
        return <RentalCompanyConfigs />;
      case 'localidades':
        return <RentalCompanyPlaces />;
      case 'maquinas':
        return <RentalCompanyMachines />;
      case 'pedidos':
        return <RentalCompanyQuotations />;
      default:
        console.log('URI[1]', URI[1]);
        return false;
    }
  }
  return true;
};

export const ConstructionCompanyProfile: React.FC = () => {
  const constructionCompanyState = useSelector(
    (state: ApplicationState) => state.constructionCompany,
  );

  const dispatch = useDispatch();

  const { constructionCompany } = constructionCompanyState;

  const activate =
    window.location.pathname.substring(1).split('/')[1] === 'pedidos'
      ? {
          config: false,
          quotations: true,
        }
      : {
          config: true,
          quotations: false,
        };

  return (
    <>
      <Header />
      {constructionCompany.id !== '' ? (
        <>
          <section className="fade-in">
            <Row>
              <Col xs={12} sm={9} md={3} lg={3}>
                <LeftMenu className="justify-content">
                  <LogoImage />
                  <LeftMenu.Subtitle>
                    <h5>{constructionCompany.companyName}</h5>
                  </LeftMenu.Subtitle>
                  <div className="dot-line" />
                  <LeftMenu.Body>
                    <LeftMenu.Text>
                      <Button
                        as={Link}
                        to="/perfil/configs"
                        className={
                          activate.config
                            ? 'w-100 btnToggleActive'
                            : 'w-100 btnToggle'
                        }
                        onClick={() => {
                          activate.config = true;
                          activate.quotations = false;
                        }}
                      >
                        Configurações
                      </Button>
                    </LeftMenu.Text>
                    <LeftMenu.Text>
                      <Button
                        as={Link}
                        to="/perfil/pedidos"
                        className={
                          activate.quotations
                            ? 'w-100 btnToggleActive'
                            : 'w-100 btnToggle'
                        }
                        onClick={() => {
                          activate.config = false;
                          activate.quotations = true;
                        }}
                      >
                        Orçamentos
                      </Button>
                    </LeftMenu.Text>
                    <LeftMenu.Text>
                      <Button
                        className="w-100 btnToggle"
                        onClick={() => {
                          dispatch(removeConstructionCompany());
                          window.location.href = '/';
                        }}
                      >
                        Sair da conta
                      </Button>
                    </LeftMenu.Text>
                  </LeftMenu.Body>
                </LeftMenu>
              </Col>
              <Col xs={12} sm={9} md={9} lg={9}>
                {switchRender()}
              </Col>
            </Row>
          </section>
          <Footer />
        </>
      ) : (
        <Spinner
          className="spinner-loading"
          as="span"
          animation="border"
          role="status"
          aria-hidden="true"
        />
      )}
    </>
  );
};

export const RentalCompanyProfile: React.FC = () => {
  const rentalCompanyState = useSelector(
    (state: ApplicationState) => state.rentalCompany,
  );

  const dispatch = useDispatch();

  const { rentalCompany } = rentalCompanyState;

  const switchActive = () => {
    switch (window.location.pathname.substring(1).split('/')[1]) {
      case 'configs':
        return {
          configs: true,
          places: false,
          machines: false,
          quotations: false,
        };
      case 'localidades':
        return {
          configs: false,
          places: true,
          machines: false,
          quotations: false,
        };
      case 'pedidos':
        return {
          configs: false,
          places: false,
          machines: false,
          quotations: true,
        };
      case 'maquinas':
        return {
          configs: false,
          places: false,
          machines: true,
          quotations: false,
        };
      default:
        return {
          configs: true,
          places: false,
          machines: false,
          quotations: false,
        };
    }
  };

  const activate: {
    configs: boolean;
    places: boolean;
    machines: boolean;
    quotations: boolean;
  } = switchActive();

  useEffect(() => {
    getRentalCompany(getRentalCompanyToken())
      .then(response => {
        const rentalCompanyTemp =
          response.data.data.rentalCompany.rentalCompany;
        if (rentalCompanyTemp) {
          getRentalCompanyQuotations(getRentalCompanyToken())
            .then(result => {
              dispatch(
                updateRentalCompany({
                  rentalCompany: rentalCompanyTemp,
                  user: {
                    role: 'RENTAL',
                    token: getRentalCompanyToken(),
                  },
                  rentalCompanyQuotations:
                    result.data.data.rentalCompanyQuotations,
                }),
              );
            })
            .catch(error => {
              console.log('error', error);
            });
        } else {
          dispatch(removeRentalCompany());
        }
      })
      .catch(error => {
        dispatch(removeRentalCompany());
      });
    return (document.querySelector(
      '.grecaptcha-badge',
    ) as HTMLInputElement)?.remove();
  }, [dispatch]);

  return (
    <>
      {rentalCompany.id !== '' ? (
        <>
          <section className="fade-in">
            <Row>
              <Col xs={12} sm={9} md={3} lg={3}>
                <LeftMenu className="justify-content">
                  <LogoImage />
                  <LeftMenu.Subtitle>
                    <h5>{rentalCompany.corporateName}</h5>
                  </LeftMenu.Subtitle>
                  <div className="dot-line" />
                  <LeftMenu.Body>
                    <LeftMenu.Text>
                      <Button
                        as={Link}
                        to="/locadora/configs"
                        className={
                          activate.configs
                            ? 'w-100 btnToggleActive'
                            : 'w-100 btnToggle'
                        }
                      >
                        Configurações
                      </Button>
                    </LeftMenu.Text>
                    <LeftMenu.Text>
                      <Button
                        as={Link}
                        to="/locadora/localidades"
                        className={
                          activate.places
                            ? 'w-100 btnToggleActive'
                            : 'w-100 btnToggle'
                        }
                      >
                        Localidades
                      </Button>
                    </LeftMenu.Text>
                    <LeftMenu.Text>
                      <Button
                        as={Link}
                        to="/locadora/maquinas"
                        className={
                          activate.machines
                            ? 'w-100 btnToggleActive'
                            : 'w-100 btnToggle'
                        }
                      >
                        Máquinas
                      </Button>
                    </LeftMenu.Text>
                    <LeftMenu.Text>
                      <Button
                        as={Link}
                        to="/locadora/pedidos"
                        className={
                          activate.quotations
                            ? 'w-100 btnToggleActive'
                            : 'w-100 btnToggle'
                        }
                      >
                        Orçamentos
                      </Button>
                    </LeftMenu.Text>
                    <LeftMenu.Text>
                      <Button
                        className="w-100 btnToggle"
                        onClick={() => {
                          // dispatch({
                          //   type:
                          //     RentalCompanyTypes.REMOVE_RENTAL_COMPANY,
                          // });
                          dispatch(removeRentalCompany());
                          window.location.href = '/';
                        }}
                      >
                        Sair da conta
                      </Button>
                    </LeftMenu.Text>
                  </LeftMenu.Body>
                </LeftMenu>
              </Col>
              <Col xs={12} sm={9} md={9} lg={9}>
                {switchRender()}
              </Col>
            </Row>
          </section>
          <Footer />
        </>
      ) : (
        <Spinner
          className="spinner-loading"
          as="span"
          animation="border"
          role="status"
          aria-hidden="true"
        />
      )}
    </>
  );
};

export default ConstructionCompanyProfile;
