import { Quotation } from '../../../services/types';
/**
 * Action types
 * @UPDATE_CONSTRUCTION_COMPANY handle constructionCompany infos
 * @REMOVE_CONSTRUCTION_COMPANY remove constructionCompany infos
 */
export enum ConstructionCompanyTypes {
  UPDATE_CONSTRUCTION_COMPANY = '@constructionCompany/HANDLE_CONSTRUCTION_COMPANY',
  REMOVE_CONSTRUCTION_COMPANY = '@constructionCompany/REMOVE_CONSTRUCTION_COMPANY',
}

/**
 * Data types
 * @token : id of merchant
 * @role : url of merchant
 */

export interface User {
  token: string
  role: string
 }

export interface ConstructionCompany {
  id: string
  email: string
  companyName: string
  cnpjOrCpf: string
  image: string
  ownerName: string
  ownerEmail: string
  ownerPhone: string
  ownerWhatsApp: string
}

/**
 * State type
 * @data : the constructionCompany
 */
export interface ConstructionCompanyState {
  user: User
  constructionCompany: ConstructionCompany
  constructionCompanyQuotations: Quotation[]
}
