import React from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from '../../store';
import { toggleModalTerms } from '../../store/ducks/modals/actions';

const TermsAndPoliticModal: React.FC = () => {
  const dispatch = useDispatch();
  const { statusTermsAndPolitic, typeTermsAndPolitic } = useSelector(
    (state: ApplicationState) => state.modals,
  );

  const handleClose = () => {
    dispatch(toggleModalTerms(undefined));
  };

  return (
    <Modal
      show={statusTermsAndPolitic}
      onHide={handleClose}
      centered
      aria-labelledby="contained-quotation-modal"
      className="modal-quotation"
      dialogClassName="modal-quotation"
      id="modal-quotation"
      size="xl"
    >
      <Modal.Header closeButton>
        {typeTermsAndPolitic === 'politic' ? (
          <h4> POLÍTICA DE PRIVACIDADE </h4>
        ) : (
          <h4> TERMOS DE USO </h4>
        )}
      </Modal.Header>
      <Modal.Body>
        {typeTermsAndPolitic === 'politic' ? (
          <p>
            <b>1. INFORMAÇÕES GERAIS</b> <br /> <br /> A presente Política de
            Privacidade contém informações a respeito do modo como tratamos,
            total ou parcialmente, de forma automatizada ou não, os dados
            pessoais dos usuários que acessam nosso site e dos usuários
            cadastrados. Seu objetivo é esclarecer os interessados acerca dos
            tipos de dados que são coletados, dos motivos da coleta e da forma
            como o usuário poderá atualizar, gerenciar ou excluir estas
            informações. Esta Política de Privacidade foi elaborada em
            conformidade com a Lei Federal n. 12.965 de 23 de abril de 2014
            (Marco Civil da Internet), com a Lei Federal n. 13.709, de 14 de
            agosto de 2018 (Lei Geral de Proteção de Dados Pessoais) e com o
            Regulamento UE n. 2016/679 de 27 de abril de 2016 (Regulamento Geral
            Europeu de Proteção de Dados Pessoais - RGDP). Esta Política de
            Privacidade poderá ser atualizada em decorrência de eventual
            atualização normativa, razão pela qual se convida o usuário a
            consultar periodicamente esta seção.
            <br /> <br /> <b>2. DIREITOS DO USUÁRIO</b> <br /> <br /> O site se
            compromete a cumprir as normas previstas na LGPD, em respeito aos
            seguintes princípios: - Os dados pessoais do usuário serão
            processados de forma lícita, leal e transparente (licitude, lealdade
            e transparência); - Os dados pessoais do usuário serão coletados
            apenas para finalidades determinadas, explícitas e legítimas, não
            podendo ser tratados posteriormente de uma forma incompatível com
            essas finalidades (limitação das finalidades); - Os dados pessoais
            do usuário serão coletados de forma adequada, pertinente e limitada
            às necessidades do objetivo para os quais eles são processados
            (minimização dos dados); - Os dados pessoais do usuário serão exatos
            e atualizados sempre que necessário, de maneira que os dados
            inexatos sejam apagados ou retificados quando possível (exatidão); -
            Os dados pessoais do usuário serão conservados de uma forma que
            permita a identificação dos titulares dos dados apenas durante o
            período necessário para as finalidades para as quais são tratados
            (limitação da conservação); - Os dados pessoais do usuário serão
            tratados de forma segura, protegidos do tratamento não autorizado ou
            ilícito e contra a sua perda, destruição ou danificação acidental,
            adotando as medidas técnicas ou organizativas adequadas (integridade
            e confidencialidade). O usuário do site possui os seguintes
            direitos, conferidos pela Lei Geral de Proteção de Dados Pessoais: -
            Direito de confirmação e acesso: é o direito do usuário de obter do
            site a confirmação de que os dados pessoais que lhe digam respeito
            são ou não objeto de tratamento e, se for esse o caso, o direito de
            acessar os seus dados pessoais; - Direito de retificação: é o
            direito do usuário de obter do site, sem demora injustificada, a
            retificação dos dados pessoais inexatos que lhe digam respeito; -
            Direito à eliminação dos dados (direito ao esquecimento): é o
            direito do usuário de ter seus dados apagados do site; - Direito de
            oposição: é o direito do usuário de, a qualquer momento, se opor por
            motivos relacionados com a sua situação particular, ao tratamento
            dos dados pessoais que lhe digam respeito, podendo se opor ainda ao
            uso de seus dados pessoais para definição de perfil de marketing
            (profiling); - Direito de portabilidade dos dados: é o direito do
            usuário de receber os dados pessoais que lhe digam respeito e que
            tenha fornecido ao site, num formato estruturado, de uso corrente e
            de leitura automática, e o direito de transmitir esses dados a outro
            site; O usuário poderá exercer os seus direitos por meio de
            comunicação escrita enviada ao site, via e-mail
            “contato@maquinasparaconstrucao.com", com o assunto "EXERCÍCIO DE
            DIREITO USUÁRIO”, especificando: Nome completo; Número do CPF
            (Cadastro de Pessoas Físicas, da Receita Federal do Brasil);
            Endereço de e-mail do usuário e, se for o caso, do seu
            representante; Direito que deseja exercer junto ao site; Data do
            pedido e assinatura do usuário; Todo documento que possa demonstrar
            ou justificar o exercício de seu direito. O usuário será informado
            em caso de retificação ou eliminação dos seus dados.
            <br /> <br /> <b>
              3. DEVER DE NÃO FORNECER DADOS DE TERCEIROS
            </b>{' '}
            <br /> <br />
            Durante a utilização do site, a fim de resguardar e de proteger os
            direitos de terceiros, o usuário do site deverá fornecer somente
            seus dados pessoais, e não os de terceiros.
            <br /> <br /> <b>4. INFORMAÇÕES COLETADAS</b> <br /> <br /> A coleta
            de dados dos usuários se dará em conformidade com o disposto nesta
            Política de Privacidade e dependerá do consentimento do usuário,
            sendo este dado no momento de acesso a plataforma e no cadastro,
            dispensável somente nas hipóteses previstas no art. 11, inciso II,
            da Lei de Proteção de Dados Pessoais.
            <br /> <br /> <b>4.1. Tipos de dados coletados</b> <br />
            <br /> <br />{' '}
            <b>
              4.1.1. Dados de identificação do usuário para realização de
              cadastro
            </b>{' '}
            <br /> <br />A utilização, pelo usuário, de determinadas
            funcionalidades do site dependerá de cadastro, sendo que, nestes
            casos, os seguintes dados do usuário serão coletados e armazenados:
            - nome ou razão social - endereço de e-mail - número de telefone -
            número de CPF - número de CNPJ - inscrição estadual - dados
            financeiros, a depender da forma de pagamento adotada
            <br /> <br />{' '}
            <b>
              4.1.2. Dados relacionados à execução de contratos firmados com o
              usuário
            </b>{' '}
            <br /> <br />
            Para a execução do contrato de prestação de serviços firmado entre o
            site e o usuário, poderão ser coletados e armazenados outros dados
            relacionados ou necessários a sua execução, incluindo o teor de
            eventuais comunicações tidas com o usuário.
            <br /> <br /> <b>4.1.3. Registros de acesso</b> <br /> <br />
            Em atendimento às disposições do art. 15, caput e parágrafos, da Lei
            Federal n. 12.965/2014 (Marco Civil da Internet), os registros de
            acesso do usuário serão coletados e armazenados por, pelo menos,
            seis meses.
            <br /> <br /> <b>4.1.4. Newsletter</b> <br /> <br /> O endereço de
            e-mail cadastrado pelo usuário que optar por se inscrever em nossa
            Newsletter será coletado e armazenado até que o usuário solicite seu
            descadastro.
            <br /> <br /> <b>4.1.5. Dados sensíveis</b> <br /> <br />
            Não serão coletados dados sensíveis dos usuários, assim entendidos
            aqueles definidos nos arts . 11 e seguintes da Lei de Proteção de
            Dados Pessoais.
            <br /> <br />
            <b>
              4.1.6. Coleta de dados não previstos expressamente
            </b> <br /> <br />
            Eventualmente, outros tipos de dados não previstos expressamente
            nesta Política de Privacidade poderão ser coletados, desde que sejam
            fornecidos com o consentimento do usuário, ou, ainda, que a coleta
            seja permitida ou imposta por lei.
            <br /> <br />
            <b>
              4.2. Fundamento jurídico para o tratamento dos dados pessoais
            </b>{' '}
            <br /> <br />
            Ao utilizar os serviços do site, o usuário está consentindo com a
            presente Política de Privacidade. O usuário tem o direito de retirar
            seu consentimento a qualquer momento, não comprometendo a licitude
            do tratamento de seus dados pessoais antes da retirada. A retirada
            do consentimento poderá ser feita pelo e-mail:
            contato@maquinasparaconstrucao.com. O consentimento dos
            relativamente ou absolutamente incapazes, especialmente de crianças
            menores de 16 (dezesseis) anos, apenas poderá ser feito,
            respectivamente, se devidamente assistidos ou representados. Poderão
            ainda ser coletados dados pessoais necessários para a execução e
            cumprimento dos serviços contratados pelo usuário no site. O
            tratamento de dados pessoais sem o consentimento do usuário apenas
            será realizado em razão de interesse legítimo ou para as hipóteses
            previstas em lei, ou seja, dentre outras, as seguintes: - para o
            cumprimento de obrigação legal ou regulatória pelo controlador; -
            para a realização de estudos por órgão de pesquisa, garantida,
            sempre que possível, a anonimização dos dados pessoais; - quando
            necessário para a execução de contrato ou de procedimentos
            preliminares relacionados a contrato do qual seja parte o usuário, a
            pedido do titular dos dados; - para o exercício regular de direitos
            em processo judicial, administrativo ou arbitral, esse último nos
            termos da Lei nº 9.307, de 23 de setembro de 1996 (Lei de
            Arbitragem); - para a proteção da vida ou da incolumidade física do
            titular dos dados ou de terceiro; - para a tutela da saúde, em
            procedimento realizado por profissionais da área da saúde ou por
            entidades sanitárias; - quando necessário para atender aos
            interesses legítimos do controlador ou de terceiro, exceto no caso
            de prevalecerem direitos e liberdades fundamentais do titular dos
            dados que exijam a proteção dos dados pessoais; - para a proteção do
            crédito, inclusive quanto ao disposto na legislação pertinente.
            <br /> <br />{' '}
            <b>4.3. Finalidades do tratamento dos dados pessoais</b> <br />{' '}
            <br />
            Os dados pessoais do usuário coletados pelo site têm por finalidade
            facilitar, agilizar e cumprir os compromissos estabelecidos com o
            usuário e a fazer cumprir as solicitações realizadas por meio do
            preenchimento de formulários. Os dados pessoais poderão ser
            utilizados também com uma finalidade comercial, para personalizar o
            conteúdo oferecido ao usuário, bem como para dar subsídio ao site
            para a melhora da qualidade e funcionamento de seus serviços. Os
            dados de cadastro serão utilizados para permitir o acesso do usuário
            a determinados conteúdos do site, exclusivos para usuários
            cadastrados. A coleta de dados relacionados ou necessários à
            execução de prestação de serviços eventualmente firmado com o
            usuário terá a finalidade de conferir às partes segurança jurídica,
            além de facilitar e viabilizar a conclusão do negócio. O tratamento
            de dados pessoais para finalidades não previstas nesta Política de
            Privacidade somente ocorrerá mediante comunicação prévia ao usuário,
            sendo que, em qualquer caso, os direitos e obrigações aqui previstos
            permanecerão aplicáveis. 4.4. Prazo de conservação dos dados
            pessoais Os dados pessoais do usuário serão conservados pelo prazo
            máximo de: 02 anos, se o usuário solicitar a sua supressão antes do
            final deste prazo. Os dados pessoais dos usuários apenas poderão ser
            conservados após o término de seu tratamento nas seguintes
            hipóteses: - para o cumprimento de obrigação legal ou regulatória
            pelo controlador; - para estudo por órgão de pesquisa, garantida,
            sempre que possível, a anonimização dos dados pessoais; - para a
            transferência a terceiro, desde que respeitados os requisitos de
            tratamento de dados dispostos na legislação; - para uso exclusivo do
            controlador, vedado seu acesso por terceiro, e desde que, sempre que
            possível, anonimizados os dados.
            <br /> <br />{' '}
            <b>
              4.5. Destinatários e transferência dos dados pessoais
            </b> <br /> <br />
            Os dados pessoais do usuário poderão ser compartilhados com outras
            empresas de forma a garantir a execução do serviço contratado e bom
            funcionamento da plataforma. As empresas terceiras, destinatárias de
            dados pessoais dos usuários, serão notificadas em caso de exercício
            de direito do usuário, de forma a darem cumprimento a solicitação. A
            “Máquinas para Construção” não se responsabiliza pelo descumprimento
            total ou parcial da Lei Geral de Proteção de Dados por terceiros,
            não podendo ser responsabilizada quando, após envio de notificação
            exigindo que o terceiro cumpra o direito do usuário, o mesmo a
            descumpra. A transferência apenas poderá ser feita para outro país
            caso o país ou território em questão ou a organização internacional
            em causa assegurem um nível de proteção adequado dos dados do
            usuário. Caso não haja nível de proteção adequado, o site se
            compromete a garantir a proteção dos seus dados de acordo com as
            regras mais rigorosas, por meio de cláusulas contratuais específicas
            para determinada transferência, cláusulas-padrão contratuais, normas
            corporativas globais ou selos, certificados e códigos de conduta
            regularmente emitidos.
            <br /> <br /> <b>5. DO TRATAMENTO DOS DADOS PESSOAIS</b> <br />{' '}
            <br /> A J R GALVAO TECNOLOGIA E INOVAÇÕES LTDA (“Maquinas para
            Construção”), devidamente inscrita no CNPJ/MF sob o nº
            38132662/0001-98, cujo endereço fiscal é ALAMEDA DAS ACACIAS 1038,
            NEOPOLS , NATAL - RN, é a empresa controladora dos dados pessoais
            coletados dos Usuários no âmbito desta Política de Privacidade. O
            encarregado/a pelo tratamento de dados pessoais da Máquinas para
            Construção (“Encarregado”) pode ser contatado de forma direta
            através do seguinte e-mail: contato@maquinasparaconstrucao.com.
            <br /> <br />{' '}
            <b>6. SEGURANÇA NO TRATAMENTO DOS DADOS PESSOAIS DO USUÁRIO</b>{' '}
            <br /> O site se compromete a aplicar as medidas técnicas e
            organizativas aptas a proteger os dados pessoais de acessos não
            autorizados e de situações de destruição, perda, alteração,
            comunicação ou difusão de tais dados. Para a garantia da segurança,
            serão adotadas soluções que levem em consideração: as técnicas
            adequadas; os custos de aplicação; a natureza, o âmbito, o contexto
            e as finalidades do tratamento; e os riscos para os direitos e
            liberdades do usuário. O site utiliza serviços com certificação de
            segurança, garantindo que os dados pessoais se transmitam de forma
            segura e confidencial, de maneira que a transmissão dos dados entre
            o servidor e o usuário, e em retroalimentação, ocorra de maneira
            totalmente cifrada ou encriptada. No entanto, o site se exime de
            responsabilidade por culpa exclusiva de terceiro, como em caso de
            ataque de hackers ou crackers, ou culpa exclusiva do usuário, como
            no caso em que ele mesmo transfere seus dados a terceiro. O site se
            compromete, ainda, a comunicar o usuário em prazo adequado caso
            ocorra algum tipo de violação da segurança de seus dados pessoais
            que possa lhe causar um alto risco para seus direitos e liberdades
            pessoais. A violação de dados pessoais é uma violação de segurança
            que provoque, de modo acidental ou ilícito, a destruição, a perda, a
            alteração, a divulgação ou o acesso não autorizados a dados pessoais
            transmitidos, conservados ou sujeitos a qualquer outro tipo de
            tratamento.te a tratar os dados pessoais do usuário com
            confidencialidade, dentro dos limi Por fim, o site se comprometes
            legais.
            <br /> <br /> <b>8. RECLAMAÇÃO A UMA AUTORIDADE DE CONTROLE</b>{' '}
            <br /> <br />
            Sem prejuízo de qualquer outra via de recurso administrativo ou
            judicial, todos os titulares de dados têm direito a apresentar
            reclamação a uma autoridade de controle. A reclamação poderá ser
            feita à autoridade da sede do site, do país de residência habitual
            do usuário, do seu local de trabalho ou do local onde foi
            alegadamente praticada a infração.
            <br /> <br /> <b>9. DAS ALTERAÇÕES</b> <br /> <br /> A presente
            versão desta Política de Privacidade foi atualizada pela última vez
            em: ________. O editor se reserva o direito de modificar, a qualquer
            momento o site as presentes normas, especialmente para adaptá-las às
            evoluções do site Maquinas para Construção, seja pela
            disponibilização de novas funcionalidades, seja pela supressão ou
            modificação daquelas já existentes. O usuário será explicitamente
            notificado em caso de alteração desta política. Ao utilizar o
            serviço após eventuais modificações, o usuário demonstra sua
            concordância com as novas normas. Caso discorde de alguma das
            modificações, deverá pedir, imediatamente, o cancelamento de sua
            conta e apresentar a sua ressalva ao serviço de atendimento, se
            assim o desejar.
            <br /> <br /> <b>10. DO DIREITO APLICÁVEL E DO FORO</b> <br />{' '}
            <br />
            Para a solução das controvérsias decorrentes do presente
            instrumento, será aplicado integralmente o Direito brasileiro. Os
            eventuais litígios deverão ser apresentados no foro da comarca em
            que se encontra a sede do editor do site. Fica eleito o foro da
            Comarca de Natal-RN para dirimir as questões oriundas deste Termo de
            Uso.
          </p>
        ) : (
          <p>
            Este Termo de Uso visa descrever e regular a utilização da
            PLATAFORMA disponibilizada pela J R GALVAO TECNOLOGIA E INOVAÇÕES
            LTDA, devidamente inscrita no CNPJ/MF sob o nº 38132662/0001-98,
            cujo endereço fiscal é ALAMEDA DAS ACACIAS 1038, NEOPOLS , NATAL -
            RN, doravante denominada Máquinas para Construção. <br /> Qualquer
            pessoa, física ou jurídica, Assinante ou Usuário, que pretenda
            utilizar os serviços da PLATAFORMA Máquinas para Construção deverá
            aceitar os Termos de Uso e seguir todas as políticas e princípios
            que o regulam. <br /> A aceitação deste Termo de Uso é absolutamente
            indispensável à utilização da PLATAFORMA, bem como outros serviços
            ofertados pela Máquinas para Construção.
            <br /> O Assinante e Usuário devem ler, compreender e aceitar todas
            as condições estabelecidas neste Termo de Uso, na Política de
            Privacidade e demais documentos integrantes aos mesmos antes de seu
            cadastro na PLATAFORMA.
            <br /> <br /> <b>GLOSSÁRIO: </b> <br />
            <li>
              <b>Plataforma: </b>
              Sistema Web da “Máquinas para Construção” acessado via
              https://maquinas-para-construcao.vercel.app/;
            </li>
            <li>
              <b>Usuário: </b>
              Pessoa Física ou Pessoa Jurídica que acessa a plataforma;
            </li>
            <li>
              <b>Assinante: </b>
              Qualquer Usuário cadastrado aderente e adimplente com um Plano de
              Assinatura ou em período de testes;
            </li>
            <li>
              <b>Produtos: </b>
              Qualquer máquina, equipamento, ferramentas e disponibilizados pelo
              Assinante para locação;
            </li>
            <li>
              <b>Plano de Assinatura: </b>
              Valor e Condições de Pagamento definidos pela Plataforma para
              utilização dos seus serviços;
            </li>
            <br /> <b>1. COMO FUNCIONA O SITE</b> <br /> <br />
            A PLATAFORMA oferece espaço online livre para aproximar locador e
            locatário de máquinas, equipamentos e ferramentas voltadas à
            construção civil e ou qualquer outro tipo de construção e manutenção
            . A oferta desse produto se dará via adesão e pagamento de um plano
            de assinatura, sendo algumas funcionalidades extras pagas
            separadamente. <br />
            A PLATAFORMA não presta serviços de consultoria ou intermediação, e
            nem é proprietária dos produtos oferecidos nos anúncios. <br />
            Da mesma forma, a PLATAFORMA não guarda posse e não intervém na
            definição dos preços, sendo qualquer obrigação firmada entre
            Usuários resultado de negociação realizada entre os mesmos,
            inexistindo envolvimento por parte da PLATAFORMA, logo, se algo der
            errado na transação, qualquer indenização deverá ser reclamada com o
            outro usuário com quem você negociou. <br />
            A responsabilidade da PLATAFORMA fica então restrita à
            disponibilização do espaço e das ferramentas dela. A
            responsabilidade pela realização de anúncios e seu conteúdo, sobre
            os produtos oferecidos e sobre a realização e sucesso da transação
            caberá sempre e exclusivamente aos usuários. <br />
            <br /> <b>2. DO OBJETO</b> <br /> <br />
            Os serviços objeto dos presentes Termos de Uso consistem em: <br />
            A. ofertar e hospedar espaços na PLATAFORMA para que os Assinantes
            possam anunciar seus próprios produtos disponíveis para aluguel;{' '}
            <br />
            B. ofertar espaço para que Usuários possam consultar Produtos
            disponíveis para locação relacionados a Construção Civil C.
            viabilizar o contato direto entre Usuários interessados em alugar os
            produtos anunciados, por meio da divulgação dos dados de contato de
            uma parte à outra. <br />
            D. a PLATAFORMA possibilita aos Assinantes contatarem e negociarem
            com os usuários, sem qualquer intervenção do Máquinas para
            Construção na negociação ou na concretização dos negócios. <br />
            O Máquinas para Construção não fornece quaisquer produtos anunciados
            pelos Assinantes no Site. <br />
            <br /> <b>3. DAS CONTAS CADASTRADAS</b> <br /> <br />
            Sua conta é um cadastro único para uso do PLATAFORMA e obrigatória
            para inserir anúncios e solicitar orçamentos. <br />
            Em caso de Pessoa Física, a utilização do site é restrita a pessoas
            maiores de 18 anos de idade completos. <br />
            A. Ao criar uma conta na PLATAFORMA o Usuário declara que todas as
            informações fornecidas são verdadeiras e assume a responsabilidade
            de mantê-las atualizadas. <br />
            B. Sua senha deve ser mantida em sigilo e não deve ser
            compartilhada, pois as atividades realizadas na PLATAFORMA com o uso
            de sua conta serão sempre de sua responsabilidade. <br />
            C. As Informações da Conta, e-mail e contato telefônico, servem para
            recebimento de orçamento referente aos Produtos selecionados na
            PLATAFORMA. <br />
            D. A Suspensão e Exclusão da Conta podem ocorrer sem aviso prévio,
            por parte da PLATAFORMA, em caso de falsidade nas Informações da
            Conta, mau uso, violação da legislação, da moral, da ordem pública,
            deste Termo de Uso, Política de Privacidade e outros Termos
            disponibilizados na PLATAFORMA. <br />
            E. A Suspensão e Exclusão da Conta também pode ocorrer em caso de
            utilização da PLATAFORMA para atividades ilícitas ou imorais, pela
            falta de pagamento, ou qualquer atividade que, a critério da
            PLATAFORMA, não esteja de acordo com suas políticas internas. <br />
            <br /> <b>4. DAS ASSINATURAS</b> <br /> <br />
            Uma assinatura dá direito de uso, via LOGIN e SENHA, às
            funcionalidades de anúncio presentes na plataforma. <br />
            A. As assinaturas são para uso exclusivo dos usuários designados e
            não podem ser compartilhadas por mais de um usuário ou em mais de
            uma sessão ou em mais de um dispositivo de acesso simultaneamente,
            ou seja, cada assinatura associada a 1 usuário permite apenas 1
            acesso. <br />
            B. Assinaturas não podem ser transferidas para outros usuários.{' '}
            <br />
            <br /> <b>5. DO PREÇO E FORMA DE PAGAMENTO</b> <br /> <br />
            A. O pagamento será realizado com o vencimento e valores conforme
            descrito no PLANO DE ASSINATURA escolhido pelo Usuário. <br />
            B. O não pagamento dos serviços em suas datas próprias acarretará,
            após o 7º (SÉTIMO) dia corrido do vencimento, no bloqueio/suspensão
            imediato dos serviços prestados pela PLATAFORMA. <br />
            C. A PLATAFORMA tem um prazo de até 72 horas para a liberação do
            acesso, após a comprovação de pagamento pela instituição bancária.{' '}
            <br />
            <br /> <b>6. DO PRAZO E RESCISÃO</b> <br /> <br />O presente
            contrato poderá ser encerrado ou rescindido por interesse de
            qualquer das partes, a qualquer tempo, conforme descrito no PLANO DE
            ASSINATURAS escolhido pelo Usuário disponibilizado na PLATAFORMA.{' '}
            <br />
            <br /> <b>7. DO REAJUSTE DOS PLANOS DE ASSINATURA</b> <br /> <br />
            A. O valor dos PLANOS DE ASSINATURA poderão ser corrigidos pela
            PLATAFORMA sem aviso prévio. <br />
            B. Os PLANOS DE ASSINATURA ora contratados pelo Assinante serão
            corrigidos anualmente no mês de aniversário do presente contrato.{' '}
            <br />
            <br /> <b>8. DOS ANÚNCIOS</b> <br /> <br />
            A. O anúncio é inserido na PLATAFORMA pelo Assinante através da
            função Anunciar. <br />
            B. O Assinante deverá preencher as informações do seu produto,
            estado de conservação e demais informações solicitadas. <br />
            C. Estas informações devem ser atualizadas sempre que ocorrerem
            situações que mudem as condições da oferta que possam interferir na
            negociação. <br />
            D. É proibido anunciar produtos com conteúdo potencialmente
            ofensivo, obsceno, pornográfico, que promova o terrorismo, qualquer
            espécie de discriminação, ou que atente a quaisquer direitos
            individuais ou coletivos de terceiros. <br />
            E. O Assinante não deve inserir, transmitir, difundir ou colocar à
            disposição de terceiros, qualquer conteúdo que constitua publicidade
            ilícita ou desleal; <br />
            F. A PLATAFORMA somente permitirá ser anunciado produtos permitidos
            por este Termo de Uso e vinculados à finalidade e função social da
            empresa e pela lei vigente. <br />
            G. O Assinante se responsabiliza por qualquer violação de direitos
            de terceiros. <br />
            H. O Assinante deverá obrigatoriamente ter a posse e propriedade dos
            produtos e disponibilidade para prestar os serviços, conforme
            anunciado. <br />
            I. O Assinante deverá informar, de forma clara e completa, as
            condições da entrega e eventuais restrições geográficas. <br />
            J. Em contato com o Usuário o Assinante assume total
            responsabilidade pelas informações prestadas e presentes no
            Orçamento encaminhado. <br />
            K. A PLATAFORMA não se responsabiliza por qualquer informação
            enviada pelo Assinante ao Usuário. <br />
            L. O Assinante reconhece que cabe exclusivamente a si a
            responsabilidade pelo anúncio, pelas informações veiculadas, pelas
            características e condições do produto ou serviço anunciado, pela
            sua conduta com o usuário, pela entrega dos produtos e serviços (ou
            não entrega) e pelos tributos que possam incidir na transação.{' '}
            <br />
            M. O anúncio ficará disponível por um período determinado de acordo
            com o tempo informado no plano de assinatura dias, podendo o anúncio
            ser renovado. <br />
            <br /> <b>9. FUNCIONALIDADE EXTRA</b> <br /> <br />
            Destaques de Anúncios. Você pode contratar de forma avulsa e extra a
            funcionalidade para que seu anúncio fique em posição de destaque na
            PLATAFORMA. <br />
            <br /> <b>10. VEDAÇÕES DE USO</b> <br /> <br />
            A. Você não deve inserir, transmitir, difundir ou disponibilizar a
            terceiros por meio do Site qualquer tipo de material ou informação
            que sejam contrários à legislação vigente, à moral, à ordem pública,
            a este Termo de Uso, às políticas da PLATAFORMA e às Condições
            Particulares aplicáveis. <br />
            B. Não é permitido a utilização dos contatos disponibilizados pelo
            Usuário para (i) "spam", "e-mail de correntes", "marketing
            piramidal" e publicidade fora das áreas concebidas para tal uso;
            (ii) conteúdo falso, ambíguo, inexato, ou que possam induzir a erro
            eventuais receptores de referida informação; (iii) conteúdo que
            implique em violação ao sigilo das comunicações e à privacidade;
            (iv) senhas de acesso às distintas utilidades e/ou conteúdo do Site
            que sejam de titularidade de outros usuários. <br />
            <br /> <b>11. VEDAÇÕES DE USO</b> <br /> <br />A PLATAFORMA não
            garante a disponibilidade, acesso e continuidade do funcionamento do
            Site e Aplicativo ou de suas funcionalidades, atuais ou futuras, não
            sendo responsável por nenhum dano ou prejuízo causado ao Usuário,
            assinante ou não, em caso de indisponibilidade. <br />
            <br /> <b>12. ALTERAÇÕES NAS FERRAMENTAS E FUNCIONALIDADES</b>{' '}
            <br /> <br />
            A PLATAFORMA poderá alterar, suspender e descontinuar sem aviso
            prévio qualquer funcionalidade integrante da mesma. Em caso de
            alteração, suspensão ou descontinuidade de uma funcionalidade
            integrante de Plano de Assinatura, será feita a comunicação com pelo
            menos 5 dias de antecedência. <br />
            <br /> <b>13. DO CONTATO</b> <br /> <br />
            Eventual dúvida ou esclarecimento deve ser solicitado através do
            seguinte e-mail disponibilizado para contato:
            contato@maquinasparaconstrucao.com. <br />
            <br /> <b>14. DO FORO</b> <br /> <br />
            Fica eleito o foro da Comarca de Natal-RN para dirimir as questões
            oriundas deste Termo de Uso. <br />
          </p>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default TermsAndPoliticModal;
