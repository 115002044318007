import React from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Machine } from '../../services/types';
import FontAwesome from '../FontAwesome';
import ConfirmationModal from '../ConfirmationModal';
import {
  toggleModalM,
  toggleModalConfirm,
} from '../../store/ducks/modals/actions';
import { refactorCategoryName } from '../../services/mask';

export interface Props {
  posts: Machine[];
}

const Machines = (props: Props) => {
  const dispatch = useDispatch();

  const { posts } = props;

  return (
    <>
      <tbody>
        {posts.map((machine: Machine | any) => (
          <tr className="fade-in" key={machine.id}>
            <td>{machine.id}</td>
            <td style={{ maxWidth: 300 }}>
              {refactorCategoryName(machine.category)}
            </td>
            <td>{machine.equipamentMachine}</td>
            <td>{machine.model}</td>
            <td>{machine.manufacturer}</td>
            <td>{machine.fabricationYear}</td>
            <td>
              <Button
                className="view"
                title="Editar máquina"
                onClick={() => dispatch(toggleModalM({ ...machine }))}
              >
                <FontAwesome type="fas" name="pen" />
              </Button>
              <Button
                className="remove"
                title="Excluir máquina"
                onClick={() => dispatch(toggleModalConfirm({ ...machine }))}
              >
                <FontAwesome type="fas" name="trash" />
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
      <ConfirmationModal confirmationType="remove-machine" />
    </>
  );
};

export default Machines;
