import React, { useState } from 'react';

import './styles.scss';
import { Card, Col, Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import Pagination from '../Pagination';
import { RCQuotations } from '../Quotations';
import QuotationModal from '../QuotationModal';

const RentalCompanyQuotations: React.FC = () => {
  const rentalCompanyState = useSelector(
    (state: ApplicationState) => state.rentalCompany,
  );

  const { rentalCompany } = rentalCompanyState;

  const { rentalCompanyQuotations } = rentalCompanyState;

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = rentalCompanyQuotations.slice(
    indexOfFirstPost,
    indexOfLastPost,
  );

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  return (
    <Card className="rental-company-quotations">
      <Card.Body>
        <Col className="cart-table">
          <Card.Title>Veja aqui os orçamentos solicitados</Card.Title>
          <Card.Text>
            São <b>{rentalCompanyQuotations.length}</b> até o momento.
          </Card.Text>
          {// eslint-disable-next-line no-nested-ternary
          rentalCompanyQuotations.length === 0 ? (
            <h4>Nenhum orçamento solicitado até o momento</h4>
          ) : rentalCompany.status ? (
            <>
              <Table className="fade-in" responsive size="lg">
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Data de envio</th>
                    <th>Empresa</th>
                    <th>Localidade</th>
                    <th>Quantidade de máquinas</th>
                    {/* <th>Status</th> */}
                    <th>Ação</th>
                  </tr>
                </thead>
                <RCQuotations posts={currentPosts} />
              </Table>
              <Pagination
                postsPerPage={postsPerPage}
                totalPosts={rentalCompanyQuotations.length}
                paginate={paginate}
              />
              <QuotationModal />
            </>
          ) : (
            <h4>
              Você recebeu propostas de orçamento! Assine um plano na aba
              Configurações e ative sua conta para visualizar.
            </h4>
          )}
        </Col>
      </Card.Body>
    </Card>
  );
};

export default RentalCompanyQuotations;
