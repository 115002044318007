/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react';
import './styles.scss';
import { Modal, Card, Col, Form, Row, Button, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import SelectSearch from 'react-select-search';
import { ReCaptcha } from 'react-recaptcha-v3';
import { Link } from 'react-router-dom';
import { ApplicationState } from '../../store';
import {
  MachineInput,
  Options,
  MachineTypes,
  Manufacture,
  Machine,
  MachineModel,
} from '../../services/types';
import {
  getMachinesTypes,
  getRentalCompany,
  getRentalCompanyQuotations,
  getManufacturers,
  getMachineModelsByEquipamentMachineAndManufacturer,
} from '../../services/queries';
import { checkMachine } from '../../services/validation';
import { createMachine, updateMachine } from '../../services/mutations';
import { getRentalCompanyToken } from '../../services/auth';
import { toggleModalM } from '../../store/ducks/modals/actions';
import {
  updateRentalCompany,
  removeRentalCompany,
} from '../../store/ducks/rentalCompany/actions';
import { orderByName } from '../../services/functions';

const MachineModal: React.FC = () => {
  const modalCMState = useSelector((state: ApplicationState) => state.modals);
  const rentalCompanyState = useSelector(
    (state: ApplicationState) => state.rentalCompany,
  );

  const token = getRentalCompanyToken();

  const { statusM } = modalCMState;

  const dispatch = useDispatch();

  const verifyCallback = (recaptchaToken: string) => {
    // console.log('recaptchaToken', recaptchaToken);
  };
  const recaptcha = useRef(null);

  const [machine, handleMachine] = useState<MachineInput | any>({
    rentalCompanyId: '',
    MachineModelId: '',
    fabricationYear: 0,
    minimumLeaseTerm: 0,
    operation: false,
    onRent: false,
    onSale: false,
    onService: false,
    manufacturer: '',
    manufacturerId: '',
    operationalCapacityReferential: '',
    operationalCapacityQuantitative: '',
    operationalCapacityUnit: '',
  });

  const {
    fabricationYear,
    minimumLeaseTerm,
    operation,
    onSale,
    onRent,
    onService,
  } = machine;
  const [machineTypesOptions, handleMachineTypesOptions] = useState<Options[]>(
    [],
  );
  const [manufacturers, handleManufacturers] = useState<Manufacture[]>();
  const [selectMachine, handleSelectMachine] = useState<any>();
  const [machineModelsOptions, handleMachineModelsOptions] = useState<
    Options[]
  >([]);
  const [loading, handleLoading] = useState(false);
  const [machineModels, handleMachineModels] = useState<MachineModel[]>([]);
  const [
    machineManufacturerOptions,
    handleMachineManufacturerOptions,
  ] = useState<Options[]>([]);
  const [isLoadingModels, handleIsLoadingModels] = useState<boolean>(false);

  const handleClose = () => {
    handleMachine({
      rentalCompanyId: '',
      MachineModelId: '',
      fabricationYear: 0,
      minimumLeaseTerm: 0,
      operation: false,
      onSale: false,
      onRent: false,
      onService: false,
    });
    dispatch(
      toggleModalM({
        id: '',
        categoryId: '',
        city: '',
        createdAt: '',
        deliveryRadius: [],
        rentalCompanyId: '',
        state: '',
        machineTypeId: '',
        model: '',
        fabricationYear: 0,
        minimumLeaseTerm: 0,
        description: '',
        comments: '',
        image: '',
        operation: false,
        onSale: false,
        onRent: false,
        onService: false,
        manufacturerId: '',
        manufacturer: '',
        operationalCapacityReferential: '',
        operationalCapacityQuantitative: '',
        operationalCapacityUnit: '',
        equipamentMachineId: '',
        equipamentMachine: '',
      }),
    );
    return false;
  };

  const updateStore = () => {
    getRentalCompany(token)
      .then(resp => {
        const rentalCompanyTemp = resp.data.data.rentalCompany.rentalCompany;
        if (rentalCompanyTemp) {
          getRentalCompanyQuotations(token)
            .then(result => {
              dispatch(
                updateRentalCompany({
                  rentalCompany: rentalCompanyTemp,
                  user: {
                    role: 'RENTAL',
                    token,
                  },
                  rentalCompanyQuotations:
                    result.data.data.rentalCompanyQuotations,
                }),
              );
            })
            .catch(error => {
              console.log('error', error);
            });
        } else {
          dispatch(removeRentalCompany());
        }
      })
      .catch(error => {
        console.log('error', error);
        dispatch(removeRentalCompany());
      });
    handleClose();
  };

  const createMachineItem = (event: React.FormEvent) => {
    event.preventDefault();

    if (
      machine.modelId !== '' &&
      machine.fabricationYear !== 0 &&
      machine.minimumLeaseTerm !== 0
    ) {
      const data = {
        id: machine.id,
        rentalCompanyId: rentalCompanyState.rentalCompany.id,
        MachineModelId: machine.modelId,
        fabricationYear: machine.fabricationYear,
        minimumLeaseTerm: machine.minimumLeaseTerm,
        operation: machine.operation,
        onSale: machine.onSale,
        onRent: machine.onRent,
        onService: machine.onService,
      };

      if (modalCMState.machine.id === '') {
        createMachine(data, rentalCompanyState.user.token)
          .then(response => {
            handleLoading(false);
            if (checkMachine('create', response.data.data.createMachine)) {
              updateStore();
            }
            handleClose();
          })
          .catch(error => {
            console.log('error 3', error);
          });
      } else {
        updateMachine(data, rentalCompanyState.user.token)
          .then(response => {
            handleLoading(false);
            if (checkMachine('update', response.data.data.updateMachine)) {
              updateStore();
            }
          })
          .catch(error => {
            console.log('error 4', error);
          });
      }
    }
  };

  const getManufacturerOptions = () => {
    const manufacturersOptions: Options[] = [];
    manufacturers?.map((manufacturerItem: any) =>
      manufacturersOptions.push({
        name: manufacturerItem.name,
        value: manufacturerItem.id,
      }),
    );
    handleMachineManufacturerOptions(manufacturersOptions);
  };

  const getManufacturerName = (id: string) => {
    const nameManufacturer = manufacturers?.filter(
      (manufacturerItem: { id: string; name: string }) =>
        manufacturerItem.id === id,
    )[0];
    if (nameManufacturer) {
      // handleManufacturer(nameManufacturer);
      return nameManufacturer.name;
    }
    return true;
  };

  const getModelsOptions = (id: string) => {
    const modelsOptions: Options[] = [];

    getMachineModelsByEquipamentMachineAndManufacturer(
      machine.equipamentMachineId,
      id,
    )
      .then(response => {
        const result = orderByName(
          response.data.data.machineModelsByEquipamentMachineAndManufacturer,
        );
        handleMachineModels(result);
        result.map((filteredMachineModel: Machine) =>
          modelsOptions.push({
            name: filteredMachineModel.model,
            value: filteredMachineModel.id,
          }),
        );
        handleMachineModelsOptions(modelsOptions);
      })
      .catch(error => {
        console.log('error', error);
      });
    handleIsLoadingModels(false);
  };

  const selectModelMachine = (idModel: string) => {
    const filteredModelMachine = machineModels?.filter(
      (machineModel: any) => machineModel.id === idModel,
    )[0];

    handleSelectMachine({
      ...selectMachine,
      operationalCapacityReferential:
        filteredModelMachine.operationalCapacityReferential,
      operationalCapacityQuantitative:
        filteredModelMachine.operationalCapacityQuantitative,
      operationalCapacityUnit: filteredModelMachine.operationalCapacityUnit,
      manufacturerId: filteredModelMachine.manufacturerId,
    });

    handleMachine({
      ...machine,
      operationalCapacityReferential:
        filteredModelMachine.operationalCapacityReferential,
      operationalCapacityQuantitative:
        filteredModelMachine.operationalCapacityQuantitative,
      operationalCapacityUnit: filteredModelMachine.operationalCapacityUnit,
      manufacturerId: filteredModelMachine.manufacturerId,
      modelId: filteredModelMachine.id,
      category: filteredModelMachine.categoryName,
    });
  };

  useEffect(() => {
    if (modalCMState.machine.id !== '') {
      handleIsLoadingModels(true);
    } else {
      handleIsLoadingModels(false);
    }

    if (!machineTypesOptions.length) {
      getMachinesTypes()
        .then(async response => {
          console.log('response1', response);
          orderByName(response.data.data.equipamentMachines).map(
            (machineTypeFilterItem: MachineTypes) =>
              machineTypesOptions.push({
                name: machineTypeFilterItem.name,
                value: machineTypeFilterItem.id,
              }),
          );
        })
        .catch(error => {
          console.log('error', error);
          handleMachineTypesOptions([]);
        });
    }

    if (modalCMState.machine.id !== '') {
      const modelsOptions: Options[] = [];
      getMachineModelsByEquipamentMachineAndManufacturer(
        modalCMState.machine.equipamentMachineId,
        modalCMState.machine.manufacturerId,
      )
        .then(async response => {
          const result = orderByName(
            response.data.data.machineModelsByEquipamentMachineAndManufacturer,
          );
          handleMachineModels(result);
          result.map((filteredMachineModel: Machine) =>
            modelsOptions.push({
              name: filteredMachineModel.model,
              value: filteredMachineModel.id,
            }),
          );
          console.log('modelsOptions', modelsOptions);
          handleMachineModelsOptions(modelsOptions);
        })
        .catch(error => {
          console.log('error', error);
        });
      handleIsLoadingModels(false);
    }
    if (!manufacturers) {
      getManufacturers()
        .then(async response => {
          console.log('response4', response);
          handleManufacturers(orderByName(response.data.data.manufacturers));
        })
        .catch(error => console.log('error', error));
    }
    if (modalCMState.machine.id !== '') {
      handleMachine({ ...modalCMState.machine });
      getManufacturerOptions();
      // getModelsOptions(modalCMState.machine.manufacturerId);
    }
  }, [modalCMState.machine.id]);

  return (
    <Modal
      show={statusM}
      onHide={handleClose}
      aria-labelledby="contained-quotation-modal"
      className="modal-quotation"
      dialogClassName="modal-quotation"
      id="modal-quotation"
      size="lg"
      centered
    >
      <Card>
        <Col xs={12} className="modal-machine">
          <Modal.Header closeButton>
            {modalCMState.machine.id === '' ? (
              <h4>Cadastrar máquina</h4>
            ) : (
              <h4>Editar máquina {modalCMState.machine.id}</h4>
            )}
          </Modal.Header>
          <Modal.Body
            className="mt-4 content rental-company-configs"
            style={{ padding: '0px' }}
          >
            {machineTypesOptions.length > 0 ? (
              <Form
                onSubmit={(event: React.FormEvent) => createMachineItem(event)}
              >
                <Row className="row-content-form">
                  <Col xs={12} sm={6}>
                    <Form.Group>
                      <Form.Label>Equipamento/Máquina</Form.Label>
                      <SelectSearch
                        options={machineTypesOptions}
                        value={machine.equipamentMachineId}
                        className="select-search"
                        placeholder="Digite ou selecione um equipamento/Máquina..."
                        search
                        onChange={event => {
                          handleMachine({
                            ...machine,
                            equipamentMachineId: String(event),
                            modelId: '',
                            manufacturer: '',
                            manufacturerId: '',
                          });
                          getManufacturerOptions();
                        }}
                      />
                    </Form.Group>
                    {machine.category && (
                      <Form.Group>
                        <Form.Label>Categoria</Form.Label>
                        <Form.Text>{machine.category}</Form.Text>
                      </Form.Group>
                    )}
                    <Form.Group>
                      <Form.Label>Fabricante</Form.Label>
                      <SelectSearch
                        placeholder="Selecione um fabricante"
                        className="select-search category"
                        options={machineManufacturerOptions}
                        value={machine.manufacturerId}
                        disabled={!machine.equipamentMachineId}
                        search
                        onChange={event => {
                          handleIsLoadingModels(true);
                          getModelsOptions(String(event));
                          handleMachine({
                            ...machine,
                            manufacturerId: String(event),
                            manufacturer: getManufacturerName(String(event)),
                          });
                        }}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Modelo</Form.Label>
                      {isLoadingModels ? (
                        <Form.Text>Carregando ...</Form.Text>
                      ) : (
                        <SelectSearch
                          placeholder="Selecione um modelo"
                          className="select-search category"
                          options={machineModelsOptions}
                          value={machine.modelId}
                          disabled={
                            !machine.manufacturer ||
                            !machineModelsOptions.length
                          }
                          onChange={event => {
                            selectModelMachine(String(event));
                          }}
                        />
                      )}
                    </Form.Group>
                  </Col>
                  <Col xs={12} sm={6}>
                    <Row>
                      {machine.operationalCapacityReferential &&
                        machine.modelId && (
                          <Col xs={12}>
                            <Form.Group>
                              <Form.Label>
                                Referencial da Capacidade Operacional
                              </Form.Label>
                              <Form.Text>
                                {machine.operationalCapacityReferential}
                              </Form.Text>
                            </Form.Group>
                          </Col>
                        )}
                    </Row>
                    <Row>
                      {machine.operationalCapacityQuantitative &&
                        machine.modelId && (
                          <Col xs={12} sm={6}>
                            <Form.Label>Quant. da Cap. Operacional</Form.Label>
                            <Form.Text>
                              {machine.operationalCapacityQuantitative}
                            </Form.Text>
                          </Col>
                        )}
                      {machine.operationalCapacityUnit && machine.modelId && (
                        <Col xs={12} sm={6}>
                          <Form.Label>Unidade da Cap. Operacional</Form.Label>
                          <Form.Text>
                            {machine.operationalCapacityUnit}
                          </Form.Text>
                        </Col>
                      )}
                    </Row>
                    <Row>
                      <Col sm={6} xs={12}>
                        <Form.Group>
                          <Form.Label>
                            Prazo mínimo de locação (meses)
                          </Form.Label>
                          <Form.Control
                            type="number"
                            min={1}
                            name="minimumLeaseTerm"
                            className="form-control"
                            value={
                              minimumLeaseTerm === 0 ? '' : minimumLeaseTerm
                            }
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                              handleMachine({
                                ...machine,
                                minimumLeaseTerm: Number(event.target.value),
                              });
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={6} xs={12}>
                        <Form.Group>
                          <Form.Label>Ano de fabricação</Form.Label>
                          <Form.Control
                            type="number"
                            name="fabricationYear"
                            className="form-control"
                            value={fabricationYear === 0 ? '' : fabricationYear}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                              handleMachine({
                                ...machine,
                                fabricationYear: Number(event.target.value),
                              });
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl={6}>
                        <Form.Group>
                          <Form.Label>Possui operador?</Form.Label>
                          <Row className="operator">
                            <input
                              type="radio"
                              id="radioItemOperator1"
                              name="radioItemOperator1"
                              value={operation === true ? 1 : 0}
                              checked={operation === true}
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>,
                              ) => {
                                handleMachine({
                                  ...machine,
                                  operation: true,
                                });
                              }}
                            />
                            <label htmlFor="radioItemOperator1">Sim</label>
                            <input
                              type="radio"
                              id="radioItemOperator2"
                              name="radioItemOperator2"
                              value={operation === false ? 1 : 0}
                              checked={operation === false}
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>,
                              ) => {
                                handleMachine({
                                  ...machine,
                                  operation: false,
                                });
                              }}
                            />
                            <label htmlFor="radioItemOperator2">Não</label>
                          </Row>
                        </Form.Group>
                      </Col>
                      <Col xl={6}>
                        <Form.Group>
                          <Form.Label>À venda?</Form.Label>
                          <Row className="operator">
                            <input
                              type="radio"
                              id="radioItemSale3"
                              name="radioItemSale3"
                              value={onSale === true ? 1 : 0}
                              checked={onSale === true}
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>,
                              ) => {
                                handleMachine({
                                  ...machine,
                                  onSale: true,
                                });
                              }}
                            />
                            <label htmlFor="radioItemSale3">Sim</label>
                            <input
                              type="radio"
                              id="radioItemSale4"
                              name="radioItemSale4"
                              value={onSale === false ? 1 : 0}
                              checked={onSale === false}
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>,
                              ) => {
                                handleMachine({
                                  ...machine,
                                  onSale: false,
                                });
                              }}
                            />
                            <label htmlFor="radioItemSale4">Não</label>
                          </Row>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl={6}>
                        <Form.Group>
                          <Form.Label>Prestação de serviço?</Form.Label>
                          <Row className="operator">
                            <input
                              type="radio"
                              id="radioItemSale5"
                              name="radioItemSale5"
                              value={onService === true ? 1 : 0}
                              checked={onService === true}
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>,
                              ) => {
                                handleMachine({
                                  ...machine,
                                  onService: true,
                                });
                              }}
                            />
                            <label htmlFor="radioItemSale5">Sim</label>
                            <input
                              type="radio"
                              id="radioItemSale6"
                              name="radioItemSale6"
                              value={onService === false ? 1 : 0}
                              checked={onService === false}
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>,
                              ) => {
                                handleMachine({
                                  ...machine,
                                  onService: false,
                                });
                              }}
                            />
                            <label htmlFor="radioItemSale6">Não</label>
                          </Row>
                        </Form.Group>
                      </Col>
                      <Col xl={6}>
                        <Form.Group>
                          <Form.Label>Locação?</Form.Label>
                          <Row className="operator">
                            <input
                              type="radio"
                              id="radioItemSale7"
                              name="radioItemSale7"
                              value={onRent === true ? 1 : 0}
                              checked={onRent === true}
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>,
                              ) => {
                                handleMachine({
                                  ...machine,
                                  onRent: true,
                                });
                              }}
                            />
                            <label htmlFor="radioItemSale7">Sim</label>
                            <input
                              type="radio"
                              id="radioItemSale8"
                              name="radioItemSale8"
                              value={onRent === false ? 1 : 0}
                              checked={onRent === false}
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>,
                              ) => {
                                handleMachine({
                                  ...machine,
                                  onRent: false,
                                });
                              }}
                            />
                            <label htmlFor="radioItemSale8">Não</label>
                          </Row>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {!machineModels.length &&
                  (machine.manufacturer || !machineModelsOptions) && (
                    <Row>
                      <Col>
                        <Form.Text>
                          Não encontrou o modelo que deseja? Entre em contato
                          <Link to="/contato">{' aqui '}</Link>e solicite o
                          cadastro
                        </Form.Text>
                      </Col>
                    </Row>
                  )}
                <ReCaptcha
                  sitekey={process.env.REACT_APP_RECAPTCHA_KEY || ''}
                  action="submit"
                  ref={recaptcha}
                  verifyCallback={verifyCallback}
                />
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  className="justify-content"
                >
                  <Button
                    className="mt-3"
                    type="submit"
                    style={{ marginLeft: '0px' }}
                  >
                    {!loading ? (
                      <>
                        {modalCMState.machine.id === ''
                          ? 'Cadastrar Máquina'
                          : 'Atualizar Máquina'}
                      </>
                    ) : (
                      <Spinner
                        as="span"
                        animation="border"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                  </Button>
                </Col>
              </Form>
            ) : (
              <Spinner
                as="span"
                animation="border"
                role="status"
                aria-hidden="true"
              />
            )}
          </Modal.Body>
        </Col>
      </Card>
    </Modal>
  );
};

export default MachineModal;
