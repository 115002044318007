import { Reducer } from 'redux';
import SimpleCrypto from 'simple-crypto-js';
import { ConstructionCompanyState, ConstructionCompanyTypes } from './types';

const INITIAL_STATE: ConstructionCompanyState = {
  constructionCompany: {
    id: '',
    email: '',
    companyName: '',
    cnpjOrCpf: '',
    image: '',
    ownerName: '',
    ownerEmail: '',
    ownerPhone: '',
    ownerWhatsApp: '',
  },
  user: {
    role: '',
    token: '',
  },
  constructionCompanyQuotations: [],
};

const reducer: Reducer<ConstructionCompanyState> = (
  state = INITIAL_STATE,
  action,
) => {
  const simpleCripto = new SimpleCrypto(process.env.REACT_APP_CRIPTO_KEY || '');
  const updatedConstructionCompanyState = state;

  switch (action.type) {
    case ConstructionCompanyTypes.UPDATE_CONSTRUCTION_COMPANY:
      updatedConstructionCompanyState.user = action.payload.user;
      updatedConstructionCompanyState.constructionCompany =
        action.payload.constructionCompany;
      // eslint-disable-next-line max-len
      updatedConstructionCompanyState.constructionCompanyQuotations =
        action.payload.constructionCompanyQuotations;
      localStorage.setItem(
        process.env.REACT_APP_LOCAL_STORAGE_CONSTRUCTION_COMPANY_AUTH || '',
        JSON.stringify(simpleCripto.encrypt(action.payload.user.token)),
      );
      return { ...state, ...updatedConstructionCompanyState };

    case ConstructionCompanyTypes.REMOVE_CONSTRUCTION_COMPANY:
      localStorage.removeItem(
        process.env.REACT_APP_LOCAL_STORAGE_CONSTRUCTION_COMPANY_AUTH || '',
      );
      return { ...state, ...INITIAL_STATE };

    default:
      return state;
  }
};

export default reducer;
