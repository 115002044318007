import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { toast } from 'react-toastify';
import { Spinner, Row } from 'react-bootstrap';
import { PlansType } from '../../services/types';

interface Props {
  product: PlansType;
  response?: any;
}

const PayPalButton = (window as any).paypal.Buttons.driver('react', {
  React,
  ReactDOM,
});

const PayPal: React.FC<Props> = (props: Props) => {
  const { product, response } = props;
  const { value, name } = product;
  const [loading, handleLoading] = useState(false);

  const createOrder = (data: any, actions: any) =>
    actions.order.create({
      purchase_units: [
        {
          description: name,
          amount: {
            value,
          },
        },
      ],
    });

  const onApprove = async (data: any, actions: any) => {
    handleLoading(true);
    const order = await actions.order.capture();
    handleLoading(false);
    response(order);
    toast.success('Pagamento Realizado com sucesso!');
  };

  const onError = (err: string) => {
    console.log('pagamento error', err);
    toast.error('Houve um erro ao efetuar o pagamento, tente novamente.');
  };

  return (
    <Row>
      <PayPalButton
        createOrder={(data: any, actions: any) => createOrder(data, actions)}
        onApprove={(data: any, actions: any) => onApprove(data, actions)}
        onError={onError}
      />
      {loading && (
        <Row style={{ padding: '0 35px' }}>
          <Spinner
            as="span"
            animation="border"
            role="status"
            aria-hidden="true"
          />
        </Row>
      )}
    </Row>
  );
};

export default PayPal;
