import React from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Quotation } from '../../services/types';
import FontAwesome from '../FontAwesome';
import { formatDate } from '../../services/mask';
import { toggleModalQ } from '../../store/ducks/modals/actions';

export interface Props {
  posts: Quotation[];
}

export const Quotations = (props: Props) => {
  const dispatch = useDispatch();
  const { posts } = props;
  return (
    <>
      <tbody>
        {posts.map((quotation: Quotation, index: number) => (
          <tr className="fade-in" key={quotation.id}>
            <td>{quotation.id}</td>
            <td>{formatDate(quotation.createdAt)}</td>
            <td>{quotation && quotation.machines.length}</td>
            <td>
              {quotation.constructionState},{' '}
              {quotation.constructionPlace === 'CAPITAL'
                ? 'Capital e região metropolitana'
                : 'Interior'}
            </td>
            {/* <td>{quotation.status ? 'Ativo' : 'Inativo'}</td> */}
            <td>{quotation.pdfs.length}</td>
            <td>
              <Button
                className="view"
                title="Visualizar orçamentos"
                onClick={() => dispatch(toggleModalQ({ ...quotation }))}
              >
                <FontAwesome type="fas" name="eye" />
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </>
  );
};

interface PropsRC {
  posts: Quotation[];
}

export const RCQuotations = (props: PropsRC) => {
  const dispatch = useDispatch();
  const { posts } = props;
  return (
    <>
      <tbody>
        {posts.map((quotation: Quotation, index: number) => (
          <tr className="fade-in" key={quotation.id}>
            <td>{quotation.id}</td>
            <td>{formatDate(quotation.createdAt)}</td>
            <td>{quotation.companyName}</td>
            <td>
              {quotation.constructionState},{' '}
              {quotation.constructionPlace === 'CAPITAL'
                ? 'Capital e região metropolitana'
                : 'Interior'}
            </td>
            {/* <td>{quotation.status ? 'Ativo' : 'Inativo'}</td> */}
            <td>{quotation.machines.length}</td>
            <td>
              <Button
                className="view"
                title="Visualizar proposta"
                onClick={() => dispatch(toggleModalQ({ ...quotation }))}
              >
                <FontAwesome type="fas" name="eye" />
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </>
  );
};
export default Quotations;
