import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import './styles.scss';
import { Card, Form, Button, Container, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Login as LoginType } from '../../services/types';
import { auth } from '../../services/mutations';
import { checkAuth, checkError } from '../../services/validation';
import {
  updateConstructionCompany,
  removeConstructionCompany,
} from '../../store/ducks/constructionCompany/actions';
import {
  getConstructionCompany,
  getRentalCompany,
} from '../../services/queries';
import {
  updateRentalCompany,
  removeRentalCompany,
} from '../../store/ducks/rentalCompany/actions';

const Login: React.FC = () => {
  const dispatch = useDispatch();
  const [loading, handleLoading] = useState(false);

  const getAuth = (event: React.FormEvent) => {
    event.preventDefault();
    if (checkAuth('email', email) && checkAuth('password', password)) {
      handleLoading(true);
      auth(email, password).then(response => {
        const { login }: { login: LoginType } = response.data.data;

        if (checkError(response.data.errors)) {
          const user = {
            token: login.token,
            role: login.role,
          };

          if (login.role !== 'RENTAL') {
            getConstructionCompany(login.token).then(async result => {
              console.log(result);
              if (result.data.data.constructionCompany) {
                const {
                  constructionCompany,
                } = result.data.data.constructionCompany;
                if (checkError(response.data.errors)) {
                  dispatch(
                    updateConstructionCompany({
                      user,
                      constructionCompany,
                      constructionCompanyQuotations: [],
                    }),
                  );
                  window.location.href = '/perfil/configs';
                } else {
                  dispatch(removeConstructionCompany());
                  dispatch(removeRentalCompany());
                  handleLoading(false);
                }
              } else {
                handleLoading(false);
                checkError(result.data.errors);
              }
            });
          } else {
            getRentalCompany(login.token).then(async result => {
              const { rentalCompany } = result.data.data.rentalCompany;
              if (checkError(response.data.errors)) {
                dispatch(
                  updateRentalCompany({
                    user,
                    rentalCompany,
                    rentalCompanyQuotations: [],
                  }),
                );
                window.location.href = '/locadora/configs';
              } else {
                dispatch(removeRentalCompany());
                handleLoading(false);
              }
            });
          }
        } else {
          dispatch(removeConstructionCompany());
          handleLoading(false);
        }
      });
    }
  };

  const [email, handleEmail] = useState('');
  const [password, handlePassword] = useState('');
  return (
    <Container className="login" fluid>
      <h2>Login</h2>
      <Card>
        <Card.Body>
          <Form onSubmit={(event: React.FormEvent) => getAuth(event)}>
            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                value={email}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleEmail(event.target.value);
                }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Senha</Form.Label>
              <Form.Control
                type="password"
                value={password}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handlePassword(event.target.value);
                }}
              />
            </Form.Group>
            <Form.Group>
              <Link to="/recuperar-senha">Esqueci a senha</Link>
            </Form.Group>
            <Button type="submit">
              {!loading ? (
                'LOGIN'
              ) : (
                <Spinner
                  as="span"
                  animation="border"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </Button>
          </Form>
        </Card.Body>
      </Card>
      {/* <Link to={`/acesso/locadora`}>
        Sou uma locadora
      </Link> */}
    </Container>
  );
};

export const RentalCompanyLogin: React.FC = () => {
  const dispatch = useDispatch();
  const [loading, handleLoading] = useState(false);

  const getAuth = (event: React.FormEvent) => {
    event.preventDefault();
    if (checkAuth('email', email) && checkAuth('password', password)) {
      handleLoading(true);
      auth(email, password).then(response => {
        const { login }: { login: LoginType } = response.data.data;
        if (checkError(response.data.errors)) {
          const user = {
            token: login.token,
            role: login.role,
          };

          getRentalCompany(login.token).then(async result => {
            const { rentalCompany } = result.data.data.rentalCompany;
            if (checkError(response.data.errors)) {
              dispatch(
                updateRentalCompany({
                  user,
                  rentalCompany,
                  rentalCompanyQuotations: [],
                }),
              );
              window.location.href = '/locadora/configs';
            } else {
              dispatch(removeRentalCompany());
              handleLoading(false);
            }
          });
        } else {
          dispatch(removeRentalCompany());
          handleLoading(false);
        }
      });
    }
  };

  const [email, handleEmail] = useState('');
  const [password, handlePassword] = useState('');
  return (
    <Container className="login" fluid>
      <h2>Login</h2>
      <Card>
        <Card.Body>
          <Form onSubmit={(event: React.FormEvent) => getAuth(event)}>
            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="text"
                value={email}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleEmail(event.target.value);
                }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Senha</Form.Label>
              <Form.Control
                type="password"
                value={password}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handlePassword(event.target.value);
                }}
              />
            </Form.Group>
            <Button type="submit">
              {!loading ? (
                'LOGIN'
              ) : (
                <Spinner
                  as="span"
                  animation="border"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default Login;
