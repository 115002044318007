import React from 'react';

import Header from '../../components/Header';

import MachinesSearchPanel from '../../components/MachinesSearchPanel';
import PagePath from '../../components/PagePath';

const MachinesSearch: React.FC = () => (
  <>
    <Header />
    <PagePath />
    <section className="fade-in">
      <MachinesSearchPanel />
    </section>
  </>
);

export default MachinesSearch;
