import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import iconMPC from '../../../images/logo/logo.png';

const SuccessPage: React.FC = () => {
  const history = useHistory();

  return (
    <>
      <Row className="row-description phase03">
        <Col>
          <img
            src={iconMPC}
            alt="icon-mpc"
            style={{ width: 100, height: 100, marginBottom: 20 }}
          />
          <h5>
            Cadastro realizado com sucesso! <br />
            Não perca tempo e registre agora mesmo as suas máquinas
          </h5>
          <Button
            onClick={() => history.push('/login')}
            type="submit"
            style={{ marginTop: 30 }}
          >
            Acessar conta
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default SuccessPage;
