import {
  Category,
  Machine,
  MachineTypes,
  DeliveryState,
  MachineQuotationInput,
} from './types';
import { getCategories, getMachinesTypes } from './queries';
import { removeSpecialChars } from './mask';

export const groupItems = (arr: any, groupBy: string) =>
  arr.reduce((result: any, item: any) => {
    // eslint-disable-next-line no-param-reassign
    (result[item[groupBy]] = result[item[groupBy]] || []).push(item);
    return result;
  }, {});

// eslint-disable-next-line max-len
export const groupMinimumLeaseTerm = (arr: Machine[]) =>
  arr.reduce((index: number[], current: Machine) => {
    if (
      index.length === 0 ||
      index.filter((i: number) => i === current.minimumLeaseTerm).length === 0
    ) {
      index.push(current.minimumLeaseTerm);
    }
    return index;
  }, []);

export const groupState = (arr: Machine[]) =>
  arr.reduce((index: string[], current: Machine) => {
    current.deliveryRadius.map((deliveryState: DeliveryState) => {
      if (
        index.length === 0 ||
        index.filter((i: string) => i === deliveryState.state).length === 0
      ) {
        index.push(deliveryState.state);
      }
      return true;
    });
    return index;
  }, []);

// eslint-disable-next-line max-len
export const groupMachineTypes = (arr: Machine[]) =>
  arr.reduce((index: number[], current: Machine) => {
    if (
      index.length === 0 ||
      index.filter((i: number) => i === Number(current.machineTypeId))
        .length === 0
    ) {
      const convert = Number(current.machineTypeId);
      index.push(convert);
    }
    return index;
  }, []);

export const getProductsFiltered = (arr: Machine[], char: string) => {
  const temp: Machine[] = [];
  arr.map(machine => {
    const stringA = machine.model.toUpperCase();
    // const stringB = prod.name.toUpperCase();
    const comparator = char.toUpperCase();

    return stringA.includes(comparator) // || stringB.includes(comparator)
      ? temp.push(machine)
      : '';
  });
  // );
  return temp;
};

export const getCategoryInfos = (categoryName: string) => {
  const result: Category[] = [];
  getCategories().then(response => {
    response.data.data.categories.filter((category: Category) => {
      if (category.name === categoryName) result.push(category);
      return true;
    });
  });
  return result;
};

export const getMachineCategoryById = async (categoryId: string) => {
  const result: Category[] = [];
  await getCategories().then(response => {
    response.data.data.categories.filter((category: Category) => {
      if (category.id === categoryId) result.push(category);
      return true;
    });
  });
  return result[0];
};

export const getMachineTypeById = async (machineTypeId: string) => {
  const result: MachineTypes[] = [];
  await getMachinesTypes().then(response => {
    response.data.data.machineTypes.filter((machineType: MachineTypes) => {
      if (machineType.id === machineTypeId) result.push(machineType);
      return true;
    });
  });
  return result[0];
};

export const getMachineTypeByName = async (machineTypeName: string) => {
  const result: MachineTypes[] = [];
  await getMachinesTypes().then(response => {
    response.data.data.equipamentMachines.filter(
      (machineType: MachineTypes) => {
        if (removeSpecialChars(machineType.name) === machineTypeName) {
          result.push(machineType);
        }
        return true;
      },
    );
  });
  return result[0];
};

export const filterItems = (char: string, machines: Machine[]) => {
  if (char === '') {
    return machines;
  }
  const temp = getProductsFiltered(machines, char);
  return temp.length > 0 ? temp : machines;
};

export const createMachineArray = (machines: MachineQuotationInput[]) => {
  let newString = '[';
  machines.map((machine: MachineQuotationInput | any, index: number) => {
    newString += `{
      modelId: "${machine.modelId}"
      fabricationYear: ${machine.fabricationYear}
      quantity: ${machine.quantity}
      leaseTerm: ${machine.leaseTerm}
      operation: ${machine.operation}
      sale: ${machine.sale}
      rent: ${machine.rent}
      service: ${machine.service}
    }`;
    if (index === machines.length - 1) newString += ']';
    else newString += ',';
    return newString;
  });
  return newString;
};

export const orderByName = (object: any) => {
  object.sort((a: any, b: any) => {
    if (a.name > b.name) {
      return 1;
    }
    if (a.name < b.name) {
      return -1;
    }
    // a must be equal to b
    return 0;
  });
  return object;
};

export const orderByModel = (object: any) => {
  object.sort((a: any, b: any) => {
    if (a.model > b.model) {
      return 1;
    }
    if (a.model < b.model) {
      return -1;
    }
    // a must be equal to b
    return 0;
  });
  return object;
};
