import axios from 'axios';
import { ConstructionCompany } from '../store/ducks/constructionCompany/types';
import {
  ConstructionCompanyInput,
  QuotationInput,
  RentalCompanyInput,
  MachineInput,
  DeliveryState,
  DeliveryStateInput,
  PaymentInput,
} from './types';
import { createMachineArray } from './functions';

const headers = {
  'Content-type': 'application/json',
};

export const createConstructionCompany = async (
  constructionCompany: ConstructionCompany | any,
  password: string,
) => {
  const {
    companyName,
    cnpjOrCpf,
    email,
    acceptanceTermsOfUse,
  } = constructionCompany;

  const data = {
    query: `
    mutation CreateConstructionAccount{
      createConstructionCompany(
        email: "${email}",
        acceptanceTermsOfUse: ${acceptanceTermsOfUse}
        password: "${password}",
        name: "${companyName}",
        cnpjOrCpf: "${cnpjOrCpf}",
        )
    }
          `,
  };
  const CreateConstructionAccount = await axios({
    url: process.env.REACT_APP_API_URL,
    method: 'POST',
    headers,
    data,
  });
  return CreateConstructionAccount;
};

export const updateConstructionCompany = async (
  constructionCompany: ConstructionCompanyInput,
  token: string,
) => {
  const {
    id,
    email,
    password,
    companyName,
    cnpjOrCpf,
    ownerName,
    ownerEmail,
    ownerPhone,
    ownerWhatsApp,
    acceptanceTermsOfUse,
    phone,
  } = constructionCompany;
  const data = {
    query: '',
  };
  if (password === '') {
    data.query = `
            mutation UpdateConstructionAccount{
              updateConstructionCompany(constructionCompany: {
                id: "${id}"
                email: "${email}"
                companyName: "${companyName}"
                acceptanceTermsOfUse: ${acceptanceTermsOfUse}
                phone: "${phone}"
                cnpjOrCpf: "${cnpjOrCpf}"
                ownerName: "${ownerName}"
                ownerEmail: "${ownerEmail}"
                ownerPhone: "${ownerPhone}"
                ownerWhatsApp: "${ownerWhatsApp}"
                })
            }
                  `;
  } else {
    data.query = `
            mutation UpdateConstructionAccount{
              updateConstructionCompany(constructionCompany: {
                id: "${id}"
                email: "${email}"
                password: "${password}"
                companyName: "${companyName}"
                acceptanceTermsOfUse: ${acceptanceTermsOfUse}
                phone: "${phone}"
                cnpjOrCpf: "${cnpjOrCpf}"
                ownerName: "${ownerName}"
                ownerEmail: "${ownerEmail}"
                ownerPhone: "${ownerPhone}"
                ownerWhatsApp: "${ownerWhatsApp}"
              })
            }
            `;
  }
  const UpdateConstructionAccount = await axios({
    url: process.env.REACT_APP_API_URL,
    method: 'POST',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
    data,
  });
  return UpdateConstructionAccount;
};

export const updateRentalCompany = async (
  rentalCompany: RentalCompanyInput | any,
  token: string,
) => {
  const {
    id,
    email,
    password,
    corporateName,
    planId,
    address,
    phone,
    whatsApp,
    cnpjOrCpf,
    ownerName,
    ownerEmail,
    ownerPhone,
    ownerWhatsApp,
    acceptanceTermsOfUse,
  } = rentalCompany;
  const data = {
    query: '',
  };
  if (password === '') {
    data.query = `
            mutation UpdateRentalAccount{
              updateRentalCompany(rentalCompany: {
                id: "${id}"
                acceptanceTermsOfUse: ${acceptanceTermsOfUse}
                email: "${email}"
                corporateName: "${corporateName}"
                planId: "${planId}"
                address: "${address}"
                phone: "${phone}"
                whatsApp: "${whatsApp}"
                cnpjOrCpf: "${cnpjOrCpf}"
                ownerName: "${ownerName}"
                ownerEmail: "${ownerEmail}"
                ownerPhone: "${ownerPhone}"
                ownerWhatsApp: "${ownerWhatsApp}"
                })
            }
                  `;
  } else {
    data.query = `
            mutation UpdateRentalAccount{
              updateRentalCompany(rentalCompany: {
                id: "${id}"
                acceptanceTermsOfUse: ${acceptanceTermsOfUse}
                email: "${email}"
                password: "${password}"
                corporateName: "${corporateName}"
                planId: "${planId}"
                address: "${address}"
                phone: "${phone}"
                whatsApp: "${whatsApp}"
                cnpjOrCpf: "${cnpjOrCpf}"
                ownerName: "${ownerName}"
                ownerEmail: "${ownerEmail}"
                ownerPhone: "${ownerPhone}"
                ownerWhatsApp: "${ownerWhatsApp}"
              })
            }
            `;
  }
  const UpdateRentalAccount = await axios({
    url: process.env.REACT_APP_API_URL,
    method: 'POST',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
    data,
  });
  return UpdateRentalAccount;
};

export const uploadImage = async (data: FormData) => {
  const uploadResult = await axios({
    url: process.env.REACT_APP_CLOUDINARY_URL,
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    data,
  });
  return uploadResult;
};

export const createQuotation = async (
  quotation: QuotationInput,
  token: string,
) => {
  const {
    constructionPlace,
    constructionState,
    machines,
    contactAuthorizationEmail,
    contactAuthorizationPhone,
    contactAuthorizationWhatsApp,
  } = quotation;

  const data = {
    query: `
    mutation CreateQuotation{
      createQuotation(quotation: {
        constructionState: "${constructionState}"
        constructionPlace: ${constructionPlace}
        contactAuthorizationEmail: ${contactAuthorizationEmail}
        contactAuthorizationPhone: ${contactAuthorizationPhone}
        contactAuthorizationWhatsApp: ${contactAuthorizationWhatsApp}
        machines: ${createMachineArray(machines)}
      })
    }
          `,
  };
  const CreateQuotation = await axios({
    url: process.env.REACT_APP_API_URL,
    method: 'POST',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
    data,
  });
  return CreateQuotation;
};

export const createMachine = async (
  machine: MachineInput | any,
  token: string,
) => {
  const {
    rentalCompanyId,
    MachineModelId,
    fabricationYear,
    minimumLeaseTerm,
    operation,
    onSale,
    onRent,
    onService,
  } = machine;
  const data = {
    query: `
    mutation CreateMachine{
      createMachine(machineInput: {
        rentalCompanyId: ${rentalCompanyId}
        MachineModelId: ${MachineModelId}
        fabricationYear: ${fabricationYear}
        minimumLeaseTerm: ${minimumLeaseTerm}
        operation: ${operation}
        onSale: ${onSale}
        onRent: ${onRent}
        onService: ${onService}
      })
    }
          `,
  };
  const CreateMachine = await axios({
    url: process.env.REACT_APP_API_URL,
    method: 'POST',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
    data,
  });
  return CreateMachine;
};

export const updateMachine = async (
  machine: MachineInput | any,
  token: string,
) => {
  const {
    id,
    rentalCompanyId,
    MachineModelId,
    fabricationYear,
    minimumLeaseTerm,
    operation,
    onSale,
    onRent,
    onService,
  } = machine;
  const data = {
    query: `
    mutation UpdateMachine{
      updateMachine(machineInput: {
        id: ${id}
        rentalCompanyId: ${rentalCompanyId}
        MachineModelId: ${MachineModelId}
        fabricationYear: ${fabricationYear}
        minimumLeaseTerm: ${minimumLeaseTerm}
        operation: ${operation}
        onSale: ${onSale}
        onRent: ${onRent}
        onService: ${onService}
      })
    }
          `,
  };
  const UpdateMachine = await axios({
    url: process.env.REACT_APP_API_URL,
    method: 'POST',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
    data,
  });
  return UpdateMachine;
};

export const deleteMachine = async (machineId: string, token: string) => {
  const data = {
    query: `
    mutation DeleteMachine{
      deleteMachine(machineId: "${machineId}")
    }
          `,
  };
  const DeleteMachine = await axios({
    url: process.env.REACT_APP_API_URL,
    method: 'POST',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
    data,
  });
  return DeleteMachine;
};

export const createDeliveryState = async (
  deliveryState: DeliveryStateInput,
  token: string,
) => {
  const { state, deliveryInCapital, deliveryInInland } = deliveryState;
  const data = {
    query: `
    mutation CreateDeliveryState{
      createDeliveryState(deliveryState: {
        state: "${state}"
        deliveryInCapital: ${deliveryInCapital}
        deliveryInInland: ${deliveryInInland}
      })
    }
          `,
  };
  const CreateDeliveryState = await axios({
    url: process.env.REACT_APP_API_URL,
    method: 'POST',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
    data,
  });
  return CreateDeliveryState;
};

export const updateDeliveryState = async (
  deliveryState: DeliveryState,
  token: string,
) => {
  // INCOMPLETO
  const { state, deliveryInCapital, deliveryInInland } = deliveryState;
  const data = {
    query: `
    mutation UpdateDeliveryState{
      updateDeliveryState(deliveryState: {
        state: "${state}"
        deliveryInCapital: ${deliveryInCapital}
        deliveryInInland: ${deliveryInInland}
      })
    }
          `,
  };
  const UpdateDeliveryState = await axios({
    url: process.env.REACT_APP_API_URL,
    method: 'POST',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
    data,
  });
  return UpdateDeliveryState;
};

export const deleteDeliveryState = async (id: string, token: string) => {
  const data = {
    query: `
    mutation DeleteDeliveryState{
      deleteDeliveryState(deliveryStateId: "${id}")
    }
          `,
  };
  const DeleteDeliveryState = await axios({
    url: process.env.REACT_APP_API_URL,
    method: 'POST',
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
    data,
  });
  return DeleteDeliveryState;
};

export const sendPdf = async (data: FormData, token: string) => {
  const SendPdf = await axios({
    url: process.env.REACT_APP_API_PDF,
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
  return SendPdf;
};

export const createMessage = async (
  name: string,
  email: string,
  subject: string,
  message: string,
) => {
  const data = {
    query: `
    mutation CreateMessage{
      createMessage(
      name: "${name}", 
      email: "${email}", 
      subject: "${subject}", 
      message: "${message}")
    }
          `,
  };
  const CreateMessage = await axios({
    url: process.env.REACT_APP_API_URL,
    method: 'POST',
    headers,
    data,
  });
  return CreateMessage;
};

export const auth = async (email: string, password: string) => {
  const data = {
    query: `
    mutation Auth{
      login(email: "${email}", password: "${password}" ){
        token
        role
        error
      }
    }
          `,
  };
  const authResult = await axios({
    url: process.env.REACT_APP_API_URL,
    method: 'POST',
    headers,
    data,
  });
  return authResult;
};

export const passwordReset = async (email: string) => {
  const data = {
    query: `
      mutation PasswordReset{
        passwordReset(email: "${email}")
      }
    `,
  };
  const PasswordReset = await axios({
    url: process.env.REACT_APP_API_URL,
    method: 'POST',
    headers,
    data,
  });
  return PasswordReset;
};

export const passwordResetTokenValidation = async (token: string) => {
  const data = {
    query: `
      mutation PasswordResetTokenValidation{
        passwordResetTokenValidation(token: "${token}")
      }
    `,
  };
  const PasswordResetTokenValidation = await axios({
    url: process.env.REACT_APP_API_URL,
    method: 'POST',
    headers,
    data,
  });
  return PasswordResetTokenValidation;
};

export const passwordUpdate = async (
  token: string,
  newPassword: string,
  newPasswordConfirmation: string,
) => {
  const data = {
    query: `
      mutation PasswordUpdate{
        passwordUpdate(
          token: "${token}"
          newPassword: "${newPassword}"
          newPasswordConfirmation: "${newPasswordConfirmation}"
        )
      }
    `,
  };
  const PasswordUpdate = await axios({
    url: process.env.REACT_APP_API_URL,
    method: 'POST',
    headers,
    data,
  });
  return PasswordUpdate;
};

export const createRentalCompany = async (
  rentalCompany: RentalCompanyInput,
) => {
  const {
    acceptanceTermsOfUse,
    address,
    cnpjOrCpf,
    corporateName,
    email,
    ownerEmail,
    ownerName,
    ownerPhone,
    ownerWhatsApp,
    password,
    phone,
    planId,
    whatsApp,
  } = rentalCompany;

  const data = {
    query: `
      mutation CreateRentalAccount{
        createRentalCompany(rentalCompany: {
          acceptanceTermsOfUse: ${acceptanceTermsOfUse}
          email: "${email}"
          password: "${password}"
          corporateName: "${corporateName}"
          cnpjOrCpf: "${cnpjOrCpf}"
          address: "${address}"
          phone: "${phone}"
          whatsApp: "${whatsApp}"
          planId: "${planId}"
          ownerName: "${ownerName}"
          ownerEmail: "${ownerEmail}"
          ownerPhone: "${ownerPhone}"
          ownerWhatsApp: "${ownerWhatsApp}"
        })
      }
          `,
  };
  const CreateRentalAccount = await axios({
    url: process.env.REACT_APP_API_URL,
    method: 'POST',
    headers,
    data,
  });
  return CreateRentalAccount;
};

export const setPayment = async (payment: PaymentInput) => {
  const {
    payerId,
    paymentEmail,
    paymentId,
    paymentAmount,
    planId,
    token,
  } = payment;

  const data = {
    query: `
      mutation SetPayment{
        setPayment(
          paymentId: "${paymentId}"
          paymentEmail: "${paymentEmail}"
          payerId: "${payerId}"
          paymentAmount: "${paymentAmount}"
          planId: "${planId}"
        )
      }
    `,
  };
  const SetPayment = await axios({
    url: process.env.REACT_APP_API_URL,
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
  return SetPayment;
};
