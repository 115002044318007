import React from 'react';
import { Card } from 'react-bootstrap';

import './styles.scss';
import styled from 'styled-components';

import { PartnerCompany } from '../../services/types';

const Partner: React.FC<PartnerCompany> = (props: PartnerCompany) => {
  const { corporateName, image, description } = props;

  const PartnerComponent = styled(Card)`
    background: url(${image}) no-repeat center;
    background-size: cover;
    border: 1px solid #e8e8e8;
  `;

  return (
    <PartnerComponent className="partner-component justify-content text-center">
      <Card.Title>{corporateName}</Card.Title>
      <Card.Text>{description}</Card.Text>
    </PartnerComponent>
  );
};

export default Partner;
