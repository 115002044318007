import { action } from 'typesafe-actions';
import { ModalsTypes, TermsAndPoliticType } from './types';
import { Quotation, Machine, DeliveryState } from '../../../services/types';

export const toggleModalQ = (data: Quotation | undefined) =>
  action(ModalsTypes.TOGGLE_MODAL_Q, data);

export const toggleModalM = (data: Machine | undefined) =>
  action(ModalsTypes.TOGGLE_MODAL_M, data);

export const toggleModalConfirm = (data: Machine | DeliveryState | undefined) =>
  action(ModalsTypes.TOGGLE_MODAL_CONFIRM, data);

export const toggleModalP = (data: DeliveryState | undefined) =>
  action(ModalsTypes.TOGGLE_MODAL_P, data);

export const toggleModalTerms = (data: TermsAndPoliticType | undefined) =>
  action(ModalsTypes.TOGGLE_MODAL_TERMS, data);
