import { Quotation, Machine, DeliveryState } from '../../../services/types';

/**
 * Action types
 * @TOGGLE_MODAL_Q modal quotation constructionCompany
 * @TOGGLE_MODAL_M modal createMachine rentalCompany
 * @TOGGLE_MODAL_CONFIRM modal confirm action
 * @TOGGLE_MODAL_P modal createPlace action
 */
export enum ModalsTypes {
  TOGGLE_MODAL_Q = '@modal/TOGGLE_MODAL_Q',
  TOGGLE_MODAL_M = '@modal/@TOGGLE_MODAL_M ',
  TOGGLE_MODAL_CONFIRM = '@modal/@TOGGLE_MODAL_CONFIRM ',
  TOGGLE_MODAL_P = '@modal/@TOGGLE_MODAL_P ',
  TOGGLE_MODAL_TERMS = '@modal/TOGGLE_MODAL_TERMS',
}
/**
 * State type
 * @data : the Address
 */
export type TermsAndPoliticType = 'politic' | 'terms' | '';
export interface ModalsState {
  statusQ: boolean;
  statusM: boolean;
  statusConfirm: boolean;
  statusP: boolean;
  statusTermsAndPolitic: boolean;
  typeTermsAndPolitic: TermsAndPoliticType;
  quotation: Quotation;
  machine: Machine;
  deliveryState: DeliveryState;
}
