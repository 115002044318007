import React from 'react';

import Header from '../../components/Header';

import MachinesPanel from '../../components/MachinesPanel';

const Machines: React.FC = () => (
  <>
    <Header />
    <section className="fade-in">
      <MachinesPanel />
    </section>
  </>
);

export default Machines;
