/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useRef, useEffect } from 'react';
import { Modal, Card, Col, Form, Row, Button, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import SelectSearch from 'react-select-search';
import { ReCaptcha } from 'react-recaptcha-v3';
import { ApplicationState } from '../../store';
import { toggleModalP } from '../../store/ducks/modals/actions';
import { DeliveryState, DeliveryStateInput } from '../../services/types';
import { checkDeliveryState } from '../../services/validation';
import { getRentalCompanyToken } from '../../services/auth';
import {
  updateRentalCompany,
  removeRentalCompany,
} from '../../store/ducks/rentalCompany/actions';
import {
  getRentalCompany,
  getRentalCompanyQuotations,
} from '../../services/queries';
import {
  createDeliveryState,
  updateDeliveryState,
} from '../../services/mutations';

const DeliveryStateModal: React.FC = () => {
  const modalPState = useSelector((state: ApplicationState) => state.modals);
  const rentalCompanyState = useSelector(
    (state: ApplicationState) => state.rentalCompany,
  );

  const dispatch = useDispatch();

  const token = getRentalCompanyToken();

  const verifyCallback = (recaptchaToken: string) => {
    // console.log('recaptchaToken', recaptchaToken);
  };
  const recaptcha = useRef(null);

  const [deliveryState, handleDeliveryState] = useState<
    DeliveryStateInput | DeliveryState
  >({
    state: '',
    deliveryInCapital: false,
    deliveryInInland: false,
  });

  const { state, deliveryInCapital, deliveryInInland } = deliveryState;

  const [loading, handleLoading] = useState(false);

  useEffect(() => {
    if (modalPState.deliveryState.id !== '') {
      handleDeliveryState({
        id: modalPState.deliveryState?.id,
        state: modalPState.deliveryState?.state,
        deliveryInCapital: modalPState.deliveryState?.deliveryInCapital,
        deliveryInInland: modalPState.deliveryState?.deliveryInInland,
      });
    }
  }, [
    modalPState.deliveryState,
    modalPState.deliveryState.deliveryInCapital,
    modalPState.deliveryState.deliveryInInland,
    modalPState.deliveryState.id,
    modalPState.deliveryState.state,
    modalPState.machine.id,
  ]);

  const handleClose = () => {
    handleDeliveryState({
      id: '',
      state: '',
      deliveryInCapital: false,
      deliveryInInland: false,
    });
    dispatch(
      toggleModalP({
        id: '',
        state: '',
        deliveryInCapital: false,
        deliveryInInland: false,
      }),
    );
    return false;
  };

  const updateStore = () => {
    getRentalCompany(token)
      .then(resp => {
        const rentalCompanyTemp = resp.data.data.rentalCompany.rentalCompany;
        if (rentalCompanyTemp) {
          getRentalCompanyQuotations(token)
            .then(result => {
              dispatch(
                updateRentalCompany({
                  rentalCompany: rentalCompanyTemp,
                  user: {
                    role: 'RENTAL',
                    token,
                  },
                  rentalCompanyQuotations:
                    result.data.data.rentalCompanyQuotations,
                }),
              );
            })
            .catch(error => {
              console.log('error', error);
            });
        } else {
          dispatch(removeRentalCompany());
        }
      })
      .catch(error => {
        console.log('error', error);
        dispatch(removeRentalCompany());
      });
  };

  const createPlace = (event: React.FormEvent) => {
    event.preventDefault();
    handleLoading(true);
    if (checkDeliveryState('state', state)) {
      handleLoading(true);
      if (recaptcha.current !== undefined) {
        (recaptcha as any).current.execute();
      }
      if (modalPState.deliveryState.state === '') {
        createDeliveryState(deliveryState, rentalCompanyState.user.token)
          .then(response => {
            handleLoading(false);
            if (
              checkDeliveryState(
                'create',
                response.data.data.createDeliveryState,
              )
            ) {
              updateStore();
              handleLoading(false);
              handleClose();
            }
          })
          .catch(error => {
            console.log('error 3', error);
          });
      } else {
        updateDeliveryState(
          { ...deliveryState, id: modalPState.deliveryState.id },
          rentalCompanyState.user.token,
        )
          .then(response => {
            handleLoading(false);
            if (
              checkDeliveryState(
                'update',
                response.data.data.updateDeliveryState,
              )
            ) {
              updateStore();
              handleLoading(false);
              handleClose();
            }
          })
          .catch(error => {
            console.log('error 4', error);
          });
      }
    }
  };

  return (
    <Modal
      show={modalPState.statusP}
      onHide={handleClose}
      aria-labelledby="contained-quotation-modal"
      className="modal-quotation"
      dialogClassName="modal-quotation"
      id="modal-quotation"
      size="sm"
      centered
    >
      <Card>
        <Col xs={12} className="modal-machine">
          <Modal.Header closeButton>
            {modalPState.deliveryState.state === '' ? (
              <h4>Cadastrar Localidade</h4>
            ) : (
              <h4>Editar localidade {modalPState.deliveryState.state}</h4>
            )}
          </Modal.Header>
          <Modal.Body
            className="mt-4 content rental-company-configs"
            style={{ padding: '0px' }}
          >
            <Form onSubmit={(event: React.FormEvent) => createPlace(event)}>
              <Row>
                <Col xs={12} md={12} sm={12} lg={12}>
                  <Form.Group>
                    <Form.Label>Estado</Form.Label>
                    <SelectSearch
                      options={[
                        { name: 'Acre', value: 'AC' },
                        { name: 'Alagoas', value: 'AL' },
                        { name: 'Amapá', value: 'AP' },
                        { name: 'Amazonas', value: 'AM' },
                        { name: 'Bahia', value: 'BA' },
                        { name: 'Ceará', value: 'CE' },
                        { name: 'Distrito Federal', value: 'DF' },
                        { name: 'Espírito Santo', value: 'ES' },
                        { name: 'Goiás', value: 'GO' },
                        { name: 'Maranhão', value: 'MA' },
                        { name: 'Mato Grosso', value: 'MT' },
                        { name: 'Mato Grosso do Sul', value: 'MS' },
                        { name: 'Minas Gerais', value: 'MG' },
                        { name: 'Pará', value: 'PA' },
                        { name: 'Paraíba', value: 'PB' },
                        { name: 'Paraná', value: 'PR' },
                        { name: 'Pernambuco', value: 'PE' },
                        { name: 'Piauí', value: 'PI' },
                        { name: 'Rio de Janeiro', value: 'RJ' },
                        { name: 'Rio Grande do Norte', value: 'RN' },
                        { name: 'Rio Grande do Sul', value: 'RS' },
                        { name: 'Rondônia', value: 'RO' },
                        { name: 'Roraima', value: 'RR' },
                        { name: 'Santa Catarina', value: 'SC' },
                        { name: 'São Paulo', value: 'SP' },
                        { name: 'Sergipe', value: 'SE' },
                        { name: 'Tocantins', value: 'TO' },
                      ]}
                      value={state}
                      placeholder="Selecione um Estado..."
                      search
                      onChange={event => {
                        handleDeliveryState({
                          ...deliveryState,
                          state: String(event),
                        });
                      }}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Capital e região metropolitana</Form.Label>
                    <Form.Group>
                      <input
                        type="radio"
                        id="radioItem1"
                        name="radioItem1"
                        value={deliveryInCapital ? 1 : 0}
                        checked={deliveryInCapital === true}
                        onChange={() =>
                          handleDeliveryState({
                            ...deliveryState,
                            deliveryInCapital: true,
                          })
                        }
                      />
                      <label htmlFor="radioItem1">Sim</label>
                      <input
                        type="radio"
                        id="radioItem2"
                        name="radioItem2"
                        value={deliveryInCapital ? 0 : 1}
                        checked={deliveryInCapital === false}
                        onChange={() =>
                          handleDeliveryState({
                            ...deliveryState,
                            deliveryInCapital: false,
                          })
                        }
                      />
                      <label htmlFor="radioItem2">Não</label>
                    </Form.Group>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Interior</Form.Label>
                    <Form.Group>
                      <input
                        type="radio"
                        id="radioItem3"
                        name="radioItem3"
                        value={deliveryInInland ? 1 : 0}
                        checked={deliveryInInland === true}
                        onChange={() =>
                          handleDeliveryState({
                            ...deliveryState,
                            deliveryInInland: true,
                          })
                        }
                      />
                      <label htmlFor="radioItem3">Sim</label>
                      <input
                        type="radio"
                        id="radioItem4"
                        name="radioItem4"
                        value={deliveryInInland ? 0 : 1}
                        checked={deliveryInInland === false}
                        onChange={() =>
                          handleDeliveryState({
                            ...deliveryState,
                            deliveryInInland: false,
                          })
                        }
                      />
                      <label htmlFor="radioItem4">Não</label>
                    </Form.Group>
                  </Form.Group>
                </Col>
              </Row>
              <ReCaptcha
                sitekey={process.env.REACT_APP_RECAPTCHA_KEY || ''}
                action="submit"
                ref={recaptcha}
                verifyCallback={verifyCallback}
              />
              <Col xs={12} sm={12} md={12} lg={12} className="justify-content">
                <Button type="submit" style={{ marginLeft: '0px' }}>
                  {!loading ? (
                    <>
                      {modalPState.deliveryState.state === ''
                        ? 'Cadastrar Localidade'
                        : 'Atualizar Localidade'}
                    </>
                  ) : (
                    <Spinner
                      as="span"
                      animation="border"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                </Button>
              </Col>
            </Form>
          </Modal.Body>
        </Col>
      </Card>
    </Modal>
  );
};

export default DeliveryStateModal;
