/**
 * Action types
 * @INSERT_MACHINE : insert product in cart
 * @REMOVE_MACHINE : remove product in cart
 * @CLEAR_CART : clear cart
 */
export enum CartTypes {
  INSERT_MACHINE = '@cart/INSERT_MACHINE',
  REMOVE_MACHINE = '@cart/REMOVE_MACHINE',
  CLEAR_CART = '@cart/CLEAR_CART',
}

export interface Item {
  id?: string
  image?: string
  model?: string
  categoryName: string
  categoryId: string
  machineTypeName: string
  fabricationYear?: number
  operation: boolean
  leaseTerm: number
  quantity: number
  EquipamentMachineId: string
}

/**
 * State type
 * @data
 */
export interface CartState {
  items: Item[]
}
