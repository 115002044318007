import { action } from 'typesafe-actions';
import { CartTypes, Item } from './types';

export const insertMachine = (data: Item) =>
  action(CartTypes.INSERT_MACHINE, data);
export const removeMachine = (data: number) =>
  action(CartTypes.REMOVE_MACHINE, data);
export const clearCart = () => action(CartTypes.CLEAR_CART);

/* export const updateOrderProduct = (
  data: Product,
  index: number,
) => action(OrderTypes.UPDATE_ORDER_PRODUCT, {
  data, index,
}); */
