import React, { useState } from 'react';

import './styles.scss';
import { Card, Col, Table, Row, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from '../../store';
import Pagination from '../Pagination';
import Machines from '../Machines';
import MachineModal from '../MachineModal';
import { toggleModalM } from '../../store/ducks/modals/actions';

const RentalCompanyMachines: React.FC = () => {
  const rentalCompanyState = useSelector(
    (state: ApplicationState) => state.rentalCompany,
  );

  const { rentalCompany } = rentalCompanyState;

  const dispatch = useDispatch();

  const handleShow = () => dispatch(toggleModalM(undefined));

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts =
    rentalCompany.machines !== null
      ? rentalCompany.machines.slice(indexOfFirstPost, indexOfLastPost)
      : [];

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  return (
    <Card className="rental-company-machines">
      <Card.Body>
        <Card.Title className="dashed pb-2">Máquinas cadastradas</Card.Title>
        <Col className="cart-table">
          {rentalCompany.machines === null ||
          rentalCompany.machines.length === 0 ? (
            <Row>
              <Col xs={12} sm={12} md={6} lg={9}>
                <h4>Nenhuma máquina cadastrada!</h4>
              </Col>
              <Col xs={12} sm={12} md={6} lg={3}>
                <Button className="btnToggle" onClick={handleShow}>
                  Adicionar máquina
                </Button>
              </Col>
            </Row>
          ) : (
            <>
              <Button className="btnToggle mb-4" onClick={handleShow}>
                Adicionar máquina
              </Button>
              <Table className="fade-in" responsive size="lg">
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Categoria</th>
                    <th>Equipamento/Máquina</th>
                    <th>Modelo</th>
                    <th>Fabricante</th>
                    <th>Ano de fabricação</th>
                    <th>Ação</th>
                  </tr>
                </thead>
                <Machines posts={currentPosts} />
              </Table>
              <Pagination
                postsPerPage={postsPerPage}
                totalPosts={rentalCompany.machines.length}
                paginate={paginate}
              />
            </>
          )}
        </Col>
      </Card.Body>
      <MachineModal />
    </Card>
  );
};

export default RentalCompanyMachines;
