import React from 'react';
import styled from 'styled-components';

import { Col } from 'react-bootstrap';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

import Banner from '../../images/banner.jpg';
import AboutUsInfos from '../../components/AboutUsInfos';
import PagePath from '../../components/PagePath';

const AboutUsBanner = styled(Col)`
  background: url(${Banner}) no-repeat center;
  height: 30vh;
  background-size: cover;
`;

const AboutUs: React.FC = () => (
  <>
    <Header />
    <PagePath />
    <section className="fade-in">
      <AboutUsBanner />
      <AboutUsInfos />
    </section>
    <Footer />
  </>
);

export default AboutUs;
