import { Reducer } from 'redux';
import { ModalsState, ModalsTypes } from './types';

const INITIAL_STATE: ModalsState = {
  statusQ: false,
  statusM: false,
  statusP: false,
  statusTermsAndPolitic: false,
  statusConfirm: false,
  typeTermsAndPolitic: '',
  quotation: {
    id: '',
    constructionCompanyId: '',
    companyName: '',
    cnpjOrCpf: '',
    image: '',
    address: '',
    ownerName: '',
    ownerEmail: '',
    ownerPhone: '',
    ownerWhatsApp: '',
    machines: [],
    pdfs: [],
    constructionState: '',
    constructionPlace: '',
    createdAt: '',
  },
  machine: {
    id: '',
    categoryId: '',
    city: '',
    createdAt: '',
    deliveryRadius: [],
    rentalCompanyId: '',
    state: '',
    machineTypeId: '',
    model: '',
    fabricationYear: 0,
    minimumLeaseTerm: 0,
    description: '',
    comments: '',
    image: '',
    operation: false,
    onSale: false,
    onRent: false,
    onService: false,
    manufacturerId: '',
    manufacturer: '',
    operationalCapacityReferential: '',
    operationalCapacityQuantitative: '',
    operationalCapacityUnit: '',
    equipamentMachineId: '',
    equipamentMachine: '',
  },
  deliveryState: {
    id: '',
    deliveryInCapital: false,
    deliveryInInland: false,
    state: '',
  },
};

const reducer: Reducer = (state = INITIAL_STATE, action) => {
  const updateModal: ModalsState = state;
  switch (action.type) {
    case ModalsTypes.TOGGLE_MODAL_Q:
      if (!action.payload) {
        updateModal.statusQ = !state.statusQ;
        updateModal.quotation = {
          ...{
            id: '',
            constructionCompanyId: '',
            companyName: '',
            cnpjOrCpf: '',
            image: '',
            address: '',
            ownerName: '',
            ownerEmail: '',
            ownerPhone: '',
            ownerWhatsApp: '',
            machines: [],
            pdfs: [],
            constructionState: '',
            constructionPlace: '',
            createdAt: '',
          },
        };
      } else {
        updateModal.statusQ = !state.statusQ;
        updateModal.quotation = { ...action.payload };
      }
      return {
        ...state,
        ...updateModal,
      };

    case ModalsTypes.TOGGLE_MODAL_M:
      if (action.payload === undefined) {
        updateModal.statusM = !state.statusM;
        updateModal.machine = {
          ...{
            id: '',
            categoryId: '',
            city: '',
            createdAt: '',
            deliveryRadius: [],
            rentalCompanyId: '',
            state: '',
            machineTypeId: '',
            model: '',
            fabricationYear: 0,
            minimumLeaseTerm: 0,
            description: '',
            comments: '',
            image: '',
            operation: false,
            onSale: false,
            onRent: false,
            onService: false,
            manufacturerId: '',
            manufacturer: '',
            operationalCapacityReferential: '',
            operationalCapacityQuantitative: '',
            operationalCapacityUnit: '',
            equipamentMachineId: '',
            equipamentMachine: '',
          },
        };
      } else {
        updateModal.statusM = !state.statusM;
        updateModal.machine = { ...action.payload };
      }
      return {
        ...state,
        ...updateModal,
      };

    case ModalsTypes.TOGGLE_MODAL_CONFIRM:
      if (action.payload === undefined) {
        updateModal.statusConfirm = !state.statusConfirm;
        updateModal.machine = { ...INITIAL_STATE.machine };
        updateModal.deliveryState = { ...INITIAL_STATE.deliveryState };
      } else {
        if (action.payload.deliveryInCapital === undefined) {
          updateModal.statusConfirm = !state.statusConfirm;
          updateModal.machine = { ...action.payload };
        }
        if (action.payload.model === undefined) {
          updateModal.statusConfirm = !state.statusConfirm;
          updateModal.deliveryState = { ...action.payload };
        }
      }
      return {
        ...state,
        ...updateModal,
      };

    case ModalsTypes.TOGGLE_MODAL_P:
      if (action.payload === undefined) {
        updateModal.statusP = !state.statusP;
        updateModal.deliveryState = {
          ...{
            id: '',
            deliveryInCapital: false,
            deliveryInInland: false,
            state: '',
          },
        };
      } else {
        updateModal.statusP = !state.statusP;
        updateModal.deliveryState = { ...action.payload };
      }
      console.log('state.statusP', state.statusP);

      return {
        ...state,
        ...updateModal,
      };

    case ModalsTypes.TOGGLE_MODAL_TERMS:
      if (action.payload === undefined) {
        updateModal.statusTermsAndPolitic = !state.statusTermsAndPolitic;
      } else if (action.payload === 'politic') {
        updateModal.typeTermsAndPolitic = 'politic';
        updateModal.statusTermsAndPolitic = !state.statusTermsAndPolitic;
      } else if (action.payload === 'terms') {
        updateModal.typeTermsAndPolitic = 'terms';
        updateModal.statusTermsAndPolitic = !state.statusTermsAndPolitic;
      }

      return {
        ...state,
        ...updateModal,
      };
    default:
      return state;
  }
};

export default reducer;
