import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import './styles.scss';

import { Col, Table, Button } from 'react-bootstrap';
import { ApplicationState } from '../../store';
import { Item } from '../../store/ducks/cart/types';
import { removeMachine } from '../../store/ducks/cart/actions';
import FontAwesome from '../FontAwesome';
import { refactorCategoryName } from '../../services/mask';

const CartTable: React.FC = () => {
  const cart = useSelector((state: ApplicationState) => state.cart);

  const { items } = cart;

  const dispatch = useDispatch();

  return (
    <Col className="cart-table">
      <h3>Seu Carrinho</h3>
      {items.length === 0 ? (
        <h4>
          Carrinho vazio. Insira máquinas para solicitar um orçamento. É grátis!
        </h4>
      ) : (
        <Table className="fade-in table-hover" responsive size="lg">
          <thead>
            <tr>
              <th>Modelo</th>
              <th>Categoria</th>
              <th>Equipamento/Máquina</th>
              <th>Fabricante</th>
              {/* <th>Ano de Fabricação</th> */}
              <th>Operação</th>
              <th>Compra</th>
              <th>Prestação de serviço</th>
              <th>Locação</th>
              <th>Período de locação</th>
              <th>Quantidade</th>
              <th>Ação</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item: Item | any, index: number) => (
              <tr className="fade-in" key={`${item.machineTypeName + index}`}>
                <td>{item.model || 'Não especificado'}</td>
                <td>{refactorCategoryName(item.categoryName).toLowerCase()}</td>
                <td>{item.machineTypeName.toLowerCase()}</td>
                <td> {item.manufacturer} </td>
                {/* <td>{item.fabricationYear || 'Não contém'}</td> */}
                <td>{item.operation === true ? 'Sim' : 'Não'}</td>
                <td>{item.onSale === true ? 'Sim' : 'Não'}</td>
                <td>{item.onService === true ? 'Sim' : 'Não'}</td>
                <td>{item.onRent === true ? 'Sim' : 'Não'}</td>
                <td>
                  {item.leaseTerm > 1
                    ? `${item.leaseTerm} meses`
                    : `${item.leaseTerm} mês`}
                </td>
                <td>{item.quantity}</td>
                <td>
                  <Button
                    className="remove"
                    title="Remover máquina"
                    onClick={() => {
                      dispatch(removeMachine(index));
                    }}
                  >
                    <FontAwesome type="fas" name="trash" />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </Col>
  );
};

export default CartTable;
