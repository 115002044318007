import React from 'react';
import { Card } from 'react-bootstrap';

import './styles.scss';
import styled from 'styled-components';
import { refactorTitle } from '../../services/mask';

export interface CategoryType {
  title: string;
  background: string;
}

const CategoryComponent = styled(Card)`
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid #e8e8e8;
  justify-content: flex-end;
`;

const Category: React.FC<CategoryType> = (props: CategoryType) => {
  const { title, background } = props;

  return (
    <CategoryComponent className="category-component text-center fade-in">
      <Card.Body>
        <img src={background.replace(/'/g, '')} alt="category" />
      </Card.Body>
      <Card.Title>
        <p>{refactorTitle(title)}</p>
      </Card.Title>
    </CategoryComponent>
  );
};

export default Category;
