/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useCallback } from 'react';
import { Form, Col, Row, Button } from 'react-bootstrap';

import './styles.scss';
import SelectSearch from 'react-select-search';

import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  getCategories,
  getMachinesTypes,
  getMachines,
} from '../../services/queries';
import {
  Category,
  Machine,
  Options,
  MachineTypes,
  DeliveryState,
} from '../../services/types';
import { checkAdvancedSearch } from '../../services/validation';
import { insertMachine } from '../../store/ducks/cart/actions';
import { refactorCategoryName } from '../../services/mask';
import { orderByModel, orderByName } from '../../services/functions';

const SearchData: React.FC = () => {
  const dispatch = useDispatch();

  const [category, handleCategory] = useState('');
  const [machineType, handleMachineType] = useState('');
  const [model, handleModel] = useState('');
  const [quantity, handleQuantity] = useState<number>(1);
  const [period, handlePeriod] = useState<number>(1);
  const [operation, handleOperation] = useState(0);
  const [sale, handleSale] = useState(0);
  const [rent, handleRent] = useState(0);
  const [service, handleService] = useState(0);
  const [machines, handleMachines] = useState<Machine[]>();
  const [categorySelected, handleCategorySelected] = useState<Category>();
  const [manufacturer, handleManufacturer] = useState<string>('');
  const [manufacturers, handleManufacturers] = useState<Options[]>([]);
  const [models, handleModels] = useState<Options[]>([]);
  const [machine, handleMachine] = useState<Machine | any>({
    id: '',
    rentalCompanyId: '',
    categoryId: '',
    machineTypeId: '',
    model: '',
    operation: false,
    fabricationYear: 0,
    minimumLeaseTerm: 0,
    description: '',
    comments: '',
    city: '',
    state: '',
    deliveryRadius: [],
    onSale: false,
    onService: false,
    onRent: false,
    createdAt: '',
  });
  const [categoriesList, handleCategoriesList] = useState([]);
  const [machinesTypes, handleMachinesTypes] = useState<MachineTypes[]>([]);
  const [isLoadingManufacturer, handleIsLoadingManufacturer] = useState<
    boolean
  >(false);
  const [isLoadingModel, handleIsLoadingModel] = useState<boolean>(false);

  const getAllCategories = useCallback(() => {
    getCategories().then(async response =>
      handleCategoriesList(orderByName(response.data.data.categories)),
    );
  }, []);

  const getAllMachines = useCallback(() => {
    getMachines().then(async response =>
      handleMachines(orderByModel(response.data.data.machines)),
    );
  }, []);

  const getAllMachinesTypes = useCallback(() => {
    // eslint-disable-next-line max-len
    getMachinesTypes().then(async response =>
      handleMachinesTypes(orderByName(response.data.data.equipamentMachines)),
    );
  }, []);

  const getCategoriesOptions = useCallback(() => {
    const categoriesOptions: Options[] = [];
    categoriesList.map((categoryItem: Category) =>
      categoriesOptions.push({
        name: refactorCategoryName(categoryItem.name),
        value: categoryItem.id,
      }),
    );
    return categoriesOptions;
  }, [categoriesList]);

  const getMachinesTypesOptions = useCallback(() => {
    const machinesOptions: Options[] = [];
    try {
      machinesTypes.map((machineTypeFilterItem: MachineTypes) =>
        machinesOptions.push({
          name: machineTypeFilterItem.name,
          value: machineTypeFilterItem.id,
        }),
      );
      return machinesOptions;
    } catch (error) {
      return [];
    }
  }, [machinesTypes]);

  const getModelsOptions = (manufacturerId: string) => {
    const modelsOptions: Options[] = [];
    handleIsLoadingModel(true);
    try {
      machines
        ?.filter(
          (machineItem: any) =>
            machineItem.manufacturerId === manufacturerId &&
            machineItem.equipamentMachineId === machineType,
        )
        .map((machineFiltered: any) =>
          modelsOptions.push({
            value: machineFiltered.modelId,
            name: machineFiltered.model,
          }),
        );
      handleIsLoadingModel(false);
      handleModels(modelsOptions);
    } catch (error) {
      return [];
    }
    return modelsOptions;
  };

  const selectCategory = (categoryId: string) => {
    handleCategory(categoryId);
    const categoryFiltered = categoriesList.filter(
      (categoryItem: Category) => categoryItem.id === categoryId,
    )[0];
    handleCategorySelected(categoryFiltered);
  };

  const addMachineToCart = (event: React.FormEvent) => {
    event.preventDefault();
    if (
      checkAdvancedSearch('category', category) &&
      checkAdvancedSearch('machineType', machineType) &&
      checkAdvancedSearch('model', model) &&
      checkAdvancedSearch('quantity', String(quantity)) &&
      checkAdvancedSearch('period', String(period))
    ) {
      const categoryTemp: Category[] = categoriesList.filter(
        (categoryItem: Category) => categoryItem.id === category,
      );
      const categoryName = categoryTemp[0].name;
      const categoryId = categoryTemp[0].id;

      const data = {
        id: machine.id || '',
        image: categorySelected?.background || '',
        model: machine.model || '',
        modelId: machine.modelId,
        categoryName,
        categoryId,
        manufacturer: machine.manufacturer,
        machineTypeName: machine.equipamentMachine,
        EquipamentMachineId: machine.equipamentMachineId,
        operation: operation === 1,
        fabricationYear: machine.fabricationYear || 0,
        leaseTerm: period,
        quantity,
        onSale: sale === 1,
        onRent: rent === 1,
        onService: service === 1,
      };
      dispatch(insertMachine(data));
    }
  };

  const clearFields = useCallback(() => {
    handleModel('');
    handleMachinesTypes([]);
    handleMachineType('');
    handleManufacturer('');
    handleQuantity(1);
    handlePeriod(1);
  }, []);

  useEffect(() => {
    getAllMachines();
    getAllMachinesTypes();
    getAllCategories();
  }, [operation]);

  const getManufacturer = useCallback(
    (equipamentId: string) => {
      const manufacturersFilter: Options[] = [];

      handleIsLoadingManufacturer(true);

      machines
        ?.filter(
          (machineItem: any) =>
            machineItem.equipamentMachineId === equipamentId,
        )
        .map((machineFiltered: any) => {
          manufacturersFilter.push({
            name: machineFiltered.manufacturer,
            value: machineFiltered.manufacturerId,
          });
        });

      handleIsLoadingManufacturer(false);

      handleManufacturers(manufacturersFilter);
    },
    [machines],
  );

  return (
    <section className="search-data">
      <Form onSubmit={(event: React.FormEvent) => addMachineToCart(event)}>
        <h3>ETAPA 1 - Dados</h3>
        <p>Preencha um tipo de máquina por vez</p>
        <Form.Row>
          <Col>
            <Form.Label>Equipamento/Máquina</Form.Label>
            {!machinesTypes.length ? (
              <Form.Text>Carregando ...</Form.Text>
            ) : (
              <SelectSearch
                options={getMachinesTypesOptions()}
                value={machineType}
                placeholder="Selecione um tipo de máquina..."
                search
                // disabled={machinesTypes.length === 0}
                onChange={event => {
                  const categoryTemp = String(
                    machinesTypes &&
                      machinesTypes.filter(
                        (machineTypeTemp: MachineTypes) =>
                          machineTypeTemp.id === String(event),
                      )[0]?.categoryId,
                  );
                  selectCategory(categoryTemp);
                  handleModel('');
                  handleManufacturer('');
                  handleMachineType(String(event));
                  getManufacturer(String(event));
                }}
              />
            )}
          </Col>
          <Col>
            <Form.Label>Categoria</Form.Label>
            <SelectSearch
              options={getCategoriesOptions()}
              value={category}
              disabled
              // placeholder="Selecione uma categoria..."
              // search
              // onChange={event => {
              // selectCategory(String(event));
              // clearFields();
              // getAllMachinesTypes();
              // getAllMachines();
              // }}
            />
          </Col>
        </Form.Row>
        {!manufacturers.length &&
        !models.length &&
        category !== '' &&
        machineType !== '' ? (
          <Row>
            <Col>
              <Form.Text>
                Ops, as locadoras ainda não cadastraram equipamentos desse tipo.
                Em breve, serão cadastrados!
              </Form.Text>
            </Col>
          </Row>
        ) : (
          <>
            <Form.Row>
              <Col>
                <Form.Label>Fabricante</Form.Label>
                {isLoadingManufacturer ? (
                  <Form.Text>Carregando ...</Form.Text>
                ) : (
                  <SelectSearch
                    options={manufacturers}
                    value={manufacturer}
                    placeholder="Selecione um Fabricante..."
                    search
                    disabled={machineType === '' || !manufacturers.length}
                    onChange={event => {
                      handleManufacturer(String(event));
                      getModelsOptions(String(event));
                      handleModel('');
                    }}
                  />
                )}
              </Col>
              <Col>
                <Form.Label>Modelo</Form.Label>
                {isLoadingModel ? (
                  <Form.Text>Carregando ...</Form.Text>
                ) : (
                  <SelectSearch
                    options={models}
                    value={model}
                    placeholder="Selecione um modelo..."
                    search
                    disabled={manufacturer === '' || !models.length}
                    onChange={event => {
                      handleModel(String(event));
                      if (machines) {
                        const tempResult = machines.filter(
                          (machineTemp: Machine | any) =>
                            machineTemp.modelId === String(event) &&
                            machineTemp.equipamentMachineId === machineType &&
                            machineTemp.manufacturerId === manufacturer,
                        )[0];
                        handleMachine(tempResult);
                        handlePeriod(tempResult.minimumLeaseTerm);
                      }
                    }}
                  />
                )}
              </Col>
            </Form.Row>
            <Form.Row>
              <Col>
                <Form.Label>Quantidade</Form.Label>
                <Form.Control
                  value={quantity}
                  type="number"
                  onChange={e => handleQuantity(Number(e.target.value))}
                />
              </Col>
              <Col>
                <Form.Label>Meses de locação (caso alugue)</Form.Label>
                <Form.Control
                  value={period}
                  type="number"
                  min={machine.minimumLeaseTerm}
                  max={999}
                  onChange={e => handlePeriod(Number(e.target.value))}
                />
              </Col>
            </Form.Row>
            {/* {machine.fabricationYear !== 0 && (
              <Col>
                <Form.Label>Ano de Fabricação: </Form.Label>
                <Form.Text>{machine.fabricationYear}</Form.Text>
              </Col>
            )} */}
            <Form.Row>
              <Col>
                <Form.Label>Com operador?</Form.Label>
                <Row className="operator">
                  {/* {machine.operation === true || machine.id.length === 0 ? ( */}
                  <input
                    type="radio"
                    id="radioItem"
                    name="radioItem"
                    value={operation}
                    checked={operation === 1}
                    onChange={() => handleOperation(1)}
                  />
                  <label htmlFor="radioItem">Sim</label>
                  <input
                    type="radio"
                    id="radioItem1"
                    name="radioItem1"
                    value={operation}
                    checked={operation === 0}
                    onChange={() => handleOperation(0)}
                  />
                  <label htmlFor="radioItem1">Não</label>
                </Row>
              </Col>
              <Col>
                <Form.Label>Prestação de serviço?</Form.Label>
                <Row className="operator">
                  <input
                    type="radio"
                    id="radioItem2"
                    name="radioItem2"
                    value={service}
                    checked={service === 1}
                    onChange={() => handleService(1)}
                  />
                  <label htmlFor="radioItem2">Sim</label>
                  <input
                    type="radio"
                    id="radioItem3"
                    name="radioItem3"
                    value={service}
                    checked={service === 0}
                    onChange={() => handleService(0)}
                  />
                  <label htmlFor="radioItem3">Não</label>
                </Row>
              </Col>
            </Form.Row>
            <Form.Row>
              <Col>
                <Form.Label>Compra?</Form.Label>
                <Row className="operator">
                  <input
                    type="radio"
                    id="radioItem4"
                    name="radioItem4"
                    value={sale}
                    checked={sale === 1}
                    onChange={() => handleSale(1)}
                  />
                  <label htmlFor="radioItem4">Sim</label>

                  <input
                    type="radio"
                    id="radioItem5"
                    name="radioItem5"
                    value={sale}
                    checked={sale === 0}
                    onChange={() => handleSale(0)}
                  />
                  <label htmlFor="radioItem5">Não</label>
                </Row>
              </Col>
              <Col>
                <Form.Label>Locação?</Form.Label>
                <Row className="operator">
                  <input
                    type="radio"
                    id="radioItem6"
                    name="radioItem6"
                    value={rent}
                    checked={rent === 1}
                    onChange={() => handleRent(1)}
                  />
                  <label htmlFor="radioItem6">Sim</label>

                  <input
                    type="radio"
                    id="radioItem7"
                    name="radioItem7"
                    value={rent}
                    checked={rent === 0}
                    onChange={() => handleRent(0)}
                  />
                  <label htmlFor="radioItem7">Não</label>
                </Row>
              </Col>
            </Form.Row>
            {machine.id.length === 0 ? (
              ''
            ) : (
              <>
                <Form.Label>
                  Locais de obra disponíveis para a máquina
                </Form.Label>
                {machine.deliveryRadius.map(
                  (deliveryState: DeliveryState, index: number) => (
                    <li key={`${deliveryState.state + index}`}>
                      {deliveryState.state}:{' '}
                      {deliveryState.deliveryInCapital &&
                      !deliveryState.deliveryInInland
                        ? 'Capital e região metropolitana'
                        : ''}
                      {deliveryState.deliveryInInland &&
                      deliveryState.deliveryInCapital
                        ? 'Capital, região metropolitana e interior'
                        : ''}
                      {deliveryState.deliveryInInland &&
                      !deliveryState.deliveryInCapital
                        ? 'Apenas interior'
                        : ''}{' '}
                    </li>
                  ),
                )}
              </>
            )}
          </>
        )}
        {!manufacturers.length &&
          !models.length &&
          category !== '' &&
          machineType !== '' && (
            <Row>
              <Col>
                <Form.Text>
                  Não encontrou o modelo que deseja? Entre em contato
                  <Link to="/contato">{' aqui '}</Link>e solicite o cadastro.
                </Form.Text>
              </Col>
            </Row>
          )}
        <Form.Text className="text-center mt-4">
          <Row>
            <Col xs={6}>
              <Button
                className="btn-clear"
                onClick={() => {
                  clearFields();
                  handleCategory('');
                }}
              >
                Limpar filtros
              </Button>
            </Col>
            <Col xs={6}>
              <Button type="submit">Adicionar à lista</Button>
            </Col>
          </Row>
        </Form.Text>
      </Form>
    </section>
  );
};

export default SearchData;
