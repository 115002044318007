import { Reducer } from 'redux';
import SimpleCrypto from 'simple-crypto-js';
import { RentalCompanyState, RentalCompanyTypes } from './types';

const INITIAL_STATE: RentalCompanyState = {
  rentalCompany: {
    id: '',
    machines: [],
    deliveryRadius: [],
    corporateName: '',
    description: '',
    cnpjOrCpf: '',
    address: '',
    phone: '',
    whatsApp: '',
    email: '',
    planId: '',
    image: '',
    status: false,
    state: '',
    city: '',
    ownerName: '',
    ownerEmail: '',
    ownerPhone: '',
    ownerWhatsApp: '',
    createdAt: '',
    lastLogin: '',
  },
  user: {
    token: '',
    role: '',
  },
  rentalCompanyQuotations: [],
};

const reducer: Reducer<RentalCompanyState> = (
  state = INITIAL_STATE,
  action,
) => {
  const updatedRentalCompanyState = state;
  const simpleCripto = new SimpleCrypto(process.env.REACT_APP_CRIPTO_KEY || '');

  switch (action.type) {
    case RentalCompanyTypes.UPDATE_RENTAL_COMPANY:
      updatedRentalCompanyState.user = action.payload.user;
      updatedRentalCompanyState.rentalCompany = action.payload.rentalCompany;
      updatedRentalCompanyState.rentalCompanyQuotations =
        action.payload.rentalCompanyQuotations;
      localStorage.setItem(
        process.env.REACT_APP_LOCAL_STORAGE_RENTAL_COMPANY_AUTH || '' || '',
        JSON.stringify(simpleCripto.encrypt(action.payload.user.token)),
      );
      return { ...state, ...updatedRentalCompanyState };

    case RentalCompanyTypes.REMOVE_RENTAL_COMPANY:
      localStorage.removeItem(
        process.env.REACT_APP_LOCAL_STORAGE_RENTAL_COMPANY_AUTH || '' || '',
      );
      return { ...state, ...INITIAL_STATE };

    default:
      return state;
  }
};

export default reducer;
