import { Reducer } from 'redux';
import { CartState, CartTypes } from './types';

export const INITIAL_STATE: CartState = {
  items: JSON.parse(
    localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE_CART || '') ||
      '[]',
  ),
};

const reducer: Reducer<CartState> = (state = INITIAL_STATE, action) => {
  const insertMachine = state;
  const removeMachine = state;
  const clearCart: CartState = { items: [] };

  switch (action.type) {
    case CartTypes.INSERT_MACHINE:
      insertMachine.items.push(action.payload);
      localStorage.setItem(
        process.env.REACT_APP_LOCAL_STORAGE_CART || '',
        JSON.stringify(insertMachine.items),
      );
      return { ...state, ...insertMachine };

    case CartTypes.REMOVE_MACHINE:
      removeMachine.items.splice(action.payload, 1);
      localStorage.setItem(
        process.env.REACT_APP_LOCAL_STORAGE_CART || '',
        JSON.stringify(removeMachine.items),
      );
      return { ...state, ...removeMachine };

    case CartTypes.CLEAR_CART:
      localStorage.removeItem('mpcCart');
      return {
        ...state,
        ...clearCart,
      };

    default:
      return state;

    /* case OrderTypes.UPDATE_ORDER_PRODUCT:
      const updatedCart = state;
      if (action.quantity >= 1) {
        updatedCart.items[action.index] = {
          ...action.data,
          quantity: action.quantity,
          observations: action.observations,
          subtotal: action.quantity * action.data.price,
          total: action.quantity * action.data.price,
        };
      } else {
        updatedCart.items.splice(action.index, 1);
      }

      updatedCart.subtotal = updatedCart.items.reduce(
        (subtotal: number, product: Product) => subtotal + product.subtotal,
        0,
      );

      localStorage.setItem(
        `products_${URI}`,
        JSON.stringify(updatedCart.items),
      );
      return { ...state, ...updatedCart };

    case OrderTypes.INSERT_ORDER_PRODUCT:
      const cartItem = {
        ...state,
      };

      if (cartItem.items[0] === undefined) {
        cartItem.items[0] = action.data;
        cartItem.subtotal = cartItem.items.reduce(
          (subtotal: number, product: Product) => subtotal + product.subtotal,
          0,
        );
        localStorage.setItem(`products_${URI}`, JSON.stringify(cartItem.items));
        return { ...state, ...cartItem };
      }
      cartItem.items.push(action.data);

      cartItem.subtotal = cartItem.items.reduce(
        (subtotal: number, product: Product) => subtotal + product.subtotal,
        0,
      );

      localStorage.setItem(`products_${URI}`, JSON.stringify(cartItem.items));
      return { ...state, ...cartItem };

    case OrderTypes.UPDATE_TOTAL:
      const total = state;

      total.subtotal = Number(total.subtotal.toFixed(2));

      total.total = total.subtotal + total.deliveryFee;

      total.items = total.items.map(
        (product: Product, index: number) => (total.items[index] = { ...product, item: index + 1 }),
      );
      return { ...state, ...total };
      */
  }
};

export default reducer;
