import React, { useState } from 'react';

import './styles.scss';
import { Card, Col, Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import Pagination from '../Pagination';
import { Quotations } from '../Quotations';
import QuotationModal from '../QuotationModal';

const UserQuotations: React.FC = () => {
  const constructionCompanyState = useSelector(
    (state: ApplicationState) => state.constructionCompany,
  );

  const { constructionCompanyQuotations } = constructionCompanyState;

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = constructionCompanyQuotations.slice(
    indexOfFirstPost,
    indexOfLastPost,
  );

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  return (
    <Card className="user-quotations">
      <Card.Body>
        {/* <Card.Title>Orçamentos</Card.Title> */}
        <Col className="cart-table">
          <Card.Title>Veja aqui os orçamentos solicitados</Card.Title>
          {constructionCompanyQuotations.length === 0 ? (
            <h4>
              Carrinho vazio. Insira máquinas para solicitar um orçamento. É
              grátis!
            </h4>
          ) : (
            <>
              <Table className="fade-in" responsive size="lg">
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Data de envio</th>
                    <th>Quantidade de Máquinas</th>
                    <th>Localidade</th>
                    {/* <th>Status</th> */}
                    <th>Orçamentos recebidos</th>
                    <th>Ação</th>
                  </tr>
                </thead>
                <Quotations posts={currentPosts} />
              </Table>
              <Pagination
                postsPerPage={postsPerPage}
                totalPosts={constructionCompanyQuotations.length}
                paginate={paginate}
              />
              <QuotationModal />
            </>
          )}
        </Col>
      </Card.Body>
    </Card>
  );
};

export default UserQuotations;
