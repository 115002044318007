import { combineReducers } from 'redux';

import rentalCompany from './rentalCompany';
import constructionCompany from './constructionCompany';
import modals from './modals';
import cart from './cart';

export default combineReducers({
  rentalCompany,
  constructionCompany,
  modals,
  cart,
});
