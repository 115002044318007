import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

import Logo from '../../images/logo/logo.png';
import Juliano from '../../images/juliano.png';

import './styles.scss';

const Footer: React.FC = () => (
  <Col className="footer-master">
    <Row>
      <Col className="text-center" xs={12} sm={12} md={4} lg={2}>
        <img className="logo" src={Logo} alt="logo" />
        <h2>Máquinas para Construção</h2>
      </Col>
      <Col xs={12} sm={12} md={6} lg={8}>
        <Row>
          <Col className="justify-content" xl={1}>
            <Link to="/">Início</Link>
          </Col>
          <Col className="justify-content" xl={2}>
            <Link to="/orcamento">Preciso de Máquinas</Link>
          </Col>
          <Col className="justify-content" xl={2}>
            <Link to="/sejaumlocadormpc">Tenho Máquinas</Link>
          </Col>
          <Col className="justify-content" xl={2}>
            <Link to="/descricao">Como funciona o MPC?</Link>
          </Col>
          <Col className="justify-content" xl={1}>
            <Link to="/sobre">Sobre</Link>
          </Col>
          <Col className="justify-content" xl={1}>
            <Link to="/contato">Contato</Link>
          </Col>
        </Row>
      </Col>
      <Col xs={12} sm={12} md={2} lg={2}>
        <img src={Juliano} alt="juliano" />
      </Col>
    </Row>
    <div className="blue-bar">
      <p>versão {process.env.REACT_APP_VERSION}</p>
    </div>
  </Col>
);

export default Footer;
