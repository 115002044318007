/* eslint-disable array-callback-return */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  Col,
  Row,
  Card,
  Collapse,
  Spinner,
  FormControl,
  Form,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import SelectSearch from 'react-select-search';
import Machine from '../Machine';
import {
  getMachinesTypes,
  getCategories,
  getMachines,
} from '../../services/queries';
import {
  Category,
  MachineTypes,
  Machine as MachineItem,
  DeliveryState,
  Options,
} from '../../services/types';
import { refactorCategoryName, removeSpecialChars } from '../../services/mask';

import './styles.scss';
import {
  groupMinimumLeaseTerm,
  groupState,
  filterItems,
  orderByName,
  orderByModel,
} from '../../services/functions';
import FontAwesome from '../FontAwesome';
import { PagePathManual } from '../PagePath';
import Footer from '../Footer';
import CategorySlick from '../CategorySlick';

const MachinesPanel: React.FC = () => {
  const URI = window.location.pathname.substring(1).split('/');

  const [link, handleLink] = useState('');
  const [subLink, handleSubLink] = useState('');

  const [openMinimumLeaseTerm, handleOpenMinimumLeaseTerm] = useState(false);
  const [openOperation, handleOpenOperation] = useState(false);
  const [openLocation, handleOpenLocation] = useState(false);

  const [minimumLeaseTerm, handleMinimumLeaseTerm] = useState<number>(0);
  const [operation, handleOperation] = useState<number>(0);
  const [state, handleState] = useState<string>('');

  const [filter, handleFilter] = useState<string>('');
  const [machines, handleMachines] = useState<MachineItem[]>([]);
  const [filteredItems, handleFilteredItems] = useState<MachineItem[]>([]);
  const [minimumLeaseTermItems, handleMinimumLeaseTermItems] = useState<
    number[]
  >([]);
  const [stateItems, handleStateItems] = useState<string[]>([]);
  const [categorySelect, handleCategorySelect] = useState<Category>();
  const [categoriesList, handleCategoriesList] = useState<Category[]>([]);
  const [machinesTypes, handleMachinesTypes] = useState([]);
  const [categoryName, handleCategoryName] = useState('');
  const [category, handleCategory] = useState('');
  const [machineType, handleMachineType] = useState('');
  const [loadingMachines, handleLoadingMachines] = useState(true);

  const getAllMachines = async () => {
    await getCategories().then(async result => {
      const categoryTemp: Category = result.data.data.categories.filter(
        (categoryItem: Category) =>
          removeSpecialChars(categoryItem.name) === URI[1],
      )[0];
      handleCategoriesList(orderByName(result.data.data.categories));
      handleCategorySelect(categoryTemp);
      handleSubLink(categoryTemp.name);
      await getMachinesTypes().then(async res => {
        const machineTypeTemp = res.data.data.equipamentMachines.filter(
          (machineTypeItem: MachineTypes) =>
            machineTypeItem.categoryId === categoryTemp.id &&
            removeSpecialChars(machineTypeItem.name) === URI[2],
        )[0];
        handleLink(machineTypeTemp.name);
        handleMachinesTypes(orderByName(res.data.data.equipamentMachines));
        handleLoadingMachines(true);
        await getMachines()
          .then(async response => {
            handleLoadingMachines(false);
            const temp: MachineItem[] = orderByModel(
              response.data.data.machines,
            ).filter(
              (machineItem: any) =>
                machineItem.categoryId === machineTypeTemp.categoryId &&
                machineItem.equipamentMachineId === machineTypeTemp.id,
            );
            handleMinimumLeaseTermItems(
              groupMinimumLeaseTerm(temp).sort((a: number, b: number) => a - b),
            );
            handleStateItems(groupState(temp));
            handleMachines(temp);
            handleFilteredItems(temp);
          })
          .catch(async error => {
            // eslint-disable-next-line no-alert
            alert(error);
            return 0;
          });
      });
    });
  };

  const clearFilters = () => {
    handleFilter('');
    handleState('');
    handleOperation(0);
    handleMinimumLeaseTerm(0);
    handleFilteredItems(machines);
  };

  useEffect(() => {
    getAllMachines();
  }, []);

  const getCategoriesOptions = () => {
    const categoriesOptions: Options[] = [];
    categoriesList.map((categoryItem: Category) =>
      categoriesOptions.push({
        name: refactorCategoryName(categoryItem.name),
        value: categoryItem.id,
      }),
    );
    return categoriesOptions;
  };

  const getMachinesTypesOptions = () => {
    const machinesOptions: Options[] = [];
    try {
      machinesTypes
        .filter(
          (machineTypeFilter: MachineTypes) =>
            machineTypeFilter.categoryId === category,
        )
        .map((machineTypeFilterItem: MachineTypes) =>
          machinesOptions.push({
            name: machineTypeFilterItem.name,
            value: machineTypeFilterItem.id,
          }),
        );
      return machinesOptions;
    } catch (error) {
      return [];
    }
  };

  const selectCategory = (IdCategory: string) => {
    handleCategory(IdCategory);
    categoriesList.map((categoryItem: Category) => {
      if (categoryItem.id === IdCategory) {
        handleCategoryName(removeSpecialChars(categoryItem.name));
      }
    });
  };

  const goMachines = (IdEquipamentMachines: string) => {
    machinesTypes
      .filter(
        (machineTypeFilter: MachineTypes) =>
          machineTypeFilter.categoryId === category,
      )
      .map((machineTypeFilterItem: MachineTypes) => {
        if (machineTypeFilterItem.id === IdEquipamentMachines) {
          window.location.pathname = `/categorias/${categoryName}/${removeSpecialChars(
            machineTypeFilterItem.name,
          )}`;
        }
      });
  };

  return (
    <>
      {!loadingMachines ? (
        <>
          <PagePathManual {...{ link, subLink, subSubLink: '' }} />
          <CategorySlick />
          <Row>
            <Col className="machines-panel" md={3}>
              <Card>
                <Card.Body>
                  <h4>Filtrar por:</h4>
                  <hr />
                  <Form.Label>Categoria</Form.Label>
                  <SelectSearch
                    options={getCategoriesOptions()}
                    value={category}
                    placeholder="Selecione uma categoria..."
                    search
                    onChange={event => {
                      selectCategory(String(event));
                      // clearFields();
                    }}
                  />
                  <Form.Label>Equipamento/Máquinas</Form.Label>
                  <SelectSearch
                    options={getMachinesTypesOptions()}
                    value={machineType}
                    placeholder="Selecione um equipamento/máquina..."
                    // search
                    disabled={categoryName === ''}
                    onChange={event => {
                      handleMachineType(String(event));
                      goMachines(String(event));
                    }}
                  />
                  <hr />
                  <FormControl
                    type="text"
                    value={filter}
                    className="ml-sm-2 mr-sm-2"
                    placeholder="Digite aqui o modelo..."
                    onChange={event => {
                      handleFilter(event.target.value);
                      handleFilteredItems(
                        filterItems(event.target.value, filteredItems),
                      );
                    }}
                  />
                  <div className="filter-section">
                    <Card.Title
                      onClick={() => {
                        handleOpenMinimumLeaseTerm(!openMinimumLeaseTerm);
                        handleOpenLocation(false);
                        handleOpenOperation(false);
                        clearFilters();
                      }}
                      aria-controls="example-collapse-text-b"
                      aria-expanded={openMinimumLeaseTerm}
                    >
                      Prazo mínimo de locação{' '}
                      <div className="float-right">
                        <FontAwesome name="angle-down" type="fas" />
                      </div>
                    </Card.Title>
                    <Collapse in={openMinimumLeaseTerm}>
                      <div id="example-collapse-text-b">
                        <Row>
                          {minimumLeaseTermItems.map((minLeaseTerm: number) => (
                            <Card.Text key={minLeaseTerm}>
                              {minLeaseTerm > 1
                                ? `
                                ${minLeaseTerm} meses
                                `
                                : `${minLeaseTerm} mês`}
                              <input
                                type="radio"
                                id="radioItem"
                                name="radioItem"
                                value={minimumLeaseTerm}
                                checked={minimumLeaseTerm === minLeaseTerm}
                                onChange={() => {
                                  handleMinimumLeaseTerm(minLeaseTerm);
                                  handleFilteredItems(
                                    machines.filter(
                                      (machine: MachineItem) =>
                                        machine.minimumLeaseTerm ===
                                        minLeaseTerm,
                                    ),
                                  );
                                }}
                              />
                            </Card.Text>
                          ))}
                        </Row>
                      </div>
                    </Collapse>
                  </div>
                  <div className="filter-section">
                    <Card.Title
                      onClick={() => {
                        handleOpenOperation(!openOperation);
                        handleOpenMinimumLeaseTerm(false);
                        handleOpenLocation(false);
                        clearFilters();
                      }}
                      aria-controls="example-collapse-textc"
                      aria-expanded={openOperation}
                    >
                      Operação{' '}
                      <div className="float-right">
                        <FontAwesome name="angle-down" type="fas" />
                      </div>
                    </Card.Title>
                    <Collapse in={openOperation}>
                      <div id="example-collapse-textc">
                        <Row>
                          <Card.Text>
                            Com operador
                            <input
                              type="radio"
                              id="radioItem"
                              name="radioItem"
                              value={operation}
                              checked={operation === 1}
                              onChange={() => {
                                handleOperation(1);
                                handleFilteredItems(
                                  machines.filter(
                                    (machine: MachineItem) =>
                                      machine.operation === true,
                                  ),
                                );
                              }}
                            />
                          </Card.Text>
                          <Card.Text>
                            Sem operador
                            <input
                              type="radio"
                              id="radioItem"
                              name="radioItem"
                              value={operation}
                              checked={operation === 2}
                              onChange={() => {
                                handleOperation(2);
                                handleFilteredItems(
                                  machines.filter(
                                    (machine: MachineItem) =>
                                      machine.operation === false,
                                  ),
                                );
                              }}
                            />
                          </Card.Text>
                        </Row>
                      </div>
                    </Collapse>
                  </div>
                  <div className="filter-section">
                    <Card.Title
                      onClick={() => {
                        handleOpenLocation(!openLocation);
                        handleOpenMinimumLeaseTerm(false);
                        handleOpenOperation(false);
                        clearFilters();
                      }}
                      aria-controls="example-collapse-text-d"
                      aria-expanded={openLocation}
                    >
                      Localidade{' '}
                      <div className="float-right">
                        <FontAwesome name="angle-down" type="fas" />
                      </div>
                    </Card.Title>
                    <Collapse in={openLocation}>
                      <div id="example-collapse-text-d">
                        <Row>
                          {stateItems.map((stateItem: string) => (
                            <Card.Text key={stateItem}>
                              {stateItem}
                              <input
                                type="radio"
                                id="radioItem"
                                name="radioItem"
                                value={state}
                                checked={state === stateItem}
                                onChange={() => {
                                  handleState(stateItem);
                                  handleFilteredItems(
                                    machines.filter(
                                      (machine: MachineItem) =>
                                        machine.deliveryRadius.filter(
                                          (deliveryState: DeliveryState) =>
                                            deliveryState.state === stateItem,
                                        )[0],
                                    ),
                                  );
                                }}
                              />
                            </Card.Text>
                          ))}
                        </Row>
                      </div>
                    </Collapse>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col className="mr-3 machines-items">
              {filteredItems.length > 0 ? (
                <Row>
                  {filteredItems.map((machine: MachineItem) => (
                    <Col
                      className="collum-machine"
                      key={machine.id}
                      xs={6}
                      sm={6}
                      md={6}
                      lg={4}
                    >
                      <Link
                        to={`/categorias/${URI[1]}/${
                          URI[2]
                        }/${removeSpecialChars(machine.modelId as any)}`}
                      >
                        {categorySelect && (
                          <Machine
                            {...machine}
                            image={categorySelect?.background}
                          />
                        )}
                      </Link>
                    </Col>
                  ))}
                </Row>
              ) : (
                <Row className="justify-content mt-5">
                  <h5>No momento não possuímos máquinas desse tipo!</h5>
                </Row>
              )}
            </Col>
          </Row>
          <Footer />
        </>
      ) : (
        <Spinner
          className="spinner-loading"
          as="span"
          animation="border"
          role="status"
          aria-hidden="true"
        />
      )}
    </>
  );
};

export default MachinesPanel;
