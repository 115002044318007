import React, { useState, useEffect } from 'react';
import './styles.scss';
import { Card, Row, Col, Button, Spinner } from 'react-bootstrap';
import { PlansType } from '../../../services/types';
import { getPlans } from '../../../services/queries';

interface Props {
  selectedPlan?: any;
}

const Plans: React.FC<Props> = (props: Props) => {
  const { selectedPlan } = props;
  const [plansList, handlePlansList] = useState<PlansType[]>();

  useEffect(() => {
    getPlans()
      .then(response => {
        handlePlansList(response.data.data.plans);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  const handlePlan = (plan: PlansType) => {
    selectedPlan(plan);
  };

  return (
    <div className="container-plans">
      <Row className="row-description">
        <Col>
          <h2>Apresente sua Frota na MPC</h2>
          <p>
            Escolha um plano de assinatura, insira as informações dos seus
            equipamentos e receba demandas de locação, serviços ou compra.
          </p>
          <h3>Pague apenas quando receber a primeira demanda!</h3>
        </Col>
      </Row>
      <Row className="justify-content">
        {plansList && plansList?.length !== 0 ? (
          <>
            {plansList.map((plan: PlansType) => (
              <Col
                key={plan.name}
                className="justify-content"
                xs={12}
                sm={6}
                md={4}
                lg={3}
              >
                <Card className="plan">
                  <Card.Body>
                    <h3> {plan.name} </h3>
                    <p dangerouslySetInnerHTML={{ __html: plan.description }} />
                    <h4>
                      {' '}
                      {Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      }).format(Number(plan.value))}{' '}
                    </h4>
                    <Button type="submit" onClick={() => handlePlan(plan)}>
                      Assinar {plan.name}
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </>
        ) : (
          <>
            <Spinner
              as="span"
              animation="border"
              role="status"
              aria-hidden="true"
            />
          </>
        )}
      </Row>
    </div>
  );
};

export default Plans;
