import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Header from '../../components/Header';
import PagePath from '../../components/PagePath';
import CartTable from '../../components/CartTable';
import Footer from '../../components/Footer';
import CartCredential from '../../components/CartCredential';

// import { Container } from './styles';

const Cart: React.FC = () => (
  <>
    <Header />
    <PagePath />
    <section className="fade-in">
      <Row>
        <Col xs={12} sm={12} md={12} lg={4}>
          <CartCredential />
        </Col>
        <Col xs={12} sm={12} md={12} lg={8}>
          <CartTable />
        </Col>
      </Row>
    </section>
    <Footer />
  </>
);

export default Cart;
