import React from 'react';
import Header from '../../components/Header';
import PagePath from '../../components/PagePath';
import Footer from '../../components/Footer';
import InfoMPC from '../../components/InfoMPC';

const DescriptionMPC: React.FC = () => {
  return (
    <>
      <Header />
      <PagePath />
      <section className="fade-in">
        <InfoMPC />
      </section>
      <Footer />
    </>
  );
};

export default DescriptionMPC;
