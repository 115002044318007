import React from 'react';
import Header from '../../components/Header';
import PagePath from '../../components/PagePath';
import CreateAccountRental from '../../components/CreateAccountRental';
import Footer from '../../components/Footer';

const RegisterRentalCompany: React.FC = () => (
  <>
    <Header />
    <PagePath />
    <CreateAccountRental />
    <Footer />
  </>
);

export default RegisterRentalCompany;
