/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import './styles.scss';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import InputMask from 'react-input-mask';
import { useDispatch } from 'react-redux';
import { toggleModalTerms } from '../../../store/ducks/modals/actions';
import TermsAndPoliticModal from '../../TermsAndPoliticModal';

interface Props {
  goBack(): any;
  selectFormPhase02: any;
}

interface FormPhase02 {
  ownerName: string;
  ownerEmail: string;
  ownerPhone: string;
  ownerWhatsApp: string;
  email: string;
  password: string;
  acceptanceTermsOfUse: boolean;
}

const Phase02: React.FC<Props> = props => {
  const { goBack, selectFormPhase02 } = props;
  const dispatch = useDispatch();

  const storageForm: FormPhase02 = JSON.parse(
    sessionStorage.getItem('@MPC/form') as any,
  );
  const [confirmPassword, handleConfirmPassword] = useState('');
  const [formPhase02, handleFormPhase02] = useState<FormPhase02>({
    ownerName: '',
    ownerEmail: '',
    ownerPhone: '',
    ownerWhatsApp: '',
    email: '',
    password: '',
    acceptanceTermsOfUse: false,
  });

  useEffect(() => {
    if (storageForm) {
      handleFormPhase02({
        ...formPhase02,
        ownerName: storageForm.ownerName,
        ownerEmail: storageForm.ownerEmail,
        ownerPhone: storageForm.ownerPhone,
        ownerWhatsApp: storageForm.ownerWhatsApp,
        email: storageForm.email,
        password: storageForm.password,
        acceptanceTermsOfUse: storageForm.acceptanceTermsOfUse,
      });
    }
  }, []);

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    if (
      formPhase02.ownerEmail !== '' &&
      formPhase02.ownerName !== '' &&
      formPhase02.ownerPhone !== '' &&
      formPhase02.ownerWhatsApp !== '' &&
      formPhase02.email !== '' &&
      formPhase02.password !== ''
    ) {
      if (formPhase02.acceptanceTermsOfUse) {
        if (formPhase02.password === confirmPassword) {
          if (formPhase02.password.length > 6) {
            selectFormPhase02(formPhase02);
          } else {
            toast.error('Senha muito curta, deverá ter mais de 6 caracteres');
          }
        } else {
          toast.error('As senhas não coincidem');
        }
      } else {
        toast.error('Por favor, aceite os termos de uso');
      }
    } else {
      toast.error('Por favor, preencha todos on campos');
    }
  };

  return (
    <>
      <Row className="row-description">
        <Col>
          <h5>
            O próximo passo consistem em informar quem o responsável por
            utilizar o Máquinas Para Construção e cadastrar os seus dados de
            acesso.
          </h5>
        </Col>
      </Row>
      <Row className="row-count-phases">
        <p>Etapa 02 de 03</p>
      </Row>
      <Row className="row-form-phase02">
        <Form onSubmit={(event: React.FormEvent) => onSubmit(event)}>
          <Row>
            <Col xl={6}>
              <h5>Dados do responsável</h5>
              <Form.Label>Nome do responsável</Form.Label>
              <Form.Control
                type="text"
                value={formPhase02.ownerName}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleFormPhase02({
                    ...formPhase02,
                    ownerName: event.target.value,
                  })
                }
              />
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                value={formPhase02.ownerEmail}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleFormPhase02({
                    ...formPhase02,
                    ownerEmail: event.target.value,
                  })
                }
              />
              <Row className="row-phone-wpp">
                <Col style={{ paddingLeft: 0 }}>
                  <Form.Label>Telefone</Form.Label>
                  <Form.Control
                    as={InputMask}
                    type="text"
                    mask="(99) 99999-9999"
                    maskChar="_"
                    value={formPhase02.ownerPhone}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      handleFormPhase02({
                        ...formPhase02,
                        ownerPhone: event.target.value.replace(/[^0-9]/g, ''),
                      })
                    }
                  />
                </Col>
                <Col style={{ paddingRight: 0 }}>
                  <Form.Label>WhatsApp</Form.Label>
                  <Form.Control
                    as={InputMask}
                    type="text"
                    mask="(99) 99999-9999"
                    maskChar="_"
                    value={formPhase02.ownerWhatsApp}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      handleFormPhase02({
                        ...formPhase02,
                        ownerWhatsApp: event.target.value.replace(
                          /[^0-9]/g,
                          '',
                        ),
                      })
                    }
                  />
                </Col>
              </Row>
            </Col>
            <Col xl={6}>
              <h5>Dados de acesso</h5>
              <Form.Label>Email de login</Form.Label>
              <Form.Control
                type="email"
                value={formPhase02.email}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleFormPhase02({
                    ...formPhase02,
                    email: event.target.value,
                  })
                }
              />
              <Form.Label>Senha</Form.Label>
              <Form.Control
                type="password"
                value={formPhase02.password}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleFormPhase02({
                    ...formPhase02,
                    password: event.target.value,
                  })
                }
              />
              <Form.Label>Confirmar senha</Form.Label>
              <Form.Control
                type="password"
                value={confirmPassword}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleConfirmPassword(event.target.value)
                }
              />
            </Col>
          </Row>
          <Row className="row-agree">
            <Form.Check
              type="checkbox"
              checked={formPhase02.acceptanceTermsOfUse}
              onChange={() =>
                handleFormPhase02({
                  ...formPhase02,
                  acceptanceTermsOfUse: !formPhase02.acceptanceTermsOfUse,
                })
              }
            />
            <h5>
              Declaro que li e concordo com todos os termos da plataforma
              Máquinas para Construção. Termos disponíveis{' '}
              <button
                className="button-a"
                type="button"
                onClick={() => dispatch(toggleModalTerms('terms'))}
              >
                clicando aqui
              </button>{' '}
              e política de privacidade{' '}
              <button
                className="button-a"
                type="button"
                onClick={() => dispatch(toggleModalTerms('politic'))}
              >
                clicando aqui
              </button>
              .
            </h5>
          </Row>
          <Row className="row-buttons">
            <Button className="btn-clear" onClick={goBack}>
              Voltar etapa
            </Button>
            <Button type="submit">Revisar Cadastro</Button>
          </Row>
        </Form>
      </Row>
      <TermsAndPoliticModal />
    </>
  );
};

export default Phase02;
