import React, { useState } from 'react';
import { Modal, Card, Col, Row, Button, Spinner } from 'react-bootstrap';

import './styles.scss';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from '../../store';
import { toggleModalConfirm } from '../../store/ducks/modals/actions';
import {
  getRentalCompany,
  getRentalCompanyQuotations,
} from '../../services/queries';
import { getRentalCompanyToken } from '../../services/auth';
import {
  removeRentalCompany,
  updateRentalCompany,
} from '../../store/ducks/rentalCompany/actions';
import { deleteMachine, deleteDeliveryState } from '../../services/mutations';
import { checkMachine, checkDeliveryState } from '../../services/validation';

interface Props {
  confirmationType: string;
}

const ConfirmationModal: React.FC<Props> = (props: Props) => {
  const modalsState = useSelector((state: ApplicationState) => state.modals);

  const token = getRentalCompanyToken();

  const { statusConfirm, machine, deliveryState } = modalsState;

  const dispatch = useDispatch();

  const [loading, handleLoading] = useState(false);

  const { confirmationType } = props;

  const handleClose = () => {
    dispatch(
      toggleModalConfirm({
        id: '',
        categoryId: '',
        city: '',
        createdAt: '',
        deliveryRadius: [],
        rentalCompanyId: '',
        state: '',
        machineTypeId: '',
        model: '',
        fabricationYear: 0,
        minimumLeaseTerm: 0,
        description: '',
        comments: '',
        image: '',
        operation: false,
        onSale: false,
        onRent: false,
        onService: false,
        manufacturerId: '',
        manufacturer: '',
        operationalCapacityReferential: '',
        operationalCapacityQuantitative: '',
        operationalCapacityUnit: '',
        equipamentMachineId: '',
        equipamentMachine: '',
      }),
    );
    return false;
  };

  const updateStore = () => {
    getRentalCompany(token)
      .then(resp => {
        const rentalCompanyTemp = resp.data.data.rentalCompany.rentalCompany;
        if (rentalCompanyTemp) {
          getRentalCompanyQuotations(token)
            .then(result => {
              dispatch(
                updateRentalCompany({
                  rentalCompany: rentalCompanyTemp,
                  user: {
                    role: 'RENTAL',
                    token,
                  },
                  rentalCompanyQuotations:
                    result.data.data.rentalCompanyQuotations,
                }),
              );
            })
            .catch(error => {
              console.log('error', error);
            });
        } else {
          dispatch(removeRentalCompany());
        }
      })
      .catch(error => {
        console.log('error', error);
        dispatch(removeRentalCompany());
      });
  };

  const removeMachine = () => {
    handleLoading(true);
    deleteMachine(machine.id, token)
      .then(response => {
        if (checkMachine('delete', response.data.data.deleteMachine)) {
          updateStore();
          handleLoading(false);
          handleClose();
        }
      })
      .catch(error => {
        console.log('error', error);
        checkMachine('delete', '');
        handleLoading(false);
      });
  };

  const removePlace = () => {
    handleLoading(true);
    deleteDeliveryState(deliveryState.id, token)
      .then(response => {
        if (
          checkDeliveryState('delete', response.data.data.deleteDeliveryState)
        ) {
          updateStore();
          handleLoading(false);
          handleClose();
        }
      })
      .catch(error => {
        console.log('error', error);
        checkDeliveryState('error', 'error');
        handleLoading(false);
      });
  };
  const renderSwitch = () => {
    switch (confirmationType) {
      case 'remove-machine':
        return (
          <>
            <Modal.Header closeButton>
              <h5>Confirmar exclusão</h5>
            </Modal.Header>
            <Modal.Body>
              Excluir{' '}
              <b>
                máquina {machine.id} -{machine.model}
              </b>
              ?
              <Row>
                {!loading ? (
                  <>
                    <Col xs={6}>
                      <Button className="btn-clear" onClick={handleClose}>
                        Cancelar
                      </Button>
                    </Col>
                    <Col xs={6}>
                      <Button className="btnToggle" onClick={removeMachine}>
                        Confirmar
                      </Button>
                    </Col>
                  </>
                ) : (
                  <Col xs={12} className="justify-content mt-4">
                    <Spinner
                      as="span"
                      animation="border"
                      role="status"
                      aria-hidden="true"
                    />
                  </Col>
                )}
              </Row>
            </Modal.Body>
          </>
        );
      case 'remove-place':
        return (
          <>
            <Modal.Header closeButton>
              <h5>Confirmar exclusão</h5>
            </Modal.Header>
            <Modal.Body>
              Excluir{' '}
              <b>
                localidade {deliveryState.id} -{deliveryState.state}
              </b>
              ?
              <Row>
                {!loading ? (
                  <>
                    <Col xs={6}>
                      <Button className="btn-clear" onClick={handleClose}>
                        Cancelar
                      </Button>
                    </Col>
                    <Col xs={6}>
                      <Button className="btnToggle" onClick={removePlace}>
                        Confirmar
                      </Button>
                    </Col>
                  </>
                ) : (
                  <Col xs={12} className="justify-content mt-4">
                    <Spinner
                      as="span"
                      animation="border"
                      role="status"
                      aria-hidden="true"
                    />
                  </Col>
                )}
              </Row>
            </Modal.Body>
          </>
        );
      default:
        break;
    }
    return true;
  };

  return (
    <Modal
      show={statusConfirm}
      onHide={handleClose}
      aria-labelledby="contained-confirmation-modal"
      className="confirmation-modal"
      dialogClassName="confirmation-modal"
      id="confirmation-modal"
      size="sm"
      centered
    >
      <Card>
        <Col xs={12}>{renderSwitch()}</Col>
      </Card>
    </Modal>
  );
};

export default ConfirmationModal;
