import { toast } from 'react-toastify';

export const checkAdvancedSearch = (validationCase: string, value: string) => {
  let validation = false;
  switch (validationCase) {
    case 'category':
      if (value === '') {
        toast.error('Selecione uma categoria!');
        validation = false;
        return false;
      }
      validation = true;
      return true;

    case 'machineType':
      if (value === '') {
        toast.error('Selecione um tipo de máquinas!');
        validation = false;
        return false;
      }
      validation = true;
      return true;

    case 'model':
      if (value === '') {
        toast.error('Selecione um modelo!');
        validation = false;
        return false;
      }
      validation = true;
      return true;

    case 'period':
      if (value === '0' || value === '') {
        toast.error('Insira o período de locação!');
        validation = false;
        return false;
      }
      validation = true;
      return true;

    case 'quantity':
      if (value === '0' || value === '') {
        toast.error('Insira a quantidade!');
        validation = false;
        return false;
      }
      validation = true;
      return true;

    case 'state':
      if (value === '') {
        toast.error('Insira um Estado!');
        validation = false;
        return false;
      }
      validation = true;
      return true;

    case 'response':
      if (value === '' || undefined) {
        toast.error('Houve algum erro. Tente novamente mais tarde!');
        validation = false;
        return false;
      }
      toast.success('Orçamento solicitado com sucesso!');
      validation = true;
      return true;
    default:
      break;
  }
  return validation;
};

export const checkAccount = (validationCase: string, value: string) => {
  let validation = false;

  switch (validationCase) {
    case 'companyName':
    case 'corporateName':
      if (value === '') {
        toast.error('Insira o nome / razão social!');
        validation = false;
        return false;
      }
      validation = true;
      return true;

    case 'ownerName':
      if (value === '') {
        toast.error('Insira o nome do responsável');
        validation = false;
        return false;
      }
      validation = true;
      return true;

    case 'cnpjOrCpf':
      if (value === '') {
        toast.error('Insira um CPF/CNPJ!');
        validation = false;
        return false;
      }
      if (value.length < 11) {
        toast.error('CPF/CNPJ deve conter no mínimo 11 caracteres!');
        validation = false;
        return false;
      }
      if (value.length > 14) {
        toast.error('CPF/CNPJ deve conter no máximo 14 caracteres!');
        validation = false;
        return false;
      }
      validation = true;
      return true;

    case 'address':
      if (value === '') {
        toast.error('Insira um Endereço!');
        validation = false;
        return false;
      }
      validation = true;
      return true;

    case 'city':
      if (value === '') {
        toast.error('Insira uma cidade!');
        validation = false;
        return false;
      }
      validation = true;
      return true;

    case 'state':
      if (value === '') {
        toast.error('Selecione um estado!');
        validation = false;
        return false;
      }
      validation = true;
      return true;

    case 'description':
      if (value === '') {
        toast.error('Insira uma descrição!');
        validation = false;
        return false;
      }
      validation = true;
      return true;

    case 'phone':
      if (value === '') {
        toast.error('Insira um número de telefone!');
        validation = false;
        return false;
      }
      if (value.length < 10) {
        toast.error('Telefone inválido!');
        validation = false;
        return false;
      }
      validation = true;
      return true;

    case 'whatsApp':
      if (value === '') {
        toast.error('Insira um número de WhatsApp!');
        validation = false;
        return false;
      }
      if (value.length !== 11) {
        toast.error('WhatsApp inválido!');
        validation = false;
        return false;
      }
      validation = true;
      return true;

    case 'email':
      if (value === '') {
        toast.error('Insira um E-mail!');
        validation = false;
        return false;
      }
      if (!validateEmail(value)) {
        toast.error('E-mail inválido!');
        validation = false;
        return false;
      }
      validation = true;
      return true;

    case 'password':
      if (value === '') {
        toast.error('Insira uma senha!');
        validation = false;
        return false;
      }
      if (value.length < 7) {
        toast.error('A senha deve conter no mínimo 7 caracteres!');
        validation = false;
        return false;
      }
      validation = true;
      return true;

    case 'confirmPassword':
      if (value === '') {
        toast.error('Confirme sua senha!');
        validation = false;
        return false;
      }
      validation = true;
      return true;

    case 'response':
      if (value === '' || undefined) {
        toast.error('Houve algum erro. Tente novamente mais tarde!');
        validation = false;
        return false;
      }
      toast.success('Conta criada com sucesso! Faça seu login.');
      validation = true;
      return true;

    case 'update':
      if (value === '' || undefined) {
        toast.error('Houve algum erro. Tente novamente mais tarde!');
        validation = false;
        return false;
      }
      toast.success(value);
      validation = true;
      return true;

    case 'error':
      if (value === 'error' || undefined) {
        toast.error('Houve algum erro. Tente novamente mais tarde!');
        validation = false;
        return false;
      }
      toast.success(value);
      validation = true;
      return true;

    default:
      break;
  }
  return validation;
};

export const checkPassword = (password: string, confirmPassword: string) => {
  const validate = password === confirmPassword;
  if (validate) {
    return true;
  }
  toast.error('Senhas não coincidem');
  return false;
};

export const checkMachine = (validationCase: string, value: string) => {
  let validation = false;

  switch (validationCase) {
    case 'machineTypeId':
      if (value === '') {
        toast.error('Selecione um tipo de máquina!');
        validation = false;
        return false;
      }
      validation = true;
      return true;

    case 'fabricationYear':
      if (value === '') {
        toast.error('Insira um ano de fabricação!');
        validation = false;
        return false;
      }
      if (Number(value) > 2020 || Number(value) < 1500) {
        toast.error('Insira um ano de fabricação válido!');
        validation = false;
        return false;
      }
      validation = true;
      return true;

    case 'minimumLeaseTerm':
      if (value === '') {
        toast.error('Insira um período mínimo de locação!');
        validation = false;
        return false;
      }
      if (Number(value) > 100 || Number(value) < 1) {
        toast.error('Insira um período de locação mínimo válido!');
        validation = false;
        return false;
      }
      validation = true;
      return true;

    case 'model':
      if (value === '') {
        toast.error('Insira um modelo!');
        validation = false;
        return false;
      }
      validation = true;
      return true;

    case 'image':
      if (value === 'error') {
        toast.error('Insira uma imagem!');
        validation = false;
        return false;
      }
      validation = true;
      return true;

    case 'description':
      if (value === '') {
        toast.error('Insira uma descrição!');
        validation = false;
        return false;
      }
      validation = true;
      return true;

    case 'comments':
      if (value === '') {
        toast.error('Insira as observações!');
        validation = false;
        return false;
      }
      validation = true;
      return true;

    case 'create':
      if (value === '') {
        toast.error(
          'Ocorreu algum erro na criação! Tente novamente mais tarde.',
        );
        validation = false;
        return false;
      }
      toast.success(value);
      validation = true;
      return true;

    case 'update':
      if (value === '') {
        toast.error(
          'Ocorreu algum erro na atualização! Tente novamente mais tarde.',
        );
        validation = false;
        return false;
      }
      toast.success(value);
      validation = true;
      return true;

    case 'delete':
      if (value === '') {
        toast.error(
          'Ocorreu algum erro na remoção! Tente novamente mais tarde.',
        );
        validation = false;
        return false;
      }
      toast.success(value);
      validation = true;
      return true;

    default:
      break;
  }
  return validation;
};

export const checkDeliveryState = (validationCase: string, value: string) => {
  let validation = false;
  switch (validationCase) {
    case 'state':
      if (value === '') {
        toast.error('Selecione um estado!');
        validation = false;
        return false;
      }
      validation = true;
      return true;

    case 'create':
      if (value === '') {
        toast.error(
          'Ocorreu algum erro na criação! Tente novamente mais tarde.',
        );
        validation = false;
        return false;
      }
      toast.success(value);
      validation = true;
      return true;

    case 'update':
      if (value === '') {
        toast.error(
          'Ocorreu algum erro na atualização! Tente novamente mais tarde.',
        );
        validation = false;
        return false;
      }
      toast.success(value);
      validation = true;
      return true;

    case 'delete':
      if (value === '') {
        toast.error(
          'Ocorreu algum erro na remoção! Tente novamente mais tarde.',
        );
        validation = false;
        return false;
      }
      toast.success(value);
      validation = true;
      return true;

    default:
      break;
  }
  return validation;
};

export const checkError = (errors: { message: string; path: [string] }[]) => {
  if (errors === undefined) {
    return true;
  }
  if (errors.length === 0) {
    return true;
  }
  toast.error(
    `${errors.map(
      (error: { message: string; path: [string] }) => error.message,
    )}`,
  );
  return false;
};

export const checkFile = (validationCase: string, value: string) => {
  let validation = false;
  switch (validationCase) {
    case 'create':
      if (value === '') {
        toast.error(
          'Ocorreu algum erro no arquivo! Tente novamente mais tarde.',
        );
        validation = false;
        return false;
      }
      toast.success(value);
      validation = true;
      return true;

    case 'error':
      toast.error('Ocorreu algum erro no arquivo! Tente novamente mais tarde.');
      validation = false;
      return false;

    case 'file':
    case 'machine-file':
      if (Number(value) > 1e7) {
        toast.error('Tamanho de arquivo inválido (max. 10mb)');
        validation = false;
        return false;
      }
      toast.success('Arquivo Ok');
      validation = true;
      return true;

    case 'user-file':
      if (Number(value) > 1e6) {
        toast.error('Tamanho de arquivo inválido (max. 1mb)');
        validation = false;
        return false;
      }
      toast.success('Arquivo Ok');
      validation = true;
      return true;

    default:
      break;
  }
  return validation;
};

export const checkAuth = (validationCase: string, value: string) => {
  let validation = false;
  switch (validationCase) {
    case 'email':
      if (value !== '' && validateEmail(value) === false) {
        toast.error('Email inválido!');
        validation = false;
        return false;
      }
      validation = true;
      return true;
    case 'password':
      if (value === '') {
        toast.error('Preencha a senha!');
        validation = false;
        return false;
      }
      validation = true;
      return true;

    case 'auth':
      if (value === 'Senha Incorreta') {
        toast.error(value);
        validation = false;
        return false;
      }
      if (value === 'record not found') {
        toast.error('Email não cadastrado');
        validation = false;
        return false;
      }
      if (value === null || value === undefined) {
        toast.error('Cadastro não localizado');
        validation = false;
        return false;
      }
      validation = true;
      return true;
    default:
      break;
  }
  return validation;
};

export const checkContact = (validationCase: string, value: string) => {
  let validation = false;
  switch (validationCase) {
    case 'name':
      if (value === '') {
        toast.error('Insira seu nome!');
        validation = false;
        return false;
      }
      validation = true;
      return true;

    case 'email':
      if (value === '') {
        toast.error('Insira seu e-mail!');
        validation = false;
        return false;
      }
      if (value !== '' && validateEmail(value) === false) {
        toast.error('Email inválido!');
        validation = false;
        return false;
      }
      validation = true;
      return true;

    case 'subject':
      if (value === '') {
        toast.error('Insira um assunto!');
        validation = false;
        return false;
      }
      validation = true;
      return true;

    case 'message':
      if (value === '') {
        toast.error('Insira uma mensagem!');
        validation = false;
        return false;
      }
      validation = true;
      return true;

    case 'create':
      if (value === '') {
        toast.error(
          'Ocorreu algum erro na mensagem! Tente novamente mais tarde.',
        );
        validation = false;
        return false;
      }
      toast.success(value);
      validation = true;
      return true;

    default:
      break;
  }
  return validation;
};

export const validateCpf = (cpf: string) => {
  let sum;
  let rest;
  sum = 0;
  if (cpf === '00000000000') return false;

  // eslint-disable-next-line no-plusplus
  for (let i = 1; i <= 9; i++) {
    sum += Number(cpf.substring(i - 1, i)) * (11 - i);
  }
  rest = (sum * 10) % 11;

  if (rest === 10 || rest === 11) rest = 0;
  if (rest !== Number(cpf.substring(9, 10))) return false;

  sum = 0;
  // eslint-disable-next-line no-plusplus
  for (let i = 1; i <= 10; i++) {
    sum += Number(cpf.substring(i - 1, i)) * (12 - i);
  }
  rest = (sum * 10) % 11;

  if (rest === 10 || rest === 11) rest = 0;
  if (rest !== Number(cpf.substring(10, 11))) return false;
  return true;
};

export const validateEmail = (email: string) => {
  const re = /[\w'+-]+(\.[\w'+-]+)*@\w+([-.]\w+)*\.\w{2,24}/;
  return re.test(String(email).toLowerCase());
};
