/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';

import './styles.scss';
import { Col, Card, Form, Button, Container, Row } from 'react-bootstrap';
import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-v3';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { checkAccount, checkPassword } from '../../services/validation';
import { createConstructionCompany } from '../../services/mutations';
import { ConstructionCompany } from '../../store/ducks/constructionCompany/types';
import { toggleModalTerms } from '../../store/ducks/modals/actions';
import TermsAndPoliticModal from '../TermsAndPoliticModal';

const CreateAccount: React.FC = () => {
  const dispatch = useDispatch();

  const verifyCallback = (recaptchaToken: string) => {
    console.log('recaptchaToken', recaptchaToken);
  };
  const recaptcha = useRef(null);

  useEffect(() => {
    loadReCaptcha(process.env.REACT_APP_RECAPTCHA_KEY || '');
  }, [process.env.REACT_APP_RECAPTCHA_KEY || '']);

  const [render, handleRender] = useState(0);
  const [constructionCompany, handleConstructionCompany] = useState<
    ConstructionCompany | any
  >({
    id: '',
    email: '',
    companyName: '',
    cnpjOrCpf: '',
    image: '',
    ownerName: '',
    ownerEmail: '',
    ownerPhone: '',
    ownerWhatsApp: '',
    acceptanceTermsOfUse: false,
  });
  const [password, handlePassword] = useState('');
  const [confirmPassword, handleConfirmPassword] = useState('');

  const {
    companyName,
    cnpjOrCpf,
    email,
    acceptanceTermsOfUse,
  } = constructionCompany;

  const register = (event: React.FormEvent) => {
    event.preventDefault();
    if (
      checkAccount('companyName', companyName) &&
      checkAccount('cnpjOrCpf', cnpjOrCpf) &&
      // && checkAccount('address', address)
      // && checkAccount('phone', phone)
      // && checkAccount('whatsApp', whatsApp)
      checkAccount('email', email) &&
      checkAccount('password', password) &&
      checkAccount('confirmPassword', confirmPassword) &&
      checkPassword(password, confirmPassword)
    ) {
      if (recaptcha.current !== undefined) {
        (recaptcha as any).current.execute();
      }
      if (acceptanceTermsOfUse) {
        createConstructionCompany(constructionCompany, password).then(
          response => {
            checkAccount(
              'response',
              response.data.data.createConstructionCompany,
            );
          },
        );
      } else {
        toast.error('Aceite os termos de serviço para se cadastrar');
      }
    }
  };

  const renderComponent = () => {
    switch (render) {
      case 0:
        return (
          <Card>
            <Card.Body>
              <Card.Text>
                Aqui você pode encontrar todo maquinário necessário para a sua
                empresa. Crie sua conta agora mesmo!
              </Card.Text>
              <Button type="submit" onClick={() => handleRender(1)}>
                Criar conta
              </Button>
            </Card.Body>
          </Card>
        );
      case 1:
        return (
          <Card>
            <Card.Body>
              {/* <Card.Text>Dados da empresa</Card.Text> */}
              <Form onSubmit={(event: React.FormEvent) => register(event)}>
                <Row>
                  <Col xs={12} md={12} sm={12} lg={6}>
                    <Form.Group>
                      <Form.Label>Nome/Razão Social</Form.Label>
                      <Form.Control
                        type="text"
                        value={companyName}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>,
                        ) => {
                          handleConstructionCompany({
                            ...constructionCompany,
                            companyName: event.target.value,
                          });
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={12} sm={12} lg={6}>
                    <Form.Group>
                      <Form.Label>
                        CPF/CNPJ <small>(apenas números)</small>
                      </Form.Label>
                      <Form.Control
                        name="cnpjOrCpf"
                        className="form-control"
                        type="number"
                        value={cnpjOrCpf}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>,
                        ) => {
                          handleConstructionCompany({
                            ...constructionCompany,
                            cnpjOrCpf: event.target.value,
                          });
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                {/* <Row>
                  <Col xs={12} md={12} sm={12} lg={12}>
                    <Form.Group>
                      <Form.Label>Endereço</Form.Label>
                      <Form.Control
                        type="text"
                        value={address}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>,
                        ) => {
                          handleAddress(event.target.value);
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row> */}
                {/* <Row>
                  <Col xs={12} md={12} sm={12} lg={6}>
                    <Form.Group>
                      <Form.Label>Telefone</Form.Label>
                      <InputMask
                        mask="(99) 9999-9999"
                        maskChar="_"
                        name="phone"
                        className="form-control"
                        value={phone}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>,
                        ) => handlePhone(event.target.value.replace(/[^0-9]/g, ''))}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={12} sm={12} lg={6}>
                    <Form.Group>
                      <Form.Label>WhatsApp</Form.Label>
                      <InputMask
                        mask="(99) 99999-9999"
                        maskChar="_"
                        name="whatsApp"
                        className="form-control"
                        value={whatsApp}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>,
                        ) => handleWhatsApp(
                          event.target.value.replace(/[^0-9]/g, ''),
                        )}
                      />
                    </Form.Group>
                  </Col>
                </Row> */}
                <Row>
                  <Col xs={12} md={12} sm={12} lg={12}>
                    <Form.Group>
                      <Form.Label>E-mail</Form.Label>
                      <Form.Control
                        type="text"
                        value={email}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>,
                        ) => {
                          handleConstructionCompany({
                            ...constructionCompany,
                            email: event.target.value,
                          });
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={12} sm={12} lg={6}>
                    <Form.Group>
                      <Form.Label>Senha</Form.Label>
                      <Form.Control
                        type="password"
                        value={password}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>,
                        ) => {
                          handlePassword(event.target.value);
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={12} sm={12} lg={6}>
                    <Form.Group>
                      <Form.Label>Confirmar senha</Form.Label>
                      <Form.Control
                        type="password"
                        value={confirmPassword}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>,
                        ) => {
                          handleConfirmPassword(event.target.value);
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col
                    xs={12}
                    md={12}
                    sm={12}
                    lg={12}
                    style={{ display: 'flex' }}
                  >
                    <Form.Check
                      type="checkbox"
                      checked={acceptanceTermsOfUse}
                      onChange={() =>
                        handleConstructionCompany({
                          ...constructionCompany,
                          acceptanceTermsOfUse: !acceptanceTermsOfUse,
                        })
                      }
                    />
                    <h5>
                      Declaro que li e concordo com todos os termos da
                      plataforma Máquinas para Construção. Termos disponíveis{' '}
                      <button
                        className="button-a"
                        type="button"
                        onClick={() => dispatch(toggleModalTerms('terms'))}
                      >
                        clicando aqui
                      </button>{' '}
                      e política de privacidade{' '}
                      <button
                        className="button-a"
                        type="button"
                        onClick={() => dispatch(toggleModalTerms('politic'))}
                      >
                        clicando aqui
                      </button>
                      .
                    </h5>
                  </Col>
                </Row>
                <ReCaptcha
                  sitekey={process.env.REACT_APP_RECAPTCHA_KEY || ''}
                  action="submit"
                  ref={recaptcha}
                  verifyCallback={verifyCallback}
                />
                <Button type="submit">Criar conta</Button>
              </Form>
            </Card.Body>
          </Card>
        );
      default:
        break;
    }
    return 0;
  };

  return (
    <>
      <Container className="create-account" fluid>
        <h2>Criar conta</h2>
        {renderComponent()}
      </Container>
      <TermsAndPoliticModal />
    </>
  );
};

export default CreateAccount;
