import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import MPC from '../MPC';
import Search from '../Search';
import Partners from '../Partners';
import AboutUs from '../AboutUs';
import Machines from '../Machines';
import MachinesTypes from '../MachinesTypes';
import Access, { RentalCompanyAccess } from '../Access';
import MachinesSearch from '../MachinesSearch';
import MachinesDetail from '../MachinesDetail';
import Cart from '../Cart';
import { PrivateRoute, RentalCompanyRoute } from '../_PrivateRoute';
import { ConstructionCompanyProfile, RentalCompanyProfile } from '../Profile';
import Contact from '../Contact';
import DescriptionMPC from '../DescriptionMPC';
import ForgotPassword from '../ForgotPassword';
import RecoverPassword from '../RecoverPassword';
import RegisterRentalCompany from '../RegisterRentalCompany';

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/" component={MPC} />
      <Route exact path="/categorias/:category" component={MachinesTypes} />
      <Route
        exact
        path="/categorias/:category/:machineType"
        component={Machines}
      />
      <Route
        exact
        path="/categorias/:category/:machineType/:machineModel"
        component={MachinesDetail}
      />
      <Route exact path="/orcamento" component={Search} />
      <Route exact path="/busca/:machineModel" component={MachinesSearch} />
      <Route exact path="/parceiros" component={Partners} />
      <Route exact path="/sobre" component={AboutUs} />
      <Route exact path="/login" component={Access} />
      <Route exact path="/recuperar-senha" component={ForgotPassword} />
      <Route exact path="/recuperar-senha/:token" component={RecoverPassword} />
      <Route exact path="/carrinho" component={Cart} />
      <Route exact path="/contato" component={Contact} />
      <Route exact path="/comofunciona" component={DescriptionMPC} />
      <Route exact path="/sejaumlocadormpc" component={RegisterRentalCompany} />
      <PrivateRoute
        exact
        path="/perfil/:option"
        component={ConstructionCompanyProfile}
      />
      <RentalCompanyRoute
        exact
        path="/locadora/:option"
        component={RentalCompanyProfile}
      />
      <Route exact path="/acesso/locadora" component={RentalCompanyAccess} />
      <Redirect from="*" to="/" />
    </Switch>
  );
}
