import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import SendEmail from '../../components/SendEmail';

const ForgotPassword: React.FC = () => (
  <>
    <Header />
    <section className="fade-in">
      <SendEmail />
    </section>
    <Footer />
  </>
);

export default ForgotPassword;
