/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react';

import './styles.scss';
import { Col, Form, Button, Row } from 'react-bootstrap';
import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-v3';
import { checkContact } from '../../services/validation';

import BackgroundImage from '../../images/1598.jpg';
import Footer from '../Footer';
import { createMessage } from '../../services/mutations';

const ContactForm: React.FC = () => {
  const [name, handleName] = useState('');
  const [email, handleEmail] = useState('');
  const [subject, handleSubject] = useState('');
  const [message, handleMessage] = useState('');

  const verifyCallback = (recaptchaToken: string) => {
    // console.log('recaptchaToken', recaptchaToken);
  };
  const recaptcha = useRef(null);

  useEffect(() => {
    loadReCaptcha(process.env.REACT_APP_RECAPTCHA_KEY || '');
  }, [process.env.REACT_APP_RECAPTCHA_KEY || '']);

  const sendMessage = (event: React.FormEvent) => {
    event.preventDefault();

    if (
      checkContact('name', name) &&
      checkContact('email', email) &&
      checkContact('subject', subject) &&
      checkContact('message', message)
    ) {
      if (recaptcha.current !== undefined) {
        (recaptcha as any).current.execute();
      }
      // Enviar a mensagem
      createMessage(name, email, subject, message)
        .then(response => {
          checkContact('create', response.data.data.createMessage);
        })
        .catch(error => {
          console.log('error', error);
          checkContact('create', '');
        });
    }
  };

  return (
    <>
      <section className="contact-form fade-in">
        <Row>
          <Col xs={12} sm={12} md={6} lg={6}>
            <Form onSubmit={(event: React.FormEvent) => sendMessage(event)}>
              <Col xs={12} sm={12} md={12} lg={12}>
                <Form.Group>
                  <Form.Label>Nome</Form.Label>
                  <Form.Control
                    type="text"
                    maxLength={100}
                    value={name}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      handleName(event.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12}>
                <Form.Group>
                  <Form.Label>E-mail</Form.Label>
                  <Form.Control
                    type="text"
                    maxLength={100}
                    value={email}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      handleEmail(event.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12}>
                <Form.Group>
                  <Form.Label>Assunto</Form.Label>
                  <Form.Control
                    type="text"
                    maxLength={100}
                    value={subject}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      handleSubject(event.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
              <Form.Label className="ml-3">Mensagem</Form.Label>
              <Col xs={12} sm={12} md={12} lg={12}>
                <textarea
                  value={message}
                  maxLength={500}
                  onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                    handleMessage(event.target.value);
                  }}
                />
              </Col>
              <ReCaptcha
                sitekey={process.env.REACT_APP_RECAPTCHA_KEY || ''}
                action="submit"
                ref={recaptcha}
                verifyCallback={verifyCallback}
              />
              <Button type="submit">Enviar mensagem</Button>
            </Form>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6}>
            <img
              className="fade-in"
              src={BackgroundImage}
              alt="BackgroundImage"
            />
            <a className="hidden" href="https://br.freepik.com/fotos/negocio">
              Negócio foto criado por onlyyouqj - br.freepik.com
            </a>
          </Col>
        </Row>
      </section>
      <Footer />
    </>
  );
};

export default ContactForm;
