import React, { useEffect } from 'react';

import './styles.scss';

import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Logo from '../../images/logo/logo.png';
import FontAwesome from '../FontAwesome';
import { ApplicationState } from '../../store';
import {
  updateConstructionCompany,
  removeConstructionCompany,
} from '../../store/ducks/constructionCompany/actions';

import { getToken } from '../../services/auth';
import { getConstructionCompany } from '../../services/queries';

const Header: React.FC = () => {
  const constructionCompanyState = useSelector(
    (state: ApplicationState) => state.constructionCompany,
  );

  const cart = useSelector((state: ApplicationState) => state.cart);

  const { items } = cart;

  const { constructionCompany } = constructionCompanyState;
  const dispatch = useDispatch();

  const checkActive = (path: string) => window.location.pathname === `/${path}`;

  const logout = () => {
    dispatch(removeConstructionCompany());
    window.location.href = '/';
  };

  useEffect(() => {
    getConstructionCompany(getToken())
      .then(response => {
        const constructionCompanyTemp =
          response.data.data.constructionCompany.constructionCompany;
        if (constructionCompanyTemp) {
          dispatch(
            updateConstructionCompany({
              constructionCompany: constructionCompanyTemp,
              user: {
                role: 'CONSTRUCTION',
                token: getToken(),
              },
              constructionCompanyQuotations:
                response.data.data.constructionCompany.constructionCompany
                  .quotations,
            }),
          );
        } else {
          dispatch(removeConstructionCompany());
        }
      })
      .catch(error => {
        dispatch(removeConstructionCompany());
      });
    return (document.querySelector(
      '.grecaptcha-badge',
    ) as HTMLInputElement)?.remove();
  }, [dispatch]);

  // console.log('constructionCompany', constructionCompany);

  return (
    <Navbar className="nav-bar" expand="lg">
      <Navbar.Brand href="/">
        <img src={Logo} alt="Logo" />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link active={checkActive('')} as={Link} to="/">
            Início
          </Nav.Link>
          <Nav.Link active={checkActive('orcamento')} as={Link} to="/orcamento">
            Preciso de Máquinas
          </Nav.Link>
          <Nav.Link
            active={checkActive('sejaumlocadormpc')}
            as={Link}
            to="/sejaumlocadormpc"
          >
            Tenho Máquinas
          </Nav.Link>
          <Nav.Link
            active={checkActive('comofunciona')}
            as={Link}
            to="/comofunciona"
          >
            Como funciona o MPC?
          </Nav.Link>
          <Nav.Link active={checkActive('sobre')} as={Link} to="/sobre">
            Sobre
          </Nav.Link>
          <Nav.Link active={checkActive('contato')} as={Link} to="/contato">
            Contato
          </Nav.Link>
          <a
            className="nav-link icon"
            href="https://api.whatsapp.com/send?phone=5511950852563&text=Ol%C3%A1%2C%20vim%20atrav%C3%A9s%20do%20site%20M%C3%A1quinas%20para%20Constru%C3%A7%C3%A3o!"
            target="_blank_"
          >
            <FontAwesome name="whatsapp" type="fab" />
          </a>
          <a
            className="nav-link icon"
            href="https://www.instagram.com/maquinasparaconstrucao/"
            target="_blank_"
          >
            <FontAwesome name="instagram" type="fab" />
          </a>
          <a
            className="nav-link icon"
            href="https://www.facebook.com/MaquinasParaConstrucao"
            target="_blank_"
          >
            <FontAwesome name="facebook-square" type="fab" />
          </a>
        </Nav>
        {constructionCompany.id !== '' ? (
          <>
            <NavDropdown
              title={`Olá, ${constructionCompany.companyName}`}
              id="collapsible-nav-dropdown"
            >
              <NavDropdown.Item as={Link} to="/perfil/configs">
                Configurações
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/perfil/pedidos">
                Orçamentos
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={logout}>Sair</NavDropdown.Item>
            </NavDropdown>
          </>
        ) : (
          <Nav.Link active={checkActive('login')} as={Link} to="/login">
            Login / Cadastro
          </Nav.Link>
        )}
      </Navbar.Collapse>
      <Nav.Link
        active={checkActive('carrinho')}
        as={Link}
        to="/carrinho"
        className="btn-cart d-none d-lg-block"
        data-toggle="tooltip"
        data-placement="bottom"
        title="Carrinho"
      >
        {items.length > 0 ? <span>{items.length}</span> : ''}
        <FontAwesome name="truck" type="fas" />
      </Nav.Link>
    </Navbar>
  );
};

export default Header;
