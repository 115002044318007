import React from 'react';
import styled from 'styled-components';
import { Col } from 'react-bootstrap';

const AboutUsSection = styled.section`
  margin: 2vh 0;
  padding: 0rem 1rem;
  font: 16px/24px Quicksand-Regular;
  text-align: justify;
`;

const AboutUsInfos: React.FC = () => (
  <AboutUsSection>
    <Col>
      <h4>Máquinas para Construção</h4>
      <p>
        Máquinas para Construção é uma plataforma online que conecta oferta e
        demanda (marketplace), voltada ao conjunto de atividades necessárias
        para construir e conservar utilizando máquinas, equipamentos e
        ferramentas.
      </p>
      <p>
        Os locadores, prestadores de serviços e vendedores de máquinas colocarão
        os seus produtos à disposição de todo um mercado formado por
        construtoras, concessionárias, indústrias, pessoas físicas, entre
        outros, dispostos a utilizar a ferramenta MPC para se informar sobre
        máquinas, equipamentos e veículos. A plataforma também traz informações
        que apontam como melhor identificar e escolher equipamentos e serviços,
        proporcionando escolhas mais eficientes operacionalmente e
        financeiramente. Máquinas Para Construção foi criada para facilitar
        radicalmente a comunicação entre seus usuários, desde a identificação da
        demanda, até o envio da proposta de contratação.
      </p>
      <p>
        No Máquinas para Construção, os usuários terão acesso a um banco de
        dados com cerca de 10.000 tipos de equipamentos, máquinas, ferramentas,
        veículos, aeronaves existentes atualmente, possibilitando um maior
        conhecimento do que pode ser utilizado no dia a dia da arte de construir
        e conservar.
      </p>
    </Col>
  </AboutUsSection>
);

export default AboutUsInfos;
