/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react';

import './styles.scss';
import { Card, Form, Row, Col, Button, Spinner } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import { ReCaptcha, loadReCaptcha } from 'react-recaptcha-v3';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { ApplicationState } from '../../store';
import { checkAccount, checkPassword } from '../../services/validation';
import {
  updateRentalCompany,
  uploadImage,
  setPayment,
} from '../../services/mutations';
import { RentalCompanyInput, PlansType } from '../../services/types';
import {
  getRentalCompany,
  getRentalCompanyQuotations,
  getPlans,
} from '../../services/queries';
import { getRentalCompanyToken } from '../../services/auth';
import {
  updateRentalCompany as updateRCAction,
  removeRentalCompany,
} from '../../store/ducks/rentalCompany/actions';
import PayPal from '../PayPal';

const RentalCompanyConfigs: React.FC = () => {
  const [myPlan, handleMyPlan] = useState<PlansType>();

  const rentalCompanyState = useSelector(
    (state: ApplicationState) => state.rentalCompany,
  );

  const dispatch = useDispatch();

  const { rentalCompany, user } = rentalCompanyState;

  const verifyCallback = (recaptchaToken: string) => {
    // console.log('recaptchaToken', recaptchaToken);
  };
  const recaptcha = useRef(null);

  useEffect(() => {
    if (rentalCompany.id !== '') {
      handleRentalCompany({
        id: rentalCompany.id,
        planId: rentalCompany.planId,
        status: rentalCompany.status,
        cnpjOrCpf: rentalCompany.cnpjOrCpf,
        corporateName: rentalCompany.corporateName,
        email: rentalCompany.email,
        image: rentalCompany.image,
        acceptanceTermsOfUse: true,
        password: '',
        address:
          rentalCompany.address !== undefined ? rentalCompany.address : '',
        city: rentalCompany.city !== undefined ? rentalCompany.city : '',
        phone: rentalCompany.phone !== undefined ? rentalCompany.phone : '',
        state: rentalCompany.state !== undefined ? rentalCompany.state : '',
        whatsApp:
          rentalCompany.whatsApp !== undefined ? rentalCompany.whatsApp : '',
        description:
          rentalCompany.description !== undefined
            ? rentalCompany.description
            : '',
        ownerName:
          rentalCompany.ownerName !== undefined ? rentalCompany.ownerName : '',
        ownerEmail:
          rentalCompany.ownerEmail !== undefined
            ? rentalCompany.ownerEmail
            : '',
        ownerPhone:
          rentalCompany.ownerPhone !== undefined
            ? rentalCompany.ownerPhone
            : '',
        ownerWhatsApp:
          rentalCompany.ownerWhatsApp !== undefined
            ? rentalCompany.ownerWhatsApp
            : '',
      });
    }
    loadReCaptcha(process.env.REACT_APP_RECAPTCHA_KEY || '');
  }, [process.env.REACT_APP_RECAPTCHA_KEY || '', rentalCompany]);

  const [rentalCompanyUpdated, handleRentalCompany] = useState<
    RentalCompanyInput | any
  >({
    id: '',
    email: '',
    password: '',
    corporateName: '',
    planId: '',
    address: '',
    city: '',
    phone: '',
    state: '',
    status: false,
    whatsApp: '',
    description: '',
    cnpjOrCpf: '',
    image: '',
    ownerName: '',
    ownerEmail: '',
    ownerPhone: '',
    ownerWhatsApp: '',
    acceptanceTermsOfUse: false,
  });

  const [fieldType, handleFieldType] = useState('text');

  const [updatedImage] = useState<any>();
  const [password, handlePassword] = useState('');
  const [confirmPassword, handleConfirmPassword] = useState('');
  const [loading, handleLoading] = useState(false);

  const {
    email,
    corporateName,
    planId,
    address,
    phone,
    status,
    whatsApp,
    cnpjOrCpf,
    ownerName,
    ownerEmail,
    ownerPhone,
    ownerWhatsApp,
  } = rentalCompanyUpdated;

  useEffect(() => {
    if (planId) {
      getPlans()
        .then(response => {
          const myPlanFilterd = response.data.data.plans.filter(
            (plan: PlansType) => plan.id === planId,
          )[0];
          handleMyPlan(myPlanFilterd);
        })
        .catch(error => {
          console.log(error);
        });
    }
  }, [planId]);

  const updateRentalCompanyData = (event: React.FormEvent) => {
    event.preventDefault();
    if (
      checkAccount('corporateName', corporateName) &&
      checkAccount('address', address) &&
      checkAccount('phone', phone) &&
      checkAccount('whatsApp', whatsApp) &&
      checkAccount('cnpjOrCpf', cnpjOrCpf) &&
      checkAccount('ownerName', ownerName) &&
      checkAccount('email', ownerEmail) &&
      checkAccount('phone', ownerPhone) &&
      checkAccount('whatsApp', ownerWhatsApp)
    ) {
      handleLoading(true);
      const formData = new FormData();
      formData.append('file', updatedImage);
      formData.append(
        'upload_preset',
        process.env.REACT_APP_CLOUDINARY_USER_UPLOAD_PRESET || '',
      );
      // formData.append('public_id', `user_${id}`);
      if (recaptcha.current !== undefined) {
        (recaptcha as any).current.execute();
      }
      if (password !== '') {
        if (
          checkAccount('password', password) &&
          checkAccount('confirmPassword', confirmPassword) &&
          checkPassword(password, confirmPassword)
        ) {
          rentalCompanyUpdated.password = password;
          if (updatedImage !== ('' || undefined)) {
            updateRentalCompany(rentalCompanyUpdated, user.token)
              .then(response => {
                checkAccount('update', response.data.data.updateRentalCompany);
                updateStore();
                handleLoading(false);
              })
              .catch(error => {
                handleLoading(false);
                console.log('error 3', error);
              });
          } else {
            updateRentalCompany(rentalCompanyUpdated, user.token)
              .then(response => {
                // console.log('Resposta senha alterada', response.data.data);
                checkAccount('update', response.data.data.updateRentalCompany);
                updateStore();
                handleLoading(false);
              })
              .catch(error => {
                handleLoading(false);
                console.log('error 1', error);
              });
          }
        }
      } else if (updatedImage !== ('' || undefined)) {
        uploadImage(formData)
          .then(async result => {
            rentalCompanyUpdated.image = result.data.secure_url;
            updateRentalCompany(rentalCompanyUpdated, user.token)
              .then(response => {
                // console.log('Senha não modificada', response.data);
                checkAccount('update', response.data.data.updateRentalCompany);
                updateStore();
                handleLoading(false);
              })
              .catch(error => {
                console.log('error 3', error);
              });
          })
          .catch(error => {
            checkAccount('error', 'error');
            handleLoading(false);
          });
      } else {
        updateRentalCompany(rentalCompanyUpdated, user.token)
          .then(response => {
            // console.log('Senha não modificada', response.data);
            checkAccount('update', response.data.data.updateRentalCompany);
            updateStore();
            handleLoading(false);
          })
          .catch(error => {
            handleLoading(false);
            console.log('error 3', error);
          });
      }
    }
  };

  const updateStore = () => {
    getRentalCompany(getRentalCompanyToken())
      .then(resp => {
        const rentalCompanyTemp = resp.data.data.rentalCompany.rentalCompany;
        if (rentalCompanyTemp) {
          getRentalCompanyQuotations(getRentalCompanyToken())
            .then(result => {
              dispatch(
                updateRCAction({
                  rentalCompany: rentalCompanyTemp,
                  user: {
                    role: 'RENTAL',
                    token: getRentalCompanyToken(),
                  },
                  rentalCompanyQuotations:
                    result.data.data.rentalCompanyQuotations,
                }),
              );
            })
            .catch(error => {
              console.log('error', error);
            });
        } else {
          dispatch(removeRentalCompany());
        }
      })
      .catch(error => {
        console.log('error', error);
        dispatch(removeRentalCompany());
      });
  };

  const payment = (order: any) => {
    const payerId = order.payer.payer_id;
    const paymentId = order.purchase_units[0].payments.captures[0].id;
    const paymentEmail = order.payer.email_address;
    const paymentAmount = myPlan?.value as string;
    const token = getRentalCompanyToken();
    setPayment({
      payerId,
      paymentId,
      paymentEmail,
      paymentAmount,
      planId,
      token,
    })
      .then(response => {
        if (response.data.errors) {
          toast.error(`${response.data.errors[0].message}`);
        } else if (response.data.data.setPayment) {
          window.location.pathname = '/locadora/configs';
        }
      })
      .catch(error => {
        console.log('error', error);
      });
  };

  return (
    <Card className="rental-company-configs">
      <Card.Body>
        <Card.Title className="dashed pb-2">Configurações</Card.Title>
        <Form
          onSubmit={(event: React.FormEvent) => updateRentalCompanyData(event)}
        >
          <Row>
            <Col xs={12} sm={12} md={8} lg={8}>
              <Row>
                <Col xs={12} md={12} sm={12} lg={6}>
                  <Form.Group>
                    <Form.Label>Nome/Razão Social</Form.Label>
                    <Form.Control
                      type="text"
                      value={corporateName}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                      ) => {
                        handleRentalCompany({
                          ...rentalCompanyUpdated,
                          corporateName: event.target.value,
                        });
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={12} sm={12} lg={6}>
                  <Form.Group>
                    <Form.Label>
                      CPF/CNPJ <small>(apenas números)</small>
                    </Form.Label>
                    <Form.Control
                      type="number"
                      name="cnpjOrCpf"
                      className="form-control"
                      value={cnpjOrCpf}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                      ) => {
                        handleRentalCompany({
                          ...rentalCompanyUpdated,
                          cnpjOrCpf: event.target.value,
                        });
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                {updatedImage !== ('' || undefined) ? (
                  <Col
                    xs={12}
                    md={12}
                    sm={12}
                    lg={6}
                    className="justify-content"
                  >
                    <div>
                      <div className="rental-image">
                        <img
                          id="rentalImage"
                          src={String(updatedImage)}
                          alt="profile"
                        />
                      </div>
                      <small>Prévia da imagem</small>
                    </div>
                  </Col>
                ) : (
                  ''
                )}
              </Row>
              <Col xs={12} md={12} sm={12} lg={12}>
                <Form.Group>
                  <Form.Label>Endereço</Form.Label>
                  <Form.Control
                    type="text"
                    name="address"
                    className="form-control"
                    value={address}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      handleRentalCompany({
                        ...rentalCompanyUpdated,
                        address: event.target.value,
                      });
                    }}
                  />
                </Form.Group>
              </Col>
              <Row>
                <Col xs={12} md={12} sm={12} lg={6}>
                  <Form.Group>
                    <Form.Label>Telefone</Form.Label>
                    <Form.Control
                      as={InputMask}
                      type="text"
                      mask="(99) 99999-9999"
                      maskChar="_"
                      name="phone"
                      className="form-control"
                      value={phone !== undefined ? phone : ''}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                      ) => {
                        handleRentalCompany({
                          ...rentalCompanyUpdated,
                          phone: event.target.value.replace(/[^0-9]/g, ''),
                        });
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={12} sm={12} lg={6}>
                  <Form.Group>
                    <Form.Label>WhatsApp</Form.Label>
                    <Form.Control
                      as={InputMask}
                      type="text"
                      mask="(99) 99999-9999"
                      maskChar="_"
                      name="whatsApp"
                      className="form-control"
                      value={whatsApp !== undefined ? whatsApp : ''}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                      ) => {
                        handleRentalCompany({
                          ...rentalCompanyUpdated,
                          whatsApp: event.target.value.replace(/[^0-9]/g, ''),
                        });
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12} sm={12} lg={4}>
                  <Form.Group>
                    <Form.Label>Plano</Form.Label>
                    <Card.Text>{myPlan?.name || planId}</Card.Text>
                  </Form.Group>
                </Col>
                <Col xs={12} sm={12} md={12} lg={4}>
                  <Form.Group>
                    <Form.Label>Status</Form.Label>
                    <Card.Text>{status ? 'Ativo' : 'Inativo'}</Card.Text>
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  {myPlan && !status && (
                    <>
                      <Form.Label>Ative seu plano aqui</Form.Label>
                      <PayPal product={myPlan} response={payment} />
                    </>
                  )}
                </Col>
              </Row>
              <Card.Title className="dashed pb-2">Dados de acesso</Card.Title>
              <Row>
                <Col xs={12} md={12} sm={12} lg={12}>
                  <Form.Group>
                    <Form.Label>E-mail</Form.Label>
                    <Card.Text>{email}</Card.Text>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12} sm={12} lg={6}>
                  <Form.Group>
                    <Form.Label>Nova senha</Form.Label>
                    <Form.Control
                      type={fieldType}
                      onFocus={() => handleFieldType('password')}
                      value={password}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                      ) => {
                        handlePassword(event.target.value);
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={12} sm={12} lg={6}>
                  <Form.Group>
                    <Form.Label>Confirmar nova senha</Form.Label>
                    <Form.Control
                      type={fieldType}
                      onFocus={() => handleFieldType('password')}
                      value={confirmPassword}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                      ) => {
                        handleConfirmPassword(event.target.value);
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Col>
            <Col xs={12} sm={12} md={4} lg={4}>
              <Form.Group>
                <Form.Label>Responsável</Form.Label>
                <Form.Control
                  type="text"
                  value={ownerName}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleRentalCompany({
                      ...rentalCompanyUpdated,
                      ownerName: event.target.value,
                    });
                  }}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>E-mail do Responsável</Form.Label>
                <Form.Control
                  type="text"
                  value={ownerEmail}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleRentalCompany({
                      ...rentalCompanyUpdated,
                      ownerEmail: event.target.value,
                    });
                  }}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Telefone do Responsável</Form.Label>
                <Form.Control
                  as={InputMask}
                  type="text"
                  mask="(99) 99999-9999"
                  maskChar="_"
                  name="ownerPhone"
                  className="form-control"
                  value={ownerPhone !== undefined ? ownerPhone : ''}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleRentalCompany({
                      ...rentalCompanyUpdated,
                      ownerPhone: event.target.value.replace(/[^0-9]/g, ''),
                    });
                  }}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>WhatsApp do Responsável</Form.Label>
                <Form.Control
                  as={InputMask}
                  type="text"
                  mask="(99) 99999-9999"
                  maskChar="_"
                  name="ownerWhatsApp"
                  className="form-control"
                  value={ownerWhatsApp !== undefined ? ownerWhatsApp : ''}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleRentalCompany({
                      ...rentalCompanyUpdated,
                      ownerWhatsApp: event.target.value.replace(/[^0-9]/g, ''),
                    });
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
          <ReCaptcha
            sitekey={process.env.REACT_APP_RECAPTCHA_KEY || ''}
            action="submit"
            ref={recaptcha}
            verifyCallback={verifyCallback}
          />
          <Button type="submit">
            {!loading ? (
              'Atualizar dados'
            ) : (
              <Spinner
                as="span"
                animation="border"
                role="status"
                aria-hidden="true"
              />
            )}
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default RentalCompanyConfigs;
