import React from 'react';

import { Row, Col } from 'react-bootstrap';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

import PagePath from '../../components/PagePath';
import SearchData from '../../components/SearchData';
import SearchTable from '../../components/SearchTable';

const Search: React.FC = () => (
  <>
    <Header />
    <PagePath />
    <section className="fade-in">
      <h4 className="ml-3 pl-1">
        Solicite <b style={{ color: '#f49c4b' }}>gratuitamente</b> seu
        orçamento!
      </h4>
      <Row>
        <Col xs={12} sm={12} md={12} lg={4}>
          <SearchData />
        </Col>
        <Col xs={12} sm={12} md={12} lg={8}>
          <SearchTable />
        </Col>
      </Row>
    </section>
    <Footer />
  </>
);

export default Search;
