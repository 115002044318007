import { action } from 'typesafe-actions';
import {
  ConstructionCompanyTypes, ConstructionCompanyState,
} from './types';

export const updateConstructionCompany = (data: ConstructionCompanyState) => 
  action(ConstructionCompanyTypes.UPDATE_CONSTRUCTION_COMPANY, data);

export const removeConstructionCompany = () => 
  action(ConstructionCompanyTypes.REMOVE_CONSTRUCTION_COMPANY);
