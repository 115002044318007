/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Spinner } from 'react-bootstrap';

import Category from '../Category';
import { getCategories } from '../../services/queries';
import { removeSpecialChars } from '../../services/mask';
import { Category as CategoryItem } from '../../services/types';

import './styles.scss';
import Footer from '../Footer';
import { orderByName } from '../../services/functions';

const Categories: React.FC = () => {
  const [categoriesList, handleCategoriesList] = useState([]);
  const getAllCategories = () => {
    getCategories()
      .then(async response =>
        handleCategoriesList(orderByName(response.data.data.categories)),
      )
      .catch(async error => {
        // eslint-disable-next-line no-alert
        alert(error);
        return 0;
      });
  };

  useEffect(() => {
    getAllCategories();
  }, []);

  return (
    <>
      {categoriesList.length > 0 ? (
        <>
          <Container className="categories" fluid>
            <Col>
              <h2>Categorias</h2>
            </Col>
            <Col>
              <p>
                É fácil pesquisar a máquina que você precisa.
                <br />
                Dividimos nosso banco de dados em dezesseis categorias.
                <br />
                Já sabe quais máquinas precisa,
                <Link to="/orcamento"> clicando aqui </Link>e solicite seu
                orçamento.
              </p>
            </Col>
            <Row>
              {categoriesList.map((category: CategoryItem) => (
                <Col key={category.id} xs={12} sm={12} md={6} lg={4}>
                  <Link to={`/categorias/${removeSpecialChars(category.name)}`}>
                    <Category
                      {...{
                        title: category.name,
                        background: category.background,
                      }}
                    />
                  </Link>
                </Col>
              ))}
            </Row>
          </Container>
          <Footer />
        </>
      ) : (
        <Spinner
          className="spinner-loading"
          as="span"
          animation="border"
          role="status"
          aria-hidden="true"
        />
      )}
    </>
  );
};

export default Categories;
