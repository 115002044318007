import React from 'react';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';

interface Props {
  postsPerPage: number;
  totalPosts: number;
  paginate: Function;
}

const PaginationNav = styled.nav`
  margin-top: 1vh;
  //float: right;
`;

const Pagination = ({ postsPerPage, totalPosts, paginate }: Props) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i += 1) {
    pageNumbers.push(i);
  }

  return (
    <PaginationNav>
      <ul className="pagination" style={{ overflow: 'auto' }}>
        {pageNumbers.map(number => (
          <li key={number} className="page-item">
            <Button
              className="btnToggle page-link"
              onClick={() => paginate(number)}
            >
              {number}
            </Button>
          </li>
        ))}
      </ul>
    </PaginationNav>
  );
};

export default Pagination;
