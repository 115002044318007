import React from 'react';

import './styles.scss';
import { useSelector } from 'react-redux';
import { Row, Button } from 'react-bootstrap';
import CartTable from '../CartTable';
import { ApplicationState } from '../../store';

const MARGIN_LEFT = 30;

const SearchTable: React.FC = () => {
  const cart = useSelector((state: ApplicationState) => state.cart);
  const { items } = cart;

  const goCart = () => {
    window.location.pathname = '/carrinho';
  };

  return (
    <section className="search-table">
      <Row noGutters>
        <h3>ETAPA 2 - Máquinas adicionadas</h3>
        {items.length !== 0 && (
          <Button
            type="submit"
            style={{ marginLeft: MARGIN_LEFT }}
            onClick={goCart}
          >
            Solicitar Orçamento
          </Button>
        )}
      </Row>
      <CartTable />
    </section>
  );
};

export default SearchTable;
