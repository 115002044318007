/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Spinner } from 'react-bootstrap';

import { getMachinesTypes, getCategories } from '../../services/queries';
import { removeSpecialChars } from '../../services/mask';
import { Category as CategoryItem, MachineTypes } from '../../services/types';

import './styles.scss';
import CategorySlick from '../CategorySlick';
import MachineType from '../MachineType';
import Footer from '../Footer';
import { PagePathManual } from '../PagePath';
import { orderByName } from '../../services/functions';

const MachinesTypesPanel: React.FC = () => {
  const URI = window.location.pathname.substring(1).split('/')[1];
  const [link, handleLink] = useState('');
  const [loading, setLoading] = useState(false);

  const [machinesTypesList, handleMachinesTypesList] = useState([]);
  const getAllCategories = () => {
    setLoading(true);
    getCategories()
      .then(async result => {
        const temp: CategoryItem[] = result.data.data.categories.filter(
          (category: CategoryItem) => removeSpecialChars(category.name) === URI,
        );
        setLoading(false);
        handleLink(temp[0].name);
        getMachinesTypes()
          .then(async response =>
            handleMachinesTypesList(
              orderByName(response.data.data.equipamentMachines).filter(
                // eslint-disable-next-line max-len
                (machineTypes: MachineTypes) =>
                  removeSpecialChars(machineTypes.categoryId) === temp[0].id,
              ),
            ),
          )
          .catch(async error => {
            // eslint-disable-next-line no-alert
            alert(error);
          });
      })
      .catch(async error => {
        // eslint-disable-next-line no-alert
        alert(error);
        return 0;
      });
  };

  useEffect(() => {
    getAllCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [URI]);

  return (
    <>
      {machinesTypesList.length > 0 ? (
        <>
          <PagePathManual {...{ link, subLink: '', subSubLink: '' }} />
          <CategorySlick />
          <Container className="machine-types-panel" fluid>
            <Col className="justify-content">
              <h2>Equipamentos/Máquinas</h2>
            </Col>
            {loading ? (
              <div className="container-loading">
                <Spinner
                  as="span"
                  animation="border"
                  role="status"
                  aria-hidden="true"
                />
              </div>
            ) : (
              <Row>
                {machinesTypesList.map((machineType: MachineTypes) => (
                  <Col key={machineType.id} xs={12} sm={12} md={6} lg={4}>
                    <Link
                      to={`/categorias/${URI}/${removeSpecialChars(
                        machineType.name,
                      )}`}
                    >
                      <MachineType {...machineType} />
                    </Link>
                  </Col>
                ))}
              </Row>
            )}
          </Container>
          <Footer />
        </>
      ) : (
        <Spinner
          className="spinner-loading"
          as="span"
          animation="border"
          role="status"
          aria-hidden="true"
        />
      )}
    </>
  );
};

export default MachinesTypesPanel;
