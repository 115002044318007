/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react';

import './styles.scss';
import { Card, Form, Row, Col, Button, Spinner } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import { ReCaptcha, loadReCaptcha } from 'react-recaptcha-v3';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { checkAccount, checkPassword } from '../../services/validation';
import {
  updateConstructionCompany,
  uploadImage,
} from '../../services/mutations';
import { ConstructionCompanyInput } from '../../services/types';
import { updateConstructionCompany as updateCCAction } from '../../store/ducks/constructionCompany/actions';

const UserConfigs: React.FC = () => {
  const constructionCompanyState = useSelector(
    (state: ApplicationState) => state.constructionCompany as any,
  );

  const dispatch = useDispatch();

  const { constructionCompany, user } = constructionCompanyState;

  const verifyCallback = (recaptchaToken: string) => {
    // console.log('recaptchaToken', recaptchaToken);
  };
  const recaptcha = useRef(null);

  useEffect(() => {
    if (constructionCompany.id !== '') {
      handleConstructionCompany({
        id: constructionCompany.id,
        cnpjOrCpf: constructionCompany.cnpjOrCpf,
        companyName: constructionCompany.companyName,
        email: constructionCompany.email,
        image: constructionCompany.image,
        password: '',
        ownerName:
          constructionCompany.ownerName !== undefined
            ? constructionCompany.ownerName
            : '',
        ownerEmail:
          constructionCompany.ownerEmail !== undefined
            ? constructionCompany.ownerEmail
            : '',
        ownerPhone:
          constructionCompany.ownerPhone !== undefined
            ? constructionCompany.ownerPhone
            : '',
        ownerWhatsApp:
          constructionCompany.ownerWhatsApp !== undefined
            ? constructionCompany.ownerWhatsApp
            : '',
        acceptanceTermsOfUse: true,
        phone: constructionCompany.phone,
      });
    }
    loadReCaptcha(process.env.REACT_APP_RECAPTCHA_KEY || '');
  }, [process.env.REACT_APP_RECAPTCHA_KEY || '', constructionCompany]);

  const [constructionCompanyUpdated, handleConstructionCompany] = useState<
    ConstructionCompanyInput
  >({
    id: '',
    email: '',
    password: '',
    companyName: '',
    cnpjOrCpf: '',
    image: '',
    ownerName: '',
    ownerEmail: '',
    ownerPhone: '',
    ownerWhatsApp: '',
    acceptanceTermsOfUse: true,
    phone: '',
  });

  const [fieldType, handleFieldType] = useState('text');

  const [updatedImage] = useState<any>();
  const [password, handlePassword] = useState('');
  const [confirmPassword, handleConfirmPassword] = useState('');
  const [loading, handleLoading] = useState(false);

  const {
    email,
    companyName,
    cnpjOrCpf,
    ownerName,
    ownerEmail,
    ownerPhone,
    ownerWhatsApp,
    phone,
  } = constructionCompanyUpdated;

  const updateConstructionCompanyInfos = (event: React.FormEvent) => {
    event.preventDefault();
    if (
      checkAccount('companyName', companyName) &&
      checkAccount('ownerName', ownerName) &&
      checkAccount('cnpjOrCpf', cnpjOrCpf) &&
      checkAccount('email', ownerEmail) &&
      checkAccount('phone', ownerPhone) &&
      checkAccount('whatsApp', ownerWhatsApp)
    ) {
      handleLoading(true);
      const formData = new FormData();
      formData.append('file', updatedImage);
      formData.append(
        'upload_preset',
        process.env.REACT_APP_CLOUDINARY_USER_UPLOAD_PRESET || '',
      );
      // formData.append('public_id', `user_${id}`);
      if (recaptcha.current !== undefined) {
        (recaptcha as any).current.execute();
      }
      if (password !== '') {
        if (
          checkAccount('password', password) &&
          checkAccount('confirmPassword', confirmPassword) &&
          checkPassword(password, confirmPassword)
        ) {
          constructionCompanyUpdated.password = password;
          if (updatedImage !== ('' || undefined)) {
            uploadImage(formData)
              .then(async result => {
                constructionCompanyUpdated.image = result.data.secure_url;
                updateConstructionCompany(
                  constructionCompanyUpdated,
                  user.token,
                )
                  .then(response => {
                    checkAccount(
                      'update',
                      response.data.data.updateConstructionCompany,
                    );
                    updateStore();
                    handleLoading(false);
                  })
                  .catch(error => {
                    console.log('error 3', error);
                  });
              })
              .catch(error => {
                checkAccount('error', 'error');
                handleLoading(false);
              });
          } else {
            updateConstructionCompany(constructionCompanyUpdated, user.token)
              .then(response => {
                checkAccount(
                  'update',
                  response.data.data.updateConstructionCompany,
                );
                updateStore();
                handleLoading(false);
              })
              .catch(error => {
                handleLoading(false);
                console.log('error 1', error);
              });
          }
        }
      } else if (updatedImage !== ('' || undefined)) {
        uploadImage(formData)
          .then(async result => {
            updateConstructionCompany(constructionCompanyUpdated, user.token)
              .then(response => {
                checkAccount(
                  'update',
                  response.data.data.updateConstructionCompany,
                );
                updateStore();
                handleLoading(false);
              })
              .catch(error => {
                console.log('error 3', error);
              });
            constructionCompanyUpdated.image = result.data.secure_url;
          })
          .catch(error => {
            checkAccount('error', 'error');
            handleLoading(false);
          });
      } else {
        updateConstructionCompany(constructionCompanyUpdated, user.token)
          .then(response => {
            // console.log('Senha não modificada', response.data);
            checkAccount(
              'update',
              response.data.data.updateConstructionCompany,
            );
            updateStore();
            handleLoading(false);
          })
          .catch(error => {
            handleLoading(false);
            console.log('error 3', error);
          });
      }
    } else {
      handleLoading(false);
    }
  };

  const updateStore = () => {
    dispatch(
      updateCCAction({
        constructionCompany: constructionCompanyUpdated,
        user: {
          role: 'CONSTRUCTION',
          token: user.token,
        },
        constructionCompanyQuotations:
          constructionCompanyState.constructionCompanyQuotations,
      }),
    );
  };

  return (
    <Card className="user-configs">
      <Card.Body>
        <Card.Title>Configurações</Card.Title>
        <Form
          onSubmit={(event: React.FormEvent) =>
            updateConstructionCompanyInfos(event)
          }
        >
          <Row>
            <Col xs={12} sm={12} md={8} lg={8}>
              <Row>
                <Col xs={12} md={12} sm={12} lg={6}>
                  <Form.Group>
                    <Form.Label>Nome/Razão Social</Form.Label>
                    <Form.Control
                      type="text"
                      value={companyName}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                      ) => {
                        handleConstructionCompany({
                          ...constructionCompanyUpdated,
                          companyName: event.target.value,
                        });
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={12} sm={12} lg={6}>
                  <Form.Group>
                    <Form.Label>
                      CPF/CNPJ <small>(apenas números)</small>
                    </Form.Label>
                    <Form.Control
                      type="number"
                      name="cnpjOrCpf"
                      className="form-control"
                      value={cnpjOrCpf}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                      ) => {
                        handleConstructionCompany({
                          ...constructionCompanyUpdated,
                          cnpjOrCpf: event.target.value,
                        });
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                {updatedImage !== ('' || undefined) ? (
                  <Col
                    xs={12}
                    md={12}
                    sm={12}
                    lg={6}
                    className="justify-content"
                  >
                    <div>
                      <div className="construction-image">
                        <img
                          id="constructionImage"
                          src={String(updatedImage)}
                          alt="profile"
                        />
                      </div>
                      <small>Prévia da imagem</small>
                    </div>
                  </Col>
                ) : (
                  ''
                )}
              </Row>
              <Card.Title>Dados de acesso</Card.Title>
              <Row>
                <Col xs={12} md={12} sm={12} lg={6}>
                  <Form.Group>
                    <Form.Label>E-mail</Form.Label>
                    <Card.Text>{email}</Card.Text>
                  </Form.Group>
                </Col>
                <Col xs={12} md={12} sm={12} lg={6}>
                  <Form.Group>
                    <Form.Label>Telefone</Form.Label>
                    <Form.Control
                      as={InputMask}
                      type="text"
                      mask="(99) 99999-9999"
                      maskChar="_"
                      value={phone}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        handleConstructionCompany({
                          ...constructionCompanyUpdated,
                          phone: event.target.value.replace(/[^0-9]/g, ''),
                        })
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12} sm={12} lg={6}>
                  <Form.Group>
                    <Form.Label>Nova senha</Form.Label>
                    <Form.Control
                      type={fieldType}
                      onFocus={() => handleFieldType('password')}
                      value={password}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                      ) => {
                        handlePassword(event.target.value);
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={12} sm={12} lg={6}>
                  <Form.Group>
                    <Form.Label>Confirmar nova senha</Form.Label>
                    <Form.Control
                      type={fieldType}
                      onFocus={() => handleFieldType('password')}
                      value={confirmPassword}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                      ) => {
                        handleConfirmPassword(event.target.value);
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Col>
            <Col xs={12} sm={12} md={4} lg={4}>
              <Form.Group>
                <Form.Label>Responsável</Form.Label>
                <Form.Control
                  type="text"
                  value={ownerName}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleConstructionCompany({
                      ...constructionCompanyUpdated,
                      ownerName: event.target.value,
                    });
                  }}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>E-mail do Responsável</Form.Label>
                <Form.Control
                  type="text"
                  value={ownerEmail}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleConstructionCompany({
                      ...constructionCompanyUpdated,
                      ownerEmail: event.target.value,
                    });
                  }}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Telefone do Responsável</Form.Label>
                <Form.Control
                  as={InputMask}
                  type="text"
                  mask="(99) 99999-9999"
                  maskChar="_"
                  name="ownerPhone"
                  className="form-control"
                  value={ownerPhone !== undefined ? ownerPhone : ''}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleConstructionCompany({
                      ...constructionCompanyUpdated,
                      ownerPhone: event.target.value.replace(/[^0-9]/g, ''),
                    });
                  }}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>WhatsApp do Responsável</Form.Label>
                <Form.Control
                  as={InputMask}
                  type="text"
                  mask="(99) 99999-9999"
                  maskChar="_"
                  name="ownerWhatsApp"
                  className="form-control"
                  value={ownerWhatsApp !== undefined ? ownerWhatsApp : ''}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleConstructionCompany({
                      ...constructionCompanyUpdated,
                      ownerWhatsApp: event.target.value.replace(/[^0-9]/g, ''),
                    });
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
          <ReCaptcha
            sitekey={process.env.REACT_APP_RECAPTCHA_KEY || ''}
            action="submit"
            ref={recaptcha}
            verifyCallback={verifyCallback}
          />
          <Button type="submit">
            {!loading ? (
              'Atualizar dados'
            ) : (
              <Spinner
                as="span"
                animation="border"
                role="status"
                aria-hidden="true"
              />
            )}
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default UserConfigs;
