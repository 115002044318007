/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect } from 'react';

import './styles.scss';
import Card from 'react-bootstrap/Card';
import { Row, Col, Button, Spinner, Form } from 'react-bootstrap';
import { ReCaptcha, loadReCaptcha } from 'react-recaptcha-v3';
import { useSelector, useDispatch } from 'react-redux';
import { Quotation } from '../../services/types';
import { sendPdf } from '../../services/mutations';
import { ApplicationState } from '../../store';
import { checkFile } from '../../services/validation';
import {
  getRentalCompany,
  getRentalCompanyQuotations,
} from '../../services/queries';
import { getRentalCompanyToken } from '../../services/auth';
import {
  updateRentalCompany,
  removeRentalCompany,
} from '../../store/ducks/rentalCompany/actions';
import { toggleModalQ } from '../../store/ducks/modals/actions';

interface Props {
  data: Quotation | any;
}

const QuotationData: React.FC<Props> = (props: Props) => {
  const { data } = props;

  const rentalCompanyState = useSelector(
    (state: ApplicationState) => state.rentalCompany,
  );

  const { user } = rentalCompanyState;

  const dispatch = useDispatch();

  const verifyCallback = (recaptchaToken: string) => {
    // console.log('recaptchaToken', recaptchaToken);
  };
  const recaptcha = useRef(null);

  const [updatedFile, handleUpdatedFile] = useState<any>();

  const [fileName, handleFileName] = useState('');

  const [loading, handleLoading] = useState(false);

  useEffect(() => {
    loadReCaptcha(process.env.REACT_APP_RECAPTCHA_KEY || '');
  }, [process.env.REACT_APP_RECAPTCHA_KEY || '']);

  const fileChange = (fileItem: FileList) => {
    // Tratar o tamanho do arquivo fileItem[0].size;
    if (fileItem[0] !== undefined) {
      const preview: HTMLImageElement = document.getElementById(
        'rentalImage',
      ) as HTMLImageElement;
      const file = fileItem[0];

      if (checkFile('file', String(file.size))) {
        const reader: FileReader = new FileReader();
        handleFileName(file.name);
        reader.onloadend = () => {
          // preview.src = reader.result;
          handleUpdatedFile(fileItem[0]);
        };

        if (file) {
          reader.readAsDataURL(file);
        } else {
          preview.src = '';
        }
      } else {
        handleUpdatedFile([]);
      }
    }
  };

  const updateStore = () => {
    getRentalCompany(getRentalCompanyToken())
      .then(resp => {
        const rentalCompanyTemp = resp.data.data.rentalCompany.rentalCompany;
        if (rentalCompanyTemp) {
          getRentalCompanyQuotations(getRentalCompanyToken())
            .then(result => {
              dispatch(
                updateRentalCompany({
                  rentalCompany: rentalCompanyTemp,
                  user: {
                    role: 'RENTAL',
                    token: getRentalCompanyToken(),
                  },
                  rentalCompanyQuotations:
                    result.data.data.rentalCompanyQuotations,
                }),
              );
            })
            .catch(error => {
              console.log('error', error);
            });
        } else {
          dispatch(removeRentalCompany());
        }
      })
      .catch(error => {
        console.log('error', error);
        dispatch(removeRentalCompany());
      });
    dispatch(toggleModalQ(undefined));
  };

  const sendQuotation = (event: React.FormEvent) => {
    event.preventDefault();

    if (updatedFile !== ('' || undefined)) {
      handleLoading(true);

      const formData: FormData = new FormData();
      formData.append('quotationId', data.id);
      formData.append('file', updatedFile);

      sendPdf(formData, user.token)
        .then(response => {
          checkFile('create', 'Orçamento enviado com sucesso');
          updateStore();
          handleLoading(false);
        })
        .catch(error => {
          console.log('error 3', error);
          handleLoading(false);
        });
    } else {
      handleLoading(false);
    }
  };

  return (
    <section className="quotation-data mb-4">
      <Row>
        <Col xs={12} sm={12} md={8} lg={8}>
          <Card>
            <Card.Body>
              <Row>
                <Col xs={12} sm={12} md={6} lg={4}>
                  <Card.Title> Empresa demandante </Card.Title>
                  <Card.Text>{data.companyName}</Card.Text>
                </Col>
                <Col xs={12} sm={12} md={6} lg={4}>
                  <Card.Title> CPF/CNPJ </Card.Title>
                  <Card.Text>{data.cnpjOrCpf}</Card.Text>
                </Col>
                <Col xs={12} sm={12} md={6} lg={4}>
                  <Card.Title> Responsável </Card.Title>
                  <Card.Text>{data.ownerName}</Card.Text>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={6} lg={8}>
                  {data.address && (
                    <>
                      <Card.Title> Endereço </Card.Title>
                      <Card.Text>{data.address}</Card.Text>
                    </>
                  )}
                </Col>
                <Col xs={12} sm={12} md={6} lg={4}>
                  {data.ownerEmail && (
                    <>
                      <Card.Title> E-mail do Responsável </Card.Title>
                      <Card.Text>{data.ownerEmail}</Card.Text>
                    </>
                  )}
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={6} lg={4}>
                  {data.phone && (
                    <>
                      <Card.Title> Telefone </Card.Title>
                      <Card.Text>{data.phone}</Card.Text>
                    </>
                  )}
                </Col>
                <Col xs={12} sm={12} md={6} lg={4}>
                  {data.whatsApp && (
                    <>
                      <Card.Title> WhatsApp </Card.Title>
                      <Card.Text>{data.whatsApp}</Card.Text>
                    </>
                  )}
                </Col>
                <Col xs={12} sm={12} md={6} lg={4}>
                  {data.ownerPhone && (
                    <>
                      <Card.Title> Telefone do Responsável </Card.Title>
                      <Card.Text>{data.ownerPhone}</Card.Text>
                    </>
                  )}
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={6} lg={8}>
                  {data.email && (
                    <>
                      <Card.Title>E-mail </Card.Title>
                      <Card.Text>{data.email}</Card.Text>
                    </>
                  )}
                </Col>
                <Col xs={12} sm={12} md={6} lg={4}>
                  {data.ownerWhatsApp && (
                    <>
                      <Card.Title> WhatsApp do Responsável </Card.Title>
                      <Card.Text>{data.ownerWhatsApp}</Card.Text>
                    </>
                  )}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>

        <Col xs={12} sm={12} md={4} lg={4}>
          <Card>
            <Card.Body>
              <Form onSubmit={(event: React.FormEvent) => sendQuotation(event)}>
                <Form.Group>
                  <Form.Label> Enviar orçamento </Form.Label>
                  <Form.File
                    type="file"
                    label={fileName === '' ? 'Selecione um arquivo' : fileName}
                    data-browse="Selecionar"
                    accept="application/pdf"
                    custom
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      fileChange(event.target.files || new FileList());
                    }}
                  />
                </Form.Group>
                <ReCaptcha
                  sitekey={process.env.REACT_APP_RECAPTCHA_KEY || ''}
                  action="submit"
                  ref={recaptcha}
                  verifyCallback={verifyCallback}
                />
                <Button type="submit">
                  {!loading ? (
                    'Enviar orçamento'
                  ) : (
                    <Spinner
                      as="span"
                      animation="border"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </section>
  );
};

export default QuotationData;
