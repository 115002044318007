import React from 'react';

import './styles.scss';

import { Col, Table, Spinner } from 'react-bootstrap';
import FontAwesome from '../FontAwesome';
import { QuotationPdf, MachineQuotation } from '../../services/types';
import { formatDate } from '../../services/mask';

interface MachinesProps {
  machines: MachineQuotation[];
}

interface PdfsProps {
  pdfs: QuotationPdf[];
}

export const QuotationMachinesTable: React.FC<MachinesProps> = (
  props: MachinesProps,
) => {
  const { machines } = props;

  return (
    <Col className="quotation-machines-table">
      <h4>Máquinas selecionadas</h4>
      {machines.length !== 0 ? (
        <Table className="fade-in table-hover" responsive size="lg">
          <thead>
            <tr>
              <th>Modelo</th>
              <th>Categoria</th>
              <th>Equip/Máquina</th>
              {/* <th>Ano de Fabricação</th> */}
              <th>Operação</th>
              <th>Compra</th>
              <th>Prestação de serviço</th>
              <th>Locação</th>
              <th>Período de locação</th>
              <th>Quantidade</th>
            </tr>
          </thead>
          <tbody>
            {machines.map((item: MachineQuotation | any) => (
              <tr className="fade-in" key={`${item.id}`}>
                <td>{item.model || 'Não especificado'}</td>
                <td>{item.categoryName.toLowerCase()}</td>
                <td>{item.EquipamentMachineName.toLowerCase()}</td>
                {/* <td>{item.fabricationYear || 'Não contém'}</td> */}
                <td>{item.operation === true ? 'Sim' : 'Não'}</td>
                <td>{item.sale === true ? 'Sim' : 'Não'}</td>
                <td>{item.service === true ? 'Sim' : 'Não'}</td>
                <td>{item.rent === true ? 'Sim' : 'Não'}</td>
                <td>
                  {item.leaseTerm > 1
                    ? `${item.leaseTerm} meses`
                    : `${item.leaseTerm} mês`}
                </td>
                <td>{item.quantity}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <Spinner
          className="spinner-loading"
          as="span"
          animation="border"
          role="status"
          aria-hidden="true"
        />
      )}
    </Col>
  );
};

export const QuotationPdfsTable: React.FC<PdfsProps> = (props: PdfsProps) => {
  const { pdfs } = props;
  return (
    <Col className="quotation-pdfs-table">
      <h4>Orçamentos enviados</h4>
      {pdfs.length !== 0 ? (
        <Table className="fade-in table-hover" responsive size="lg">
          <thead>
            <tr>
              <th>Id</th>
              <th>Data de envio</th>
              <th>Empresa</th>
              <th>Status</th>
              <th>Visualizar</th>
            </tr>
          </thead>
          <tbody>
            {pdfs.map((pdf: QuotationPdf, index: number) => (
              <tr className="fade-in" key={`_${`${index}_`}`}>
                <td>{pdf.id}</td>
                <td>{formatDate(pdf.createdAt)}</td>
                <td>{pdf.rentalCompanyName}</td>
                <td>{pdf.status ? 'Ativo' : 'Inativo'}</td>
                <td>
                  <a
                    href={pdf.pdfLink}
                    target="_blank_"
                    download={`proposta_${pdf.rentalCompanyName}`}
                  >
                    <FontAwesome type="fas" name="download" />
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <Spinner
          className="spinner-loading"
          as="span"
          animation="border"
          role="status"
          aria-hidden="true"
        />
      )}
    </Col>
  );
};

export default QuotationPdfsTable;
