import React from 'react';

import PartnersList from '../../components/PartnersList';
import Header from '../../components/Header';
import PagePath from '../../components/PagePath';

const Partners: React.FC = () => (
  <>
    <Header />
    <PagePath />
    <section className="fade-in">
      <PartnersList />
    </section>
  </>
);

export default Partners;
