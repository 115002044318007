import React from 'react';

import Header from '../../components/Header';

import CategoryBanner from '../../components/CategoryBanner';
import Categories from '../../components/Categories';
import ApresentationMPC from '../../components/ApresentationMPC';

const MPC: React.FC = () => (
  <>
    <Header />
    <CategoryBanner />
    <ApresentationMPC />
    <Categories />
  </>
);
export default MPC;
