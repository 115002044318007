import React from 'react';

import './styles.scss';
import { Jumbotron, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Links } from '../../services/types';

const PagePath: React.FC = () => {
  const path = window.location.pathname.split('/');

  switch (path[path.length - 1]) {
    default:
      return (
        <Jumbotron className="page-path">
          <Col xs={6}>
            <h3>{decodeURI(path[path.length - 1].toLowerCase())}</h3>
          </Col>
          <Col>
            <p>
              <Link to="/">Início</Link>
              {path[1] !== undefined ? (
                <>
                  {' '}
                  /{' '}
                  <Link to={`/${path[1].toLowerCase()}`}>
                    {decodeURI(path[1].toLowerCase())}
                  </Link>
                </>
              ) : (
                ''
              )}
            </p>
          </Col>
        </Jumbotron>
      );
    case 'sejaumlocadormpc':
      return (
        <Jumbotron className="page-path">
          <Col xs={6}>
            <h3>Tenho Máquinas</h3>
          </Col>
          <Col>
            <p>
              <Link to="/">Início</Link>
              {path[1] !== undefined ? (
                <>
                  {' '}
                  / <Link to={`/${path[1].toLowerCase()}`}>Tenho Máquinas</Link>
                </>
              ) : (
                ''
              )}
            </p>
          </Col>
        </Jumbotron>
      );

    case 'comofunciona':
      return (
        <Jumbotron className="page-path">
          <Col xs={6}>
            <h3>Como funciona o MPC?</h3>
          </Col>
          <Col>
            <p>
              <Link to="/">Início</Link>
              {path[1] !== undefined ? (
                <>
                  {' '}
                  /{' '}
                  <Link to={`/${path[1].toLowerCase()}`}>
                    Como funciona o MPC?
                  </Link>
                </>
              ) : (
                ''
              )}
            </p>
          </Col>
        </Jumbotron>
      );
    case 'orcamento':
      return (
        <Jumbotron className="page-path">
          <Col xs={6}>
            <h3>Preciso de Máquinas</h3>
          </Col>
          <Col>
            <p>
              <Link to="/">Início</Link>
              {path[1] !== undefined ? (
                <>
                  {' '}
                  /{' '}
                  <Link to={`/${path[1].toLowerCase()}`}>
                    Preciso de Máquinas
                  </Link>
                </>
              ) : (
                ''
              )}
            </p>
          </Col>
        </Jumbotron>
      );
  }
};

export const PagePathManual: React.FC<Links> = (props: Links) => {
  const { link, subLink, subSubLink } = props;
  const pathManual = window.location.pathname.substring(1).split('/');
  // console.log('pathManual', pathManual);
  switch (pathManual.length) {
    default:
      return (
        <Jumbotron className="page-path">
          <Col xs={6}>
            <h3>{link.toLowerCase()}</h3>
          </Col>
          <Col>
            <p>
              <Link to="/">Início</Link> /{' '}
              <Link to={`/categorias/${pathManual[1].toLowerCase()}`}>
                {link.toLowerCase()}
              </Link>
            </p>
          </Col>
        </Jumbotron>
      );
    case 3:
      return (
        <Jumbotron className="page-path">
          <Col xs={6}>
            <h3>{link.toLowerCase()}</h3>
          </Col>
          <Col>
            <p>
              <Link to="/">Início</Link> /{' '}
              <Link to={`/categorias/${pathManual[1].toLowerCase()}`}>
                {subLink.toLowerCase()}
              </Link>
            </p>
          </Col>
        </Jumbotron>
      );
    case 4:
      return (
        <Jumbotron className="page-path">
          <Col xs={6}>
            <h3>{link.toLowerCase()}</h3>
          </Col>
          <Col>
            <p>
              <Link to="/">Início</Link>
              {pathManual[1] !== undefined ? (
                <>
                  {' '}
                  /{' '}
                  <Link to={`/categorias/${pathManual[1].toLowerCase()}`}>
                    {subLink.toLowerCase()}
                  </Link>
                </>
              ) : (
                ''
              )}
              {pathManual[2] !== undefined ? (
                <>
                  {' '}
                  /{' '}
                  <Link
                    to={`/categorias/${pathManual[1].toLowerCase()}/${pathManual[2].toLowerCase()}`}
                  >
                    {subSubLink.toLowerCase()}
                  </Link>
                </>
              ) : (
                ''
              )}
            </p>
          </Col>
        </Jumbotron>
      );
  }
};

export default PagePath;
