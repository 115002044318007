/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import './styles.scss';
import { Row, Col, Form, Button } from 'react-bootstrap';
import SelectSearch from 'react-select-search';
import { toast } from 'react-toastify';
import InputMask from 'react-input-mask';
import { getPlans } from '../../../services/queries';
import { PlansType, Options } from '../../../services/types';

interface Props {
  idPlan?: string;
  selectFormPhase01?: any;
}

interface FormPhase01 {
  planId: string;
  cnpjOrCpf: string;
  corporateName: string;
  address: string;
  phone: string;
  whatsApp: string;
}

const Phase01: React.FC<Props> = (props: Props) => {
  const { idPlan, selectFormPhase01 } = props;
  const storageForm: FormPhase01 = JSON.parse(
    sessionStorage.getItem('@MPC/form') as any,
  );
  const [plans, handlePlans] = useState<PlansType[]>();
  const [planSelect, handlePlanSelect] = useState<PlansType>();
  const [formPhase01, handleFormPhase01] = useState<FormPhase01>({
    planId: '',
    cnpjOrCpf: '',
    corporateName: '',
    address: '',
    phone: '',
    whatsApp: '',
  });

  useEffect(() => {
    if (storageForm) {
      handleFormPhase01({
        ...formPhase01,
        planId: storageForm.planId,
        cnpjOrCpf: storageForm.cnpjOrCpf,
        corporateName: storageForm.corporateName,
        address: storageForm.address,
        phone: storageForm.phone,
        whatsApp: storageForm.whatsApp,
      });
    }
  }, []);

  useEffect(() => {
    getPlans()
      .then(async response => {
        handlePlans(response.data.data.plans);
        const myPlan = response.data.data.plans.filter(
          (plan: PlansType) => plan.id === idPlan,
        )[0];
        handlePlanSelect(myPlan);
      })
      .catch(error => {
        console.log(error);
      });
  }, [idPlan]);

  const getPlansOptions = () => {
    const plansOptions: Options[] = [];

    plans?.map(plan =>
      plansOptions.push({
        name: plan.name,
        value: plan.id,
      }),
    );

    return plansOptions;
  };

  const selectPlan = (id: string) => {
    handlePlanSelect(plans?.filter(plan => plan.id === id)[0]);
  };

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (planSelect) {
      formPhase01.planId = planSelect.id;
    }
    if (
      formPhase01.address !== '' &&
      formPhase01.cnpjOrCpf !== '' &&
      formPhase01.corporateName !== '' &&
      formPhase01.phone !== '' &&
      formPhase01.planId !== ''
    ) {
      selectFormPhase01(formPhase01);
    } else {
      toast.error('Por favor preecha todos os campos');
    }
  };

  return (
    <>
      <Row className="row-description">
        <Col>
          <h4>
            Ficamos felizes que você deseja fazer parte do Máquinas Para
            Construção.
          </h4>
          <p>
            O próximo passo é realizar o seu cadastro. Preencha os dados da sua
            empresa
          </p>
        </Col>
      </Row>
      <Row className="row-count-phases">
        <p>Etapa 01 de 03</p>
      </Row>
      <Row className="row-form-phase01">
        <Form onSubmit={(event: React.FormEvent) => onSubmit(event)}>
          <Row>
            <Col xl="5">
              <Form.Label>Plano escolhido</Form.Label>
              <SelectSearch
                value={idPlan}
                options={getPlansOptions()}
                onChange={event => selectPlan(String(event))}
              />
              <Row>
                <Col xl="4" style={{ paddingLeft: 0 }}>
                  <Form.Label>Valor</Form.Label>
                  <p>
                    {' '}
                    {Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(Number(planSelect?.value))}{' '}
                  </p>
                </Col>
                <Col xl="8" style={{ paddingRight: 0 }}>
                  <Form.Label>Vigência</Form.Label>
                  <p>{planSelect?.validity} meses</p>
                </Col>
              </Row>
              <Form.Label>Descrição</Form.Label>
              <p
                dangerouslySetInnerHTML={{
                  __html: String(planSelect?.description),
                }}
              />
            </Col>
            <Col xl="7">
              <Row>
                <Col xl="5">
                  <Form.Label>CPF/CNPJ</Form.Label>
                  <Form.Control
                    type="number"
                    value={formPhase01?.cnpjOrCpf}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      handleFormPhase01({
                        ...formPhase01,
                        cnpjOrCpf: event.target.value,
                      })
                    }
                    placeholder=""
                  />
                </Col>
                <Col xl="7">
                  <Form.Label>Nome/Razão Social</Form.Label>
                  <Form.Control
                    type="text"
                    value={formPhase01?.corporateName}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      handleFormPhase01({
                        ...formPhase01,
                        corporateName: event.target.value,
                      })
                    }
                  />
                </Col>
              </Row>
              <Col>
                <Form.Label>Endereço</Form.Label>
                <Form.Control
                  type="text"
                  value={formPhase01?.address}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleFormPhase01({
                      ...formPhase01,
                      address: event.target.value,
                    })
                  }
                />
              </Col>
              <Row>
                <Col xl="5">
                  <Form.Label>Telefone</Form.Label>
                  <Form.Control
                    as={InputMask}
                    type="text"
                    mask="(99) 99999-9999"
                    maskChar="_"
                    value={formPhase01?.phone}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      handleFormPhase01({
                        ...formPhase01,
                        phone: event.target.value.replace(/[^0-9]/g, ''),
                      })
                    }
                  />
                </Col>
                <Col xl="7">
                  <Form.Label>WhatsApp (opcional)</Form.Label>
                  <Form.Control
                    as={InputMask}
                    type="text"
                    mask="(99) 99999-9999"
                    maskChar="_"
                    value={formPhase01?.whatsApp}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      handleFormPhase01({
                        ...formPhase01,
                        whatsApp: event.target.value.replace(/[^0-9]/g, ''),
                      })
                    }
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="row-button-submit">
            <Button type="submit">Avançar</Button>
          </Row>
        </Form>
      </Row>
    </>
  );
};

export default Phase01;
