import React, { useState } from 'react';
import './styles.scss';
import { Container, Row, Col, Form, Spinner, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { passwordReset } from '../../services/mutations';

import LogoIcon from '../../images/logo/logo.png';

const SendEmail: React.FC = () => {
  const [loading, handleLoading] = useState(false);
  const [successSendEmail, handleSuccessSendEmail] = useState(false);
  const [email, handleEmail] = useState('');

  const sendEmail = (event: React.FormEvent) => {
    event.preventDefault();
    if (email !== '') {
      handleLoading(true);
      // fazer a requisição de enviar email
      passwordReset(email)
        .then(response => {
          if (response.data.data.passwordReset) {
            handleLoading(false);
            handleSuccessSendEmail(true);
          } else {
            toast.error('Erro ao enviar email');
          }
        })
        .catch(error => {
          console.log('error', error);
        });
      // sucesso ao enviar email
    }
  };

  return (
    <Container fluid className="container-forgot justify-content">
      <Row className="justify-content">
        <Col xl={5} className="justify-content">
          {!successSendEmail ? (
            <>
              <h5>
                Para recuperar a senha de acesso a plataforma Máquinas para
                Construção basta digitar o email cadastrado e enviaremos um link
                para você.
              </h5>
              <Form
                className="form-send-email"
                onSubmit={(event: React.FormEvent) => sendEmail(event)}
              >
                <Form.Group>
                  <Form.Label>Email de login</Form.Label>
                  <Form.Control
                    type="email"
                    value={email}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      handleEmail(event.target.value);
                    }}
                  />
                </Form.Group>
                <Button type="submit">
                  {!loading ? (
                    'Solicitar alteração de  senha'
                  ) : (
                    <Spinner
                      as="span"
                      animation="border"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                </Button>
              </Form>
            </>
          ) : (
            <>
              <img src={LogoIcon} alt="logo" />
              <h5>
                O link para alterar o seu e-mail foi enviado para <b>{email}</b>
                . Confira o nosso mensagem na caixa de entrada do e-mail
                cadastrado.
              </h5>
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default SendEmail;
