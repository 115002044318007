import React from 'react';
import './styles.scss';
import { Container, Col, Row } from 'react-bootstrap';

import Rectangle from '../../images/rectangle.png';

const InfoMPC: React.FC = () => (
  <Container fluid>
    <Container fluid className="content-title">
      <p>
        Deixe o "Máquinas para Construção" ajudar o seu setor COMERCIAL e o seu
        setor de COMPRAS
      </p>
    </Container>
    <Row className="container-row-info">
      <Col xl={4} className="container-img-right">
        <img src={Rectangle} alt="rect" />
      </Col>
      <Col xl={8} className="container-text-left">
        <p>
          Máquinas para Construção foi idealizado por um Engenheiro Civil, com
          mais de 25 anos de atuação no segmento de infraestrutura e locação de
          máquinas pesadas.
          <br />O MPC auxilia a superar os desafios comerciais do dia-a-dia,
          tanto para as locadoras, vendedores de máquinas e prestadores de
          serviços com máquinas próprias, quanto para os locatários, facilitando
          a comunicação e disponibilizando o acesso a uma grande quantidade de
          novos clientes e fornecedores.
        </p>
      </Col>
    </Row>
    <Row className="container-row-info">
      <Col xl={8} className="container-text-right">
        <p>
          Para o LOCATÁRIO, TOMADOR DE SERVIÇOS OU COMPRADOR DE MÁQUINAS
          utilizar o MPC basta cadastrar-se, e adicionar à lista os equipamentos
          que tiver interesse, inserindo as informações solicitadas pela
          plataforma. <br />
          Para o LOCADOR, PRESTADOR DE SERVIÇOS OU VENDEDOR DE MAQUINAS utilizar
          o MPC, deve realizar o cadastro, inserir os equipamentos que possui
          com as informações solicitadas e escolher um plano de assinante.
          <br />O MPC, ao receber a demanda do LOCATÁRIO, TOMADOR DE SERVIÇOS OU
          COMPRADOR DE MÁQUINA, fará a busca pelo LOCADORES,PRESTADORES DE
          SERVIÇOS OU VENDEDORES DE MÁQUINAS que possam atender às necessidades
          listadas na solicitação.
        </p>
      </Col>
      <Col xl={4} className="container-img-left">
        <img src={Rectangle} alt="rect" />
      </Col>
    </Row>
    <Row className="container-row-info">
      <Col xl={4} className="container-img-right">
        <img src={Rectangle} alt="rect" />
      </Col>
      <Col xl={8} className="container-text-left">
        <p>
          Após encontrar os LOCADORES, PRESTADORES DE SERVIÇOS OU VENDEDORES DE
          MÁQUINAS que atendam a solicitação listada, nossa plataforma irá
          enviar e-mail com a solicitação da proposta orçamentária, juntamente
          com a lista de equipamentos e informações para contato e posterior
          negociação.
          <br />
          O LOCADOR, PRESTADOR DE SERVIÇOS OU VENDEDOR DE MÁQUINAS interessado
          em atender a solicitação listada, elabora um orçamento e envia via
          MPC.
          <br />
          O MPC ainda possibilita aos usuários acesso a algumas ferramentas de
          controle de solicitação de propostas.
          <br />
        </p>
      </Col>
    </Row>
    <Row>
      <Col>
        <h5>
          "O BRASIL SERÁ REALMENTE UMA POTÊNCIA, E TEREMOS A PARTIR DE 2021
          MUITAS OPORTUNIDADES DE NEGÓCIOS, ATÉ MESMO PORQUE A INFRAESTRUTURA DO
          NOSSO PAÍS AINDA ESTÁ TODA POR SER FEITA, MAS TEREMOS QUE TER
          SABEDORIA E CAPACIDADE TÉCNICA PARA APROVEITARMOS DA MELHOR FORMA
          POSSÍVEL ESTAS OPORTUNIDADES"
        </h5>
      </Col>
    </Row>
  </Container>
);

export default InfoMPC;
