/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Modal, Card, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { QuotationMachinesTable, QuotationPdfsTable } from '../QuotationTables';
import { ApplicationState } from '../../store';
import QuotationData from '../QuotationData';
import { toggleModalQ } from '../../store/ducks/modals/actions';

const QuotationModal: React.FC = () => {
  const modalQState = useSelector((state: ApplicationState) => state.modals);
  const rentalCompanyState = useSelector(
    (state: ApplicationState) => state.rentalCompany,
  );
  const { statusQ, quotation } = modalQState;

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(toggleModalQ(undefined));
    return false;
  };

  return (
    <Modal
      show={statusQ}
      // show={false}
      onHide={handleClose}
      aria-labelledby="contained-quotation-modal"
      className="modal-quotation"
      dialogClassName="modal-quotation"
      id="modal-quotation"
      size="xl"
      centered
    >
      <Card>
        <Col xs={12} className="modal-quotation-card">
          <Modal.Header closeButton>
            <h4>Orçamento {quotation.id}</h4>
          </Modal.Header>
          <Modal.Body
            className="mt-4 content"
            style={{ height: '70vh', overflowY: 'auto' }}
          >
            {rentalCompanyState.user.role === 'RENTAL' ? (
              <QuotationData data={quotation} />
            ) : (
              false
            )}
            {quotation.machines.length === 0 ? (
              <p>Sem máquinas solicitadas</p>
            ) : (
              <QuotationMachinesTable machines={quotation.machines} />
            )}
            {quotation.pdfs.length === 0 ? (
              <p>Sem orçamentos enviados</p>
            ) : (
              <QuotationPdfsTable pdfs={quotation.pdfs} />
            )}
          </Modal.Body>
        </Col>
      </Card>
    </Modal>
  );
};

export default QuotationModal;
